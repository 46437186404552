import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class GoogleService {
  private endpoints: {
    google_url: string,
    google_token: string,
    google_register: string,
  };

  constructor(
    private http: HttpClient,
    private authSvc: AuthenticationService
  ) {
    this.endpoints = {
      google_url: environment.apiUrl + 'google_url',
      google_token: environment.apiUrl + 'google_token',
      google_register: environment.apiUrl + 'google_register',
    };
  }

  async googleGetUrl2() {
    return await this.http.get<any>(this.endpoints.google_url).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  googleGetUrl(url: string): Observable<any> {
    const params = new HttpParams()
      .set('environment_url', url.toString());
    return this.http.get<any>(this.endpoints.google_url, {params: params});
  }

  googleGetToken(data): Observable<any> {
    return this.http.post<any>(this.endpoints.google_token, data);
  }

  registerGoogle(data): Observable<any> {
    return this.http.post<any>(this.endpoints.google_register, data)
      .pipe(map(res => {
        // login successful if there's a jwt token in the response
        if (res.results && res.results.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', btoa(JSON.stringify(res.results)));
          localStorage.setItem('accessToken', JSON.stringify(res.results.access_token));
          this.authSvc.updateUser(res.results);
        }
        return res;
      }));
  }
}
