import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {User} from '../../../../shared/models/user.model';
import {StripeEvts} from '../../../../shared/models/stripeevts.model';

@Component({
  selector: 'app-adm-stripe-evts',
  templateUrl: './adm-stripe-evts.component.html',
  styleUrls: ['./adm-stripe-evts.component.scss']
})
export class AdmStripeEvtsComponent implements OnInit, OnDestroy {
  currentUser: User;
  math = Math;
  stripeEvts: StripeEvts[] = [];
  searchText: string;
  searching = false;
  showSearch = false;
  loading = false;
  total: number;
  pags = 1;
  take = 500;
  skip = 0;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
    private httpOrder: HttpOrdersService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit(): void {
    this.getAllStripeEvents();
  }

  getAllStripeEvents() {
    this.loading = true;
    this.httpOrder.getStripeEvents(this.take, this.skip, this.searchText ? this.searchText : null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.stripeEvts = res.results.stripe_evts;
          this.stripeEvts.map(se => {
            if (se.type) {
              se.type_sm = se.type.split('.').map(s => s.substring(0, 3)).join('. ');
            } else {
              se.type_sm = null;
            }
          });
          this.total = res.results.total;
          this.pags = Math.ceil(this.total / this.take) || 1;
        } else {
          this.snackSvc.openSnackBar('Error getting stripe events');
        }
        this.loading = false;
      }, error => {
        this.loading = false;
        console.log(error);
        this.snackSvc.openSnackBar('Error getting stripe events');
      });
  }

  onDeleteStripevt(evt_id) {
    this.httpOrder.deleteStripeEvt(evt_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.stripeEvts = this.stripeEvts.filter(se => se.evt_id !== evt_id);
        } else {
          this.snackSvc.openSnackBar('Error deleting stripe event');
        }
      }, error => {
        console.log(error);
        this.snackSvc.openSnackBar('Error deleting stripe event');
      });
  }

  iEvts(i) {
    this.skip = ((i - 1) * this.take);
    if ((this.skip >= 0) && (this.skip <= this.total)) {
      this.getAllStripeEvents();
    } else {
      // console.log('No more previous');
    }
  }

  nextEvts() {
    if (this.skip + this.take <= this.total) {
      this.skip += this.take;
      this.getAllStripeEvents();
    }
  }

  prevEvts() {
    if (this.skip - this.take >= 0) {
      this.skip -= this.take;
      this.getAllStripeEvents();
    } else {
      // console.log('No more previous');
    }
  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  keyEscape() {
    if (this.searchText) {
      this.searchText = null;
    } else {
      this.showSearch = false;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
