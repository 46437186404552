import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpSessionService {

  private url: {
    register: string,
    passwordEmail: string,
    passwordReset: string,
    verificationEmail: string,
    verificationUserToken: string,
    verifyAccess: string,
  };

  constructor(private http: HttpClient) {
    this.url = {
      register: environment.apiUrl + 'register',
      passwordEmail: environment.apiUrl + 'password/email',
      passwordReset: environment.apiUrl + 'password/reset',
      verificationEmail: environment.apiUrl + 'verification/email',
      verificationUserToken: environment.apiUrl + 'verification/user/',
      verifyAccess: environment.apiUrl + 'verification/access/',
    };
  }

  /*
  * REGISTER USER
  */
  registerNewUser(registerData): Observable<any> {
    return this.http.post<any>(this.url.register, registerData);
  }

  /*
  * FORGOT PASSWORD
  */
  async requestPasswordToken(tokenPwd: any) {
    return await this.http.post<any>(this.url.passwordEmail, tokenPwd).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async requestPasswordChange(newPassword: any) {
    return await this.http.post<any>(this.url.passwordReset, newPassword).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /*
  * VERIFY EMAIL
  */
  async verifyUserToken(verifyToken: any) {
    return await this.http.get<any>(this.url.verificationUserToken + verifyToken.token).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async sendTokenEmail(sendToken: any) {
    return await this.http.post<any>(this.url.verificationEmail, sendToken).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /*
  * VERIFY ACCESS
  */
  async verifyUserAccess(token: any) {
    return await this.http.get<any>(this.url.verifyAccess + token).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
}
