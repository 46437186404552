import {Component, HostListener, Inject, OnDestroy} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {Subject} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Clipboard} from '@angular/cdk/clipboard';

export interface DialogDataAddPax {
  user: User;
  name: string;
  email: string;
  password: string;
  gender: string;
  room: string;
  reason: string;
}

@Component({
  selector: 'app-add-pax',
  templateUrl: './add-pax.component.html',
  styleUrls: ['./add-pax.component.scss']
})
export class AddPaxComponent implements OnDestroy {
  loading = false;
  reason: string;
  snackbarRef: any = null;
  dummy_pax = false;
  ok_copied = false;
  show_mf = false;
  selfUrl: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpUser: HttpUserService,
    private http: HttpClient,
    private clipboard: Clipboard,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<AddPaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddPax) {
    this.data.name = null;
    this.data.email = null;
    this.data.password = null;
    this.data.gender = null;
    this.selfUrl = environment.selfUrl;
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Enter') {
      this.submit();
    }
  }

  submit(): void {
    if (!this.data.name) {
      this.snackSvc.openSnackBar('Please insert name');
      return;
    }
    if (!this.data.email) {
      this.snackSvc.openSnackBar('Please insert email');
      return;
    }
    if (!this.data.password) {
      this.snackSvc.openSnackBar('Please insert password');
      return;
    }
    this.createPax(this.data);
  }

  changeName() {
    if (this.dummy_pax) {
      this.data.email = this.data.name.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g, '') + '@dummypax.planafy';
    }
  }

  dummyPax() {
    this.dummy_pax = true;
    this.data.password = Array(10).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$').map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }).join('');
    this.data.email = (this.data.name ? this.data.name.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g, '') : '') + '@dummypax.planafy';
  }

  selGen(new_gen) {
    if (this.data.gender === new_gen) {
      this.data.gender = null;
    } else {
      if (new_gen === 'female') {
        this.data.gender = 'female';
      } else if (new_gen === 'male') {
        this.data.gender = 'male';
      } else if (new_gen === 'x') {
        this.data.gender = 'x';
      } else {
        this.data.gender = null;
      }
    }
  }

  selRoom(new_room) {
    if (this.data.room === new_room) {
      this.data.room = null;
    } else {
      if (new_room === 'St') {
        this.data.room = 'St';
      } else if (new_room === 'Si') {
        this.data.room = 'Si';
      } else if (new_room === 'Tw') {
        this.data.room = 'Tw';
      } else if (new_room === 'Db') {
        this.data.room = 'Db';
      } else {
        this.data.room = null;
      }
    }
  }

  generatePassword() {
    this.data.password = Array(10).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$').map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }).join('');
    this.clipboard.copy(this.data.password);
    this.notify();
  }

  generateUser(gender) {
    this.loading = true;
    this.http.get('https://randomuser.me/api/?inc=name,email&nat=us&gender=' + gender).subscribe(
      next => {
        this.data.name = next['results'][0].name.first + ' ' + next['results'][0].name.last;
        this.data.email = next['results'][0].email;
        this.data.gender = gender;
        this.generatePassword();
        this.loading = false;
      }, error => {
        this.loading = false;
        console.log(error);
        const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
        let string = '';
        for (let ii = 0; ii < 15; ii++) {
          string += chars[Math.floor(Math.random() * chars.length)];
        }
        this.data.name = string;
        this.data.email = string + '@example.com';
        this.data.gender = (Math.floor(Math.random() * 2) === 0) ? 'male' : 'female';
        this.generatePassword();
      }
    );
  }

  notify() {
    this.ok_copied = true;
    setTimeout(() => {
      this.ok_copied = false;
    }, 1000);
  }

  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  createPax(form) {
    if (!this.validateEmail(form.email)) {
      this.snackSvc.openSnackBar('Invalid email');
      return;
    }
    this.loading = true;
    const data = {
      'name': form.name,
      'email': form.email,
      'password': form.password,
      'gender': form.gender,
    };
    // console.log(data);
    this.httpUser.createUser(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.user = res.results;
            this.close('ok');
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('Error creating user');
        });
  }

  close(reason) {
    if (reason === 'close' && this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
