import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TitleService {
  private pageTitle = new BehaviorSubject<string>('Planafy');
  currentTitle = this.pageTitle.asObservable();

  constructor() {
  }

  setTitle(name: string): void {
    this.pageTitle.next(name);
  }
}
