import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './pages/login/login.component';
import {SignupComponent} from './pages/signup/signup.component';
import {ResetpwdComponent} from './pages/resetpwd/resetpwd.component';
import {ForgotpwdComponent} from './pages/forgotpwd/forgotpwd.component';
import {MaterialModule} from '../shared/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

const authRoutes: Routes = [
  {path: 'signup/pax', component: SignupComponent},
  {path: 'login', component: LoginComponent},
  {path: 'login/reset', component: LoginComponent},
  {path: 'login/pax', component: LoginComponent},
  {path: 'logout', component: LoginComponent},
  {path: 'password/reset', component: ResetpwdComponent},
  // {path: 'password/forgot', component: ForgotpwdComponent}
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(authRoutes)
  ],
  declarations: [
    SignupComponent,
    LoginComponent,
    ResetpwdComponent,
    ForgotpwdComponent
  ]
})
export class AuthRoutingModule {
}
