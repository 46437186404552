import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpFlagService {

  private endpoints: {
    allFlags: string,
    crudFlag: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allFlags: environment.apiUrl + 'flags/',
      crudFlag: environment.apiUrl + 'flag/',
    };
  }

  // FLAG

  /* gets all flags */
  getAllflags() {
    return this.http.get<any>(this.endpoints.allFlags);
  }

  /* Gets all flags from one user */
  getFlag(flag_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('flag_id', flag_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.crudFlag, {params: params});
  }

  /* Creates a new search in flag */
  createFlag(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudFlag, data);
  }

  /* Delete flag */
  deleteFlag(req_id: number, flag_id: string): Observable<any> {
    const params = new HttpParams()
      .set('flag_id', flag_id.toString())
      .set('req_id', req_id.toString());
    return this.http.delete<any>(this.endpoints.crudFlag, {params: params});
  }
}
