import {Component, OnDestroy, OnInit} from '@angular/core';
import {TourOptional} from '../../../../../shared/models/tour-optional.model';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {Title} from '@angular/platform-browser';
import {takeUntil} from 'rxjs/operators';
import {HttpTourOptionalService} from '../../../../../shared/services/http/http-tour-optional.service';
import {environment} from '../../../../../../environments/environment';
import {TourService} from '../../../../../shared/services/common/tour.service';

@Component({
  selector: 'app-main-tour-optionals',
  templateUrl: './main-tour-optionals.component.html',
  styleUrls: ['./main-tour-optionals.component.scss']
})
export class MainTourOptionalsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;

  appUrl: string;
  linkURL: string;
  returnUrl: string;
  loadingTops = false;
  possible_currs: any[] = [];

  ok_copied1 = false;
  ok_copied2 = false;

  private onDestroy$ = new Subject<boolean>();

  static replaceSymbols(txt): string {
    txt = txt.toUpperCase().replace('EUROS', '€');
    txt = txt.toUpperCase().replace('EURO', '€');
    txt = txt.toUpperCase().replace('EUR', '€');
    txt = txt.toUpperCase().replace('US DOLLARS', '$');
    txt = txt.toUpperCase().replace('DOLLARS', '$');
    txt = txt.toUpperCase().replace('DOLLAR', '$');
    txt = txt.toUpperCase().replace('USD', '$');
    txt = txt.toUpperCase().replace('POUNDS', '£');
    txt = txt.toUpperCase().replace('POUND', '£');
    txt = txt.toUpperCase().replace('GBP', '£');
    txt = txt.toUpperCase().replace('AUD', 'AU$');
    txt = txt.toUpperCase().replace('AUSTRALIAN DOLLARS', 'AU$');
    txt = txt.toUpperCase().replace('AUS DOLLARS', 'AU$');
    txt = txt.toLowerCase().replace('au$', 'AU$');
    txt = txt.toUpperCase();
    return txt;
  }

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private titleSvc: TitleService,
    private versioning: VersioningService,
    private webTitleSvc: Title,
    private datePipe: DatePipe,
    private tourSvc: TourService,
    private httpTOSvc: HttpTourOptionalService,
    private route: ActivatedRoute,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.webTitleSvc.setTitle('Tour Activities | Planafy');
  }

  ngOnInit(): void {
    this.appUrl = environment.appUrl;
    const prod_id = this.route.snapshot.params['tour-prodid'];
    if (this.activeTour.tour.prodid + '' !== prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour');
      this.router.navigate(['tours']);
      return;
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    this.linkURL = this.appUrl + 'tour-optionals/' + btoa('' + this.activeTour.tour.id).replace(/=/g, '');
    this.getTourOptionals(this.activeTour.tour.id);
  }

  notify(txt?) {
    if (txt === '1') {
      this.ok_copied1 = true;
      this.snackSvc.openSnackBar('URL copied', null, 1);
      setTimeout(() => {
        this.ok_copied1 = false;
      }, 2000);
    } else {
      this.ok_copied2 = true;
      setTimeout(() => {
        this.ok_copied2 = false;
      }, 2000);
    }
  }

  onRefreshTO() {
    this.getTourOptionals(this.activeTour.tour.id);
  }

  getTourOptionals(tour_id) {
    this.loadingTops = true;
    this.httpTOSvc.getTourOptionals(tour_id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.versioning.check(res.results.version);
            this.activeTour.optionals = res.results.tour_optionals.sort(
              (a, b) => a.component['start_date'] > b.component['start_date'] ? 1 : a.component['start_date'] === b.component['start_date'] ? 0 : -1);
            // this.activeTourSvc.setActiveTour(this.activeTour);
          } else {
            this.snackSvc.resultsElse(res);
            this.goTOBack();
          }
          this.loadingTops = false;
        },
        error => {
          console.log(error);
          this.loadingTops = false;
        });
  }

  onGoToOpt(to_id: number) {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'activities', to_id], {queryParams: {returnUrl: window.location.pathname}});
  }

  goTOBack() {
    if (this.returnUrl && this.returnUrl === window.location.pathname) {
      this.router.navigate(['tour', this.activeTour.tour.prodid, 'activities'], {queryParams: {returnUrl: window.location.pathname}});
    } else if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  togglePaxControl(topt: TourOptional) {
    if (topt.status === 'open') {
      topt.status = 'block';
    } else if (topt.status === 'block') {
      topt.status = 'stop';
    } else if (topt.status === 'stop') {
      topt.status = 'freeze';
    } else if (topt.status === 'freeze') {
      topt.status = 'open';
    } else {
      topt.status = 'open';
    }
    this.updTourOpt(topt, 'status');
  }

  toggleOnlinePay(topt: TourOptional) {
    if (!topt.pay_online && !topt.actual_price) {
      this.snackSvc.openSnackBar('Set price first');
      return;
    }
    topt.pay_online = !topt.pay_online;
    this.updTourOpt(topt, 'pay_online');
  }

  updTourOpt(topt: TourOptional, txt?) {
    this.loadingTops = true;
    this.checkPrices(topt);
    const data = {
      'actual_price': topt.actual_price,
      'currency': topt.currency,
      'pay_online': topt.pay_online,
      'status': topt.status,
    };
    // console.log(data);
    this.httpTOSvc.updateTourOptional(topt.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const idx = this.activeTour.optionals.findIndex(o => +o.id === +topt.id);
            if (idx >= 0) {
              this.activeTour.optionals[idx].actual_price = topt.actual_price;
              this.activeTour.optionals[idx].currency = topt.currency;
              this.activeTour.optionals[idx].status = topt.status;
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTops = false;
        },
        error => {
          console.log(error);
          this.loadingTops = false;
        });
  }

  checkPrices(topt: TourOptional) {
    // If topt no curr or not in user list
    if (!topt.currency || !this.currentUser.user_settings.currency.includes(topt.currency)) {
      this.possible_currs = this.currentUser.user_settings.currency.split(',');
      topt.currency = this.possible_currs[0];
    }
    // If Online Pay
    if (topt.pay_online) {
      topt.actual_price = topt.actual_price.toUpperCase().replace(/[^,.\d]*/gm, '');
      topt.actual_price = topt.actual_price + ' ' + topt.currency;
      topt.actual_price = MainTourOptionalsComponent.replaceSymbols(topt.actual_price);
    } else {
      // If NO Online Pay
      const has_curr = /\D/.test(topt.actual_price);
      // and actual_price has no currency
      if (topt.actual_price && !has_curr) {
        topt.actual_price = topt.actual_price.toUpperCase().replace(/[^,.\d]*/gm, '');
        topt.actual_price = topt.actual_price + ' ' + topt.currency;
        topt.actual_price = MainTourOptionalsComponent.replaceSymbols(topt.actual_price);
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
