import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/session/authentication.service';
import {User} from '../models/user.model';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authSvc: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authSvc.currentUserValue;
    if (currentUser && currentUser.role >= User.ef) {
      // console.log('AUTH GUARD: CurrentUser OK');
      // authorised so return true
      return true;
    }
    if (currentUser && currentUser.role <= User.pax_admin) {
      // console.log('AUTH GUARD: CurrentUser OK');
      this.router.navigate(['home']);
      return false;
    }
    // console.log('AUTH GUARD: Not logged in', state.url);
    // not logged in so redirect to login page with the return url
    this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
