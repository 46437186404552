import {Day} from './day.model';
import {Components} from './components.model';

export class Event {
  id: number;
  tour_id: number;
  day_id: number;
  component_id: number;
  start_time: string;
  end_time: string;
  meet_time: string;
  meet_place: string;
  url: string;
  name: string;
  private_notes: string;
  public_notes: string;
  visible: boolean;
  show_times: boolean;
  comp_type_id?: number;
  comp_type_icon?: string;
  sent_at?: string;
  confirmed_at?: string;
  state?: string;
  showendtime?: boolean;
  day?: Day;
  component?: Components;
  new_messages?: number;
}
