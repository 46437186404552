import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpOrdersService {

  private endpoints: {
    ordersCrud: string,
    ordersFull: string,
    ordersUser: string,
    orderPdf: string,
    stripeCheckout: string,
    stripeTopExpCheckout: string,
    stripeTipExpCheckout: string,
    stripeDonateExpCheckout: string,
    stripeEnhanceExpCheckout: string,
    stripePaymentIntents: string,
    stripeVerifySession: string,
    stripeCreateAccount: string,
    stripeGetAccount: string,
    stripeGetAccountLink: string,
    stripeGetDashLink: string,
    stripeOrder: string,
    paypalOrder: string,
    paypalCapture: string,
    fakeOrder: string,
    voucherCRUD: string,
    voucherApply: string,
    voucherUndo: string,
    StripeEvts: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      ordersCrud: environment.apiUrl + 'orders/',
      ordersFull: environment.apiUrl + 'order_full/',
      ordersUser: environment.apiUrl + 'orders_user/',
      orderPdf: environment.apiUrl + 'orders/pdf/',
      fakeOrder: environment.apiUrl + 'orders_fake_pay/',
      stripeCheckout: environment.apiUrl + 'stripe_checkout/',
      stripeTopExpCheckout: environment.apiUrl + 'stripe_top_exp_checkout/',
      stripeTipExpCheckout: environment.apiUrl + 'stripe_tip_exp_checkout/',
      stripeDonateExpCheckout: environment.apiUrl + 'stripe_donate_exp_checkout/',
      stripeEnhanceExpCheckout: environment.apiUrl + 'stripe_enhance_exp_checkout/',
      stripePaymentIntents: environment.apiUrl + 'stripe_payment_intents/',
      stripeVerifySession: environment.apiUrl + 'stripe_verify_session/',
      stripeCreateAccount: environment.apiUrl + 'create_connected_acct/',
      stripeGetAccount: environment.apiUrl + 'get_connected_acct/',
      stripeGetAccountLink: environment.apiUrl + 'create_connected_acct_link/',
      stripeGetDashLink: environment.apiUrl + 'create_connected_dash_link/',
      stripeOrder: environment.apiUrl + 'stripe_order/',
      paypalOrder: environment.apiUrl + 'paypal_order/',
      paypalCapture: environment.apiUrl + 'paypal_capture/',
      voucherCRUD: environment.apiUrl + 'voucher/',
      voucherApply: environment.apiUrl + 'voucher_apply/',
      voucherUndo: environment.apiUrl + 'voucher_undo/',
      StripeEvts: environment.apiUrl + 'stripe_evts/',
    };
  }

  // ORDER

  /* Add order */
  addOrder(data): Observable<any> {
    return this.http.post<any>(this.endpoints.ordersCrud, data);
  }

  /* Update order */
  updateOrder(ord_id: number, data: any): Observable<any> {
    return this.http.put<any>(this.endpoints.ordersCrud + ord_id, data);
  }

  /* Update stripe order */
  addStripeOrder(data): Observable<any> {
    return this.http.post<any>(this.endpoints.stripeOrder, data);
  }

  /* Update stripe order */
  updateStripeOrder(str_id: number, data: any): Observable<any> {
    return this.http.put<any>(this.endpoints.stripeOrder + str_id, data);
  }

  /* get orders from user */
  getAllOrders(req_id: number) {
    const params = new HttpParams().set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.ordersCrud, {params: params});
  }


  /* get orders from user */
  getOrdersUser(req_id: number) {
    const params = new HttpParams().set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.ordersUser, {params: params});
  }

  /* Get full order info */
  getOrderFull(orders_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('orders_id', orders_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.ordersFull, {params: params});
  }

  /* Make a fake order */
  makeFakePay(data): Observable<any> {
    return this.http.post<any>(this.endpoints.fakeOrder, data);
  }

  /* Delete order */
  deleteOrder(ord_id: number, force?: string): Observable<any> {
    let params = new HttpParams().set('order_id', ord_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.ordersCrud, {params: params});
  }

  /* Download Order PDF */
  downloadOrderPDF(orders_id: number): Observable<Blob> {
    return this.http.get(this.endpoints.orderPdf + orders_id, {responseType: 'blob'});
  }

  // STRIPE

  /* sets a Stripe Checkout Session */
  setStripeCheckout(data) {
    return this.http.post<any>(this.endpoints.stripeCheckout, data);
  }

  /* sets a Stripe Tour Optional Express Checkout Session */
  setStripeTopExpCheckout(data) {
    return this.http.post<any>(this.endpoints.stripeTopExpCheckout, data);
  }

  /* sets a Stripe Tips Express Checkout Session */
  setStripeTipsExpCheckout(data) {
    return this.http.post<any>(this.endpoints.stripeTipExpCheckout, data);
  }

  /* sets a Stripe Donation Express Checkout Session */
  setStripeDonateExpCheckout(data) {
    return this.http.post<any>(this.endpoints.stripeDonateExpCheckout, data);
  }

  /* sets a Stripe Donation Express Checkout Session */
  setStripeEnhanceExpCheckout(data) {
    return this.http.post<any>(this.endpoints.stripeEnhanceExpCheckout, data);
  }

  /* sets a Stripe Tour Optional Checkout Session */
  verifyStripeSession(data) {
    return this.http.post<any>(this.endpoints.stripeVerifySession, data);
  }

  /* gets connected account link */
  createConnectedAccount(data) {
    return this.http.post<any>(this.endpoints.stripeCreateAccount, data);
  }

  /* gets connected account link */
  getConnectedAccount(data) {
    return this.http.post<any>(this.endpoints.stripeGetAccount, data);
  }

  /* gets connected account link */
  getConnectedLink(data) {
    return this.http.post<any>(this.endpoints.stripeGetAccountLink, data);
  }

  /* gets connected account dashboard link */
  getConnectedDashLink(data) {
    return this.http.post<any>(this.endpoints.stripeGetDashLink, data);
  }

  /* gets all payment intents from user */
  getUserPaymentIntents(user_id: number) {
    return this.http.get<any>(this.endpoints.stripePaymentIntents + user_id);
  }

  // PAYPAL

  /* gets a paypal order */
  getPayPalOrder(data) {
    return this.http.post<any>(this.endpoints.paypalOrder, data);
  }

  /* Get a paypal capture */
  getPaypalCapture(data): Observable<any> {
    return this.http.post<any>(this.endpoints.paypalCapture, data);
  }

  // VOUCHER

  /* Store a voucher */
  storeVoucher(data): Observable<any> {
    return this.http.post<any>(this.endpoints.voucherCRUD, data);
  }

  /* Update a voucher */
  updateVoucher(vouch_id: number, data: any): Observable<any> {
    return this.http.put<any>(this.endpoints.voucherCRUD + vouch_id, data);
  }

  /* Undo a voucher */
  undoVoucher(data): Observable<any> {
    return this.http.post<any>(this.endpoints.voucherUndo, data);
  }

  /* Deletes a voucher */
  deleteVoucher(voucher_id: string, force?: string): Observable<any> {
    let params = new HttpParams().set('voucher_id', voucher_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.voucherCRUD, {params: params});
  }

  /* Apply a voucher */
  applyVoucher(user_id, tour_id, name): Observable<any> {
    const params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('user_id', user_id.toString())
      .set('name', name.toString());
    return this.http.get<any>(this.endpoints.voucherApply, {params: params});
  }

  // STRIPE EVENTS

  /* Get full order info */
  getStripeEvents(take?: number, skip?: number, search?: string): Observable<any> {
    let params = new HttpParams();
    if (take) {
      params = params.set('take', take.toString());
    }
    if (skip) {
      params = params.set('skip', skip.toString());
    }
    if (search) {
      params = params.set('search', search.toString());
    }
    return this.http.get<any>(this.endpoints.StripeEvts, {params: params});
  }

  /* Deletes a stripe event */
  deleteStripeEvt(evt_id: string): Observable<any> {
    const params = new HttpParams().set('evt_id', evt_id.toString());
    return this.http.delete<any>(this.endpoints.StripeEvts, {params: params});
  }
}
