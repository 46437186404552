import {Event} from './event.model';
import {Incident} from './incident.model';
import {Itinerary} from './itinerary.model';

export class Day {
  id: number;
  itinerary_id: number;
  date: string;
  nday: number;
  incident: number;
  name: string;
  sunrise: string;
  sunset: string;
  deleted_at: string;
  events_count?: number;
  events?: Event[];
  itinerary?: Itinerary;
  incidents?: Incident[];
  optionals?: any[];
  new_messages?: number;
  cs_pending?: number;
  cs_untouch?: number;
}
