import {City} from './city.model';
import {Hotel} from './hotel.model';
import {Tour} from './tour.model';
import {Day} from './day.model';
import {Room} from './room.model';

export class Itinerary {
  id: number;
  tour_id: string;
  hotel_id: number;
  city_id: number;
  hotel_name: string;
  check_in: string;
  check_out: string;
  travel_by: string;
  flag: string;
  deleted_at: string;
  components_count?: number;
  changed_hotelName?: boolean;
  disable_country?: boolean;
  show_days?: boolean;
  show_rooms?: boolean;
  loading_tb?: boolean;
  rooms?: Room[];
  city?: City;
  hotel?: Hotel;
  tour?: Tour;
  days?: Day[];

  constructor(hotel?: Hotel, hotel_id?: number, hotel_name?: string,
              city?: City, city_id?: number,
              tour_id?: string, check_in?: string, check_out?: string, travel_by?: string) {
    this.tour_id = tour_id ? tour_id : null;
    this.hotel_id = hotel_id ? hotel_id : null;
    this.city_id = city_id ? city_id : null;
    this.hotel_name = hotel_name ? hotel_name : '';
    this.check_in = check_in ? check_in : '';
    this.check_out = check_out ? check_out : '';
    this.travel_by = travel_by ? travel_by : null;
    this.hotel = hotel ? hotel : new Hotel();
    this.city = city ? city : new City();
    this.changed_hotelName = false;
    this.disable_country = false;
  }
}
