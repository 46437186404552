import {Component, OnDestroy, OnInit} from '@angular/core';
import {OutlookService} from '../../../../../shared/services/session/outlook.service';
import {User} from '../../../../../shared/models/user.model';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {Subject} from 'rxjs';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {HttpComponentService} from '../../../../../shared/services/http/http-component.service';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {Title} from '@angular/platform-browser';
import {FlagService} from '../../../../../shared/services/common/flag.service';

@Component({
  selector: 'app-paxinfo',
  templateUrl: './paxinfo.component.html',
  styleUrls: ['./paxinfo.component.scss']
})
export class PaxinfoComponent implements OnInit, OnDestroy {
  // Active tour info
  aT: Activetour;
  currentUser: User;
  prod_id: string;
  returnUrl: string;
  loading = false;
  ub = false;
  et = false;
  ga = false;
  gy = false;
  plus = false;
  add_trs = false;
  add_qds = false;
  max: number;

  sliderFem: any = {max: 0, value: 0};
  addSdisable: boolean;
  subSdisable: boolean;
  addDisable: boolean;
  subDisable: boolean;
  addTDisable: boolean;
  subTDisable: boolean;
  addTrDisable: boolean;
  subTrDisable: boolean;
  addQDisable: boolean;
  subQDisable: boolean;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpTour: HttpTourService,
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private httpComp: HttpComponentService,
    private outlookSvc: OutlookService,
    private snackSvc: SnackbarService,
    private router: Router,
    private flagService: FlagService,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private route: ActivatedRoute
  ) {
    this.titleSvc.setTitle('PAX Info');
    this.webTitleSvc.setTitle('PAX Info | Planafy');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.aT = tour;
        this.ub = tour?.tour.company_id === 2;
        this.et = tour?.tour.company_id === 3;
        this.ga = tour?.tour.company_id === 4;
        this.gy = tour?.tour.company_id === 5;
        this.max = this.et ? 60 : 50;
        this.plus = tour?.tour.code.substring(0, 1) === 'X';
      });
  }

  ngOnInit() {
    // console.log(this.aT);
    this.prod_id = this.route.snapshot.params['tour-prodid'];
    if (this.aT.tour.prodid + '' !== this.prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour?');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour?');
      this.router.navigate(['tours']);
      return;
    }
    // redirect to main if no active tour
    if (!this.activeTourSvc.activeTourValue) {
      this.router.navigate(['tours']);
      return;
    } else {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
      this.aT.tour.npax = (this.aT.tour.npax) ? this.aT.tour.npax : 0;
      this.aT.tour.rms.sin = (this.aT.tour.rms.sin) ? this.aT.tour.rms.sin : 0;
      this.aT.tour.rms.dbl = (this.aT.tour.rms.dbl) ? this.aT.tour.rms.dbl : 0;
      this.aT.tour.rms.twn = (this.aT.tour.rms.twn) ? this.aT.tour.rms.twn : 0;
      this.aT.tour.rms.tri = (this.aT.tour.rms.tri) ? this.aT.tour.rms.tri : 0;
      this.aT.tour.rms.qad = (this.aT.tour.rms.qad) ? this.aT.tour.rms.qad : 0;
      this.aT.tour.rms.fem = (this.aT.tour.rms.fem) ? this.aT.tour.rms.fem : 0;
      this.aT.tour.rms.mal = (this.aT.tour.rms.mal) ? this.aT.tour.rms.mal : 0;
      this.sliderFem.max = this.aT.tour.npax - (this.aT.tour.rms.qad) * 4 - (this.aT.tour.rms.tri) * 3 -
        (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 - this.aT.tour.rms.sin;
      this.sliderFem.value = this.aT.tour.rms.fem;
      // Disable sub if min
      if (this.aT.tour.rms.twn === 0) {
        this.subTDisable = true;
      }
      if (this.aT.tour.rms.dbl === 0) {
        this.subDisable = true;
      }
      if (this.aT.tour.rms.sin === 0) {
        this.subSdisable = true;
      }
      if (this.aT.tour.rms.tri === 0) {
        this.subTrDisable = true;
      }
      if (this.aT.tour.rms.qad === 0) {
        this.subQDisable = true;
      }
      // Disable add qads if max
      if (this.aT.tour.rms.sin + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 +
        (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.qad) * 4 >= this.aT.tour.npax - 3) {
        this.addQDisable = true;
      }
      // Disable add triples if max
      if (this.aT.tour.rms.sin + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 +
        (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.qad) * 4 >= this.aT.tour.npax - 2) {
        this.addTrDisable = true;
      }
      // Disable add doubles if max
      if (this.aT.tour.rms.sin + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 +
        (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.qad) * 4 >= this.aT.tour.npax - 1) {
        this.addDisable = true;
      }
      // Disable add singles if max
      if ((this.aT.tour.rms.sin + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 +
        (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.qad) * 4) >= this.aT.tour.npax) {
        this.addSdisable = true;
      }
      this.add_trs = !!this.aT.tour.rms.tri || !!this.aT.tour.rms.qad;
      this.add_qds = !!this.aT.tour.rms.qad;
    }
  }

  onCalcSlider(tot) {
    this.sliderFem.max = this.aT.tour.npax - tot;
    this.sliderFem.value = Math.floor(this.sliderFem.max / 2);
    this.aT.tour.rms.fem = Math.ceil(this.sliderFem.max / 2);
    this.aT.tour.rms.mal = this.sliderFem.max - this.aT.tour.rms.fem;
  }

  onAddPax() {
    if (this.et && this.aT.tour.npax >= this.max) {
      this.max = this.max + 20;
    } else {
      this.onChangePax({value: this.aT.tour.npax + 1});
    }
  }

  onAddToggle(tot) {
    if (tot >= this.aT.tour.npax - 3) {
      this.addQDisable = true;
    }
    if (tot >= this.aT.tour.npax - 2) {
      this.addTrDisable = true;
    }
    if (tot >= this.aT.tour.npax - 1) {
      this.addDisable = true;
      this.addTDisable = true;
    }
    if (tot >= this.aT.tour.npax) {
      this.addSdisable = true;
    }
  }

  onSubToggle(tot) {
    if (tot <= 0) {
      this.subSdisable = true;
      this.subDisable = true;
      this.subTDisable = true;
      this.subTrDisable = true;
      this.subQDisable = true;
    }
    if (tot < this.aT.tour.npax) {
      this.addSdisable = false;
    }
    if (tot < this.aT.tour.npax - 1) {
      this.addDisable = false;
      this.addTDisable = false;
    }
    if (tot < this.aT.tour.npax - 2) {
      this.addTrDisable = false;
    }
    if (tot < this.aT.tour.npax - 3) {
      this.addQDisable = false;
    }
  }

  onChangePax(event: any): void {
    this.aT.tour.npax = event.value;
    // Changing PAX cut females/males in half
    this.aT.tour.rms.fem = Math.ceil((event.value - (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 - this.aT.tour.rms.sin) / 2);
    this.aT.tour.rms.mal = this.sliderFem.max - this.aT.tour.rms.fem;
    let tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
    // If PAX change to smaller than 4 * qads + 3 * tri + 2 * db/tw + sin, restart count cutting doubles in half
    if (event.value < tot) {
      // Adjust slider for males/females
      this.sliderFem.max = 0;
      this.sliderFem.value = 0;
      this.aT.tour.rms.dbl = 0;
      this.aT.tour.rms.twn = 0;
      this.aT.tour.rms.sin = 0;
      this.aT.tour.rms.tri = 0;
      this.aT.tour.rms.qad = 0;
      tot = 0;
      this.subQDisable = true;
      this.subTrDisable = true;
      this.subDisable = true;
      this.subTDisable = true;
      this.subSdisable = true;
      this.addQDisable = event.value <= tot;
      this.addTrDisable = event.value <= tot;
      this.addSdisable = event.value <= tot;
      this.addDisable = event.value <= tot;
      this.addTDisable = event.value <= tot;
    } else if (event.value === tot) {
      this.addQDisable = true;
      this.addTrDisable = true;
      this.addDisable = true;
      this.addTDisable = true;
      this.addSdisable = true;
    } else {
      this.addQDisable = false;
      this.addTrDisable = false;
      this.addDisable = false;
      this.addTDisable = false;
      this.addSdisable = false;
      if (event.value === 1 + tot) {
        this.addTDisable = true;
        this.addDisable = true;
        this.addTrDisable = true;
        this.addQDisable = true;
      } else if (event.value === 2 + tot) {
        this.addTrDisable = true;
        this.addQDisable = true;
      } else if (event.value === 3 + tot) {
        this.addQDisable = true;
      }
    }
    // Adjust slider for males/females
    this.sliderFem.max = event.value - tot;
    this.sliderFem.value = Math.ceil(this.sliderFem.max / 2);
    this.aT.tour.rms.fem = Math.ceil(this.sliderFem.max / 2);
    this.aT.tour.rms.mal = this.sliderFem.max - this.aT.tour.rms.fem;
    if (this.aT.tour.npax === 0) {
      this.addDisable = true;
      this.addTDisable = true;
      this.subDisable = true;
      this.subTDisable = true;
      this.addSdisable = true;
      this.subSdisable = true;
      this.addTrDisable = true;
      this.subTrDisable = true;
      this.addQDisable = true;
      this.subQDisable = true;
    }
  }

  onChangeDist(event): void {
    this.sliderFem.value = event.value;
    this.aT.tour.rms.fem = event.value;
    const tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
    this.aT.tour.rms.mal = this.aT.tour.npax - tot - event.value;
  }

  addQds() {
    let tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
    if (tot < this.aT.tour.npax) {
      this.aT.tour.rms.qad += 1;
      tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.aT.tour.rms.qad > 0) {
        this.subQDisable = false;
      }
    }
  }

  subQds() {
    if (this.aT.tour.rms.qad > 0) {
      this.aT.tour.rms.qad -= 1;
      const tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.aT.tour.rms.qad <= 0) {
        this.subQDisable = true;
      }
    }
  }

  addTrs() {
    let tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
    if (tot < this.aT.tour.npax) {
      this.aT.tour.rms.tri += 1;
      tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.aT.tour.rms.tri > 0) {
        this.subTrDisable = false;
      }
    }
  }

  subTrs() {
    if (this.aT.tour.rms.tri > 0) {
      this.aT.tour.rms.tri -= 1;
      const tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.aT.tour.rms.tri <= 0) {
        this.subTrDisable = true;
      }
    }
  }

  addDbs() {
    let tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
    if (tot < this.aT.tour.npax - 1) {
      this.aT.tour.rms.dbl += 1;
      tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.aT.tour.rms.dbl > 0) {
        this.subDisable = false;
      }
    }
  }

  subsDbs() {
    if (this.aT.tour.rms.dbl > 0) {
      this.aT.tour.rms.dbl -= 1;
      const tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.aT.tour.rms.dbl <= 0) {
        this.subDisable = true;
      }
    }
  }

  addTws() {
    let tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
    if (tot < this.aT.tour.npax - 1) {
      this.aT.tour.rms.twn += 1;
      tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.aT.tour.rms.twn > 0) {
        this.subTDisable = false;
      }
    }
  }

  subsTws() {
    if (this.aT.tour.rms.twn > 0) {
      this.aT.tour.rms.twn -= 1;
      const tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.aT.tour.rms.twn <= 0) {
        this.subTDisable = true;
      }
    }
  }

  addSings() {
    let tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
    if (tot < this.aT.tour.npax) {
      this.aT.tour.rms.sin += 1;
      tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.aT.tour.rms.sin > 0) {
        this.subSdisable = false;
      }
    }
  }

  subSings() {
    if (this.aT.tour.rms.sin > 0) {
      this.aT.tour.rms.sin -= 1;
      const tot = (this.aT.tour.rms.qad) * 4 + (this.aT.tour.rms.tri) * 3 + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 + this.aT.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.aT.tour.rms.sin <= 0) {
        this.subSdisable = true;
      }
    }
  }

  onSave(where) {
    if (!this.aT.tour.prodid) {
      this.snackSvc.openSnackBar('Tour ID required');
      this.router.navigate(['tourinfo']);
      return;
    }
    if (!this.aT.tour.npax) {
      this.snackSvc.openSnackBar('PAX info required');
      return;
    }
    if ((this.aT.tour.rms.sin + (this.aT.tour.rms.dbl + this.aT.tour.rms.twn) * 2 < this.aT.tour.npax) &&
      !this.aT.tour.rms.fem && !this.aT.tour.rms.mal) {
      this.snackSvc.openSnackBar('Error. Gender distribution needed');
      return;
    }
    this.loading = true;
    const params = {
      'req_id': this.currentUser.id,
      'tour_starts': this.aT.tour.tour_starts,
      'npax': this.aT.tour.npax,
      'nsingles': this.aT.tour.rms.sin,
      'ndoubles': this.aT.tour.rms.dbl,
      'ntwins': this.aT.tour.rms.twn,
      'ntriples': this.aT.tour.rms.tri,
      'nquads': this.aT.tour.rms.qad,
      'nfemales': this.aT.tour.rms.fem,
      'nmales': this.aT.tour.rms.mal,
      'create_rooms': true,
      'change_npax': true,
    };
    this.httpTour.updateTour(this.aT.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res2 => {
        console.log(res2);
        if (res2.status === 203) {
          if (this.returnUrl && this.returnUrl !== 'undefined') {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate([where, this.aT.tour.prodid]);
          }
        } else {
          if (res2.results) {
            this.snackSvc.openSnackBar(res2.results[Object.keys(res2.results)[0]].toString());
          } else {
            console.log(res2.message.toString());
          }
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }, error2 => {
        this.loading = false;
        this.snackSvc.openSnackBar('Error. Contact support');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error updateTour() paxinfo', JSON.stringify(error2));
        console.log(error2);
      });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
