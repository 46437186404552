import {User} from './user.model';
import {Tour} from './tour.model';

export class Voucher {
  id: number;
  user_id: number;
  tour_id: number;
  name: string;
  tour_cost: any;
  price_pday: any;
  amount_off: any;
  used_at: string;
  updated_at: string;
  deleted_at: string;
  tooltip?: string;
  open?: boolean;
  edit_mode?: boolean;
  user?: User;
  tour?: Tour;

  constructor() {
    this.id = null;
    this.name = null;
  }
}
