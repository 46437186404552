import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';

@Injectable({providedIn: 'root'})
export class OutlookService {
  private endpoints: {
    mailer_url: string,
    mailer_token: string,
    mailer_register: string,
    mailer_get_me: string,
    mailer_read_mail: string,
    mailer_read_tour_mail: string,
    mailer_write_mail: string,
    mailer_write_rooming: string,
    mailer_send_rooming: string,
    mailer_update_mail: string,
    mailer_attach_file: string,
    mailer_remove_file: string,
    mailer_write_mails_tour: string,
    mailer_delete_message: string,
    mailer_delete_msg_id: string,
    mailer_delete_room_msg: string,
    mailer_send_message: string,
    mailer_updsend_message: string,
    mailer_move_message: string,
    mailer_send_driver: string,
    mailer_bfast_mail: string,
    mailer_reply_message: string,
    mailer_create_EF_folder: string,
    mailer_create_tour_folder: string,
    mailer_delete_tour_folder: string,
    mailer_get_conversation: string,
    mailer_get_contacts: string,
    mailer_add_contact: string,
    mailer_update_contact: string,
    mailer_import_contacts: string,
    mailer_delete_contacts: string,
    mailer_get_mails: string,
    mailer_update_pax: string,
    mailer_update_diets: string,
    laravel_send_message: string,
  };

  constructor(
    private http: HttpClient,
    private authSvc: AuthenticationService
  ) {
    this.endpoints = {
      mailer_url: environment.apiUrl + 'mailer_url',
      mailer_token: environment.apiUrl + 'mailer_token',
      mailer_register: environment.apiUrl + 'mailer_register',
      mailer_get_me: environment.apiUrl + 'mailer_get_me',
      mailer_read_mail: environment.apiUrl + 'mailer_read_mail',
      mailer_read_tour_mail: environment.apiUrl + 'mailer_read_tour_mail',
      mailer_write_mail: environment.apiUrl + 'mailer_write_mail',
      mailer_update_mail: environment.apiUrl + 'mailer_update_mail',
      mailer_write_rooming: environment.apiUrl + 'mailer_write_rooming',
      mailer_send_rooming: environment.apiUrl + 'mailer_send_rooming',
      mailer_attach_file: environment.apiUrl + 'mailer_attach_file',
      mailer_remove_file: environment.apiUrl + 'mailer_remove_file',
      mailer_write_mails_tour: environment.apiUrl + 'mailer_write_mails_tour',
      mailer_delete_message: environment.apiUrl + 'mailer_delete_message',
      mailer_delete_msg_id: environment.apiUrl + 'mailer_delete_msg_id',
      mailer_delete_room_msg: environment.apiUrl + 'mailer_delete_room_msg',
      mailer_send_message: environment.apiUrl + 'mailer_send_message',
      mailer_reply_message: environment.apiUrl + 'mailer_reply_message',
      mailer_updsend_message: environment.apiUrl + 'mailer_updsend_message',
      mailer_move_message: environment.apiUrl + 'mailer_move_message',
      mailer_send_driver: environment.apiUrl + 'mailer_send_driver',
      mailer_bfast_mail: environment.apiUrl + 'mailer_bfast_mail',
      mailer_create_EF_folder: environment.apiUrl + 'mailer_create_EF_folder',
      mailer_create_tour_folder: environment.apiUrl + 'mailer_create_tour_folder',
      mailer_delete_tour_folder: environment.apiUrl + 'mailer_delete_tour_folder',
      mailer_get_conversation: environment.apiUrl + 'mailer_get_conversation',
      mailer_get_contacts: environment.apiUrl + 'mailer_get_contacts',
      mailer_add_contact: environment.apiUrl + 'mailer_add_contact',
      mailer_update_contact: environment.apiUrl + 'mailer_update_contact',
      mailer_import_contacts: environment.apiUrl + 'mailer_import_contacts',
      mailer_delete_contacts: environment.apiUrl + 'mailer_delete_contacts',
      mailer_get_mails: environment.apiUrl + 'mailer_get_mails',
      mailer_update_pax: environment.apiUrl + 'mailer_update_pax',
      mailer_update_diets: environment.apiUrl + 'mailer_update_diets',
      laravel_send_message: environment.apiUrl + 'laravel_send_message',
    };
  }

  outlookGetUrl(url: string): Observable<any> {
    const params = new HttpParams()
      .set('environment_url', url.toString());
    return this.http.get<any>(this.endpoints.mailer_url, {params: params});
  }

  outlookGetToken(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_token, data);
  }

  registerOutlook(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_register, data)
      .pipe(map(res => {
        // login successful if there's a jwt token in the response
        if (res.results && res.results.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', btoa(JSON.stringify(res.results)));
          localStorage.setItem('accessToken', JSON.stringify(res.results.access_token));
          this.authSvc.updateUser(res.results);
        }
        return res;
      }));
  }

  outlookMail(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_read_mail, data);
  }

  outlookTourMail(data) {
    return this.http.post<any>(this.endpoints.mailer_read_tour_mail, data);
  }

  writeMessage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_write_mail, data);
  }

  writeHotelRooming(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_write_rooming, data);
  }

  sendHotelRooming(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_send_rooming, data);
  }

  getConversations(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_get_conversation, data);
  }

  getUserMails(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_get_mails, data);
  }

  async getUserMailsAsync(data) {
    return await this.http.post<any>(this.endpoints.mailer_get_mails, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  updateMessage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_update_mail, data);
  }

  removeAttachment(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_remove_file, data);
  }

  async getConvos(data) {
    return await this.http.post<any>(this.endpoints.mailer_get_conversation, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async attachFile(data) {
    return await this.http.post<any>(this.endpoints.mailer_attach_file, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async getContacts(data) {
    return await this.http.post<any>(this.endpoints.mailer_get_contacts, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async saveContact(data) {
    return await this.http.post<any>(this.endpoints.mailer_add_contact, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  updateContact(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_update_contact, data);
  }


  async deleteContacts(data) {
    return await this.http.post<any>(this.endpoints.mailer_delete_contacts, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }


  async importContacts(data) {
    return await this.http.post<any>(this.endpoints.mailer_import_contacts, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }


  whoAmI(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_get_me, data);
  }

  sendUpdMessage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_updsend_message, data);
  }

  replyMessage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_reply_message, data);
  }

  updatePaxMessage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_update_pax, data);
  }

  updateDietsMessage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_update_diets, data);
  }

  sendLaravelMessage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.laravel_send_message, data);
  }

  async sendMessage(data) {
    return await this.http.post<any>(this.endpoints.mailer_send_message, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async sendDriverEmail(data) {
    return await this.http.post<any>(this.endpoints.mailer_send_driver, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async sendBfastEmail(data) {
    return await this.http.post<any>(this.endpoints.mailer_bfast_mail, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async moveMessage(data) {
    return await this.http.post<any>(this.endpoints.mailer_move_message, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  deleteMessage(data): Observable<any> {
    return this.http.delete<any>(this.endpoints.mailer_delete_message, {params: data});
  }

  deleteMessageId(data): Observable<any> {
    return this.http.delete<any>(this.endpoints.mailer_delete_msg_id, {params: data});
  }

  deleteRoomingMessage(data): Observable<any> {
    return this.http.delete<any>(this.endpoints.mailer_delete_room_msg, {params: data});
  }

  async createEFFolder(data) {
    return await this.http.post<any>(this.endpoints.mailer_create_EF_folder, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  createTourFolder(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mailer_create_tour_folder, data);
  }

  deleteTourFolder(data): Observable<any> {
    return this.http.delete<any>(this.endpoints.mailer_delete_tour_folder, {params: data});
  }

}
