import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Activetour} from '../../models/activetour.model';
import {Router} from '@angular/router';
import {Tour} from '../../models/tour.model';
import {User} from '../../models/user.model';
import {TourPax} from '../../models/tour-pax.model';
import {Components} from '../../models/components.model';

class TDIcomps {
  prodid: string;
  comps: Components[];
}

@Injectable({providedIn: 'root'})
export class ActivetourService {
  public activeTour: Observable<Activetour>;
  public toursUser: Tour[];
  public toursPax: TourPax[];
  public TDIcomps: TDIcomps[] = [];
  private activeTourSubject: BehaviorSubject<Activetour>;

  constructor(private router: Router) {
    this.activeTourSubject = new BehaviorSubject<Activetour>(JSON.parse(sessionStorage.getItem('activeTour')));
    this.activeTour = this.activeTourSubject.asObservable();
  }

  // Get active tour
  public get activeTourValue(): Activetour {
    if (this.activeTourSubject.value === undefined) {
      this.activeTourSubject = JSON.parse(sessionStorage.getItem('activeTour'));
    }
    return this.activeTourSubject.value;
  }

  bytesToHumanReadable(numBytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const unitIndex = Math.floor(Math.log(numBytes) / Math.log(1024));
    const value = numBytes / Math.pow(1024, unitIndex);
    return `${value.toFixed(2)} ${units[unitIndex]}`;
  }

  setActiveTour(active_tour: Activetour) {
    localStorage.removeItem('activeTour');
    this.activeTourSubject.next(active_tour);
    try {
      sessionStorage.setItem('activeTour', JSON.stringify(active_tour));
    } catch (e) {
      console.log(e);
    }
  }

  setToursUser(list: Tour[]) {
    list = list.map(tU => {
      const {itinerary, rms, ...rest} = tU;
      return rest;
    });
    this.toursUser = list;
    localStorage.setItem('toursUser', JSON.stringify(this.toursUser));
  }

  setToursPax(list: TourPax[]) {
    this.toursPax = list;
    localStorage.setItem('toursPax', JSON.stringify(this.toursPax));
  }

  getToursUser(): Tour[] {
    if (this.toursUser) {
      return this.toursUser;
    } else {
      return JSON.parse(localStorage.getItem('toursUser'));
    }
  }

  getToursPax(): TourPax[] {
    if (this.toursPax) {
      return this.toursPax;
    } else {
      return JSON.parse(localStorage.getItem('toursPax'));
    }
  }

  addTour2User(tour: Tour) {
    if (!this.toursUser) {
      this.toursUser = JSON.parse(localStorage.getItem('toursUser'));
    }
    if (typeof this.toursUser !== 'object') {
      this.toursUser = [];
    }
    // Only add if not already present
    const idx = this.toursUser.findIndex(i => '' + i.prodid === '' + tour.prodid);
    if (idx < 0) {
      const {itinerary, rms, ...rest} = tour;
      this.toursUser.push(rest);
    }
    localStorage.setItem('toursUser', JSON.stringify(this.toursUser));
  }

  belongsToUser(user: User) {
    const cond1 = '' + user.id === '' + this.activeTourValue.tour.user_id;
    const cond2 = user.view_as && '' + user.view_as === '' + this.activeTourValue.tour.user_id;
    const cond3 = this.activeTourValue.tour.tour_access?.findIndex(ta => +ta.user_id === +user.id) >= 0;
    return cond1 || cond2 || cond3;
  }

  setTDIcomps(prodid: string, list: Components[]) {
    const idx = this.TDIcomps.findIndex(tdi => '' + tdi.prodid === '' + prodid);
    if (idx >= 0) {
      this.TDIcomps[idx].comps = list;
    } else {
      this.TDIcomps.push({
        'prodid': prodid,
        'comps': list
      });
    }
    localStorage.setItem('TDIcomps', JSON.stringify(this.TDIcomps));
  }

  getTDIcomps(prodid: string): Components[] {
    if (this.TDIcomps.length > 0) {
      const idx = this.TDIcomps.findIndex(tdi => '' + tdi.prodid === '' + prodid);
      if (idx >= 0) {
        return this.TDIcomps[idx].comps;
      } else {
        return [];
      }
    } else {
      const data = JSON.parse(localStorage.getItem('TDIcomps'));
      if (!data) {
        return [];
      }
      const idx = data.findIndex(tdi => '' + tdi.prodid === '' + prodid);
      if (idx >= 0) {
        return data[idx].comps;
      } else {
        return [];
      }
    }
  }
}
