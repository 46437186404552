import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpImageService} from '../../services/http/http-image.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SnackbarService} from '../../services/common/snackbar.service';
import {Image} from '../../models/image.model';
import {environment} from '../../../../environments/environment';

export interface OpenGalleryData {
  user_id: number;
  city_id: number;
  image: any;
  reason: string;
}

@Component({
  selector: 'app-open-gallery',
  templateUrl: './open-gallery.component.html',
  styleUrls: ['./open-gallery.component.scss']
})
export class OpenGalleryComponent implements OnInit, OnDestroy {

  all_city_images: Image[] = [];
  baseUrl: string;
  loading = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(private httpImageSvc: HttpImageService,
              private snackSvc: SnackbarService,
              public dialogRef: MatDialogRef<OpenGalleryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: OpenGalleryData) {
  }

  ngOnInit(): void {
    this.baseUrl = environment.baseUrl;
    this.getAllImages();
  }

  getAllImages() {
    this.loading = true;
    this.httpImageSvc.getImagesCity(this.data.city_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.all_city_images = res.results.images;
        } else {
          this.snackSvc.resultsElse(res);
        }
        this.loading = false;
      }, error => {
        this.loading = false;
        this.snackSvc.openSnackBar('Error getting all images for city ' + this.data.city_id);
        console.log(error);
      });
  }

  onSelectImage(img) {
    this.data.image = img;
    this.close('save');
  }

  onDeleteImage(img) {
    const snackbarRef = this.snackSvc.openSnackBar('Delete image?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpImageSvc.deleteImage(img.id, this.data.user_id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              console.log(res);
              if (res.status < 400) {
                const idx = this.all_city_images.findIndex(im => +im.id === +img.id);
                this.all_city_images.splice(idx, 1);
              } else {
                this.snackSvc.resultsElse(res);
              }
            }, error => {
              this.snackSvc.openSnackBar('Error deleting image ' + img.id);
              console.log(error);
            });
        }
      });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
