import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../services/session/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: string;

  constructor(private authSvc: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('accessToken') !== 'undefined') {
      this.token = JSON.parse(localStorage.getItem('accessToken'));
    }
    // add authorization header with jwt token if available
    const currentUser = this.authSvc.currentUserValue;
    // if (currentUser && currentUser.access_token) {
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          // Authorization: `Bearer ${currentUser.access_token}`
          Authorization: 'Bearer ' + this.token
        }
      });
    }

    return next.handle(request);
  }
}
