import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MySnackbarComponent} from '../../components/my-snackbar/my-snackbar.component';

@Injectable({providedIn: 'root'})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) {
  }

  openSnackBar(message: string, action?: string, duration?: number) {
    return this.snackbar.openFromComponent(MySnackbarComponent, {
      panelClass: 'snack5',
      duration: duration ? duration * 1000 : 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: {message: message, action: action}
    });
  }

  resultsElse(res) {
    if (res.results) {
      this.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
    } else {
      this.openSnackBar(res.message ? res.message.toString() : res);
    }
  }

}
