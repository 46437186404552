import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {environment} from '../../../../../environments/environment';
import {HttpSessionService} from '../../../../shared/services/http/http-session.service';

@Component({
  selector: 'app-edit-pax',
  templateUrl: './edit-pax.component.html',
  styleUrls: ['./edit-pax.component.scss']
})
export class EditPaxComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  loadingTourPax = false;
  tokenResetPwd: string;
  name: string;
  gender: string;
  room: string;
  admin: boolean;
  active: boolean;
  hidden: boolean;
  busDriver: boolean;
  show_join_ago = true;
  show_join_ago_txt: string;
  show_last_ago = true;
  show_last_ago_txt: string;
  interval: any;

  constructor(public dialogRef: MatDialogRef<EditPaxComponent>,
              private httpUser: HttpUserService,
              private httpTour: HttpTourService,
              private httpSession: HttpSessionService,
              private snackSvc: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public data: { tourpax: TourPax, req_id: number, reason: string, cia_id: number }) {
    this.name = this.data.tourpax.user.name;
    this.gender = this.data.tourpax.user.gender;
    this.room = this.data.tourpax.room;
    this.active = this.data.tourpax.active;
    this.hidden = this.data.tourpax.hidden;
    this.admin = this.data.tourpax.user.role === 2;
    this.busDriver = this.data.tourpax.user.email === 'bus_driver@planafy.com';
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Enter') {
      this.submit();
    }
  }

  calcTime() {
    let time_amount;
    let time_units;
    const startDate = new Date(this.data.tourpax.updated_at);
    const endDate = new Date();
    let seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 30)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24 * 30) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.floor(seconds / (60 * 60 * 24 * 30));
      time_units = 'month' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_last_ago_txt = time_amount + ' ' + time_units + ' ago';
    const startDate2 = new Date(this.data.tourpax.created_at);
    const endDate2 = new Date();
    seconds = (endDate2.getTime() - startDate2.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 30)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24 * 30) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.floor(seconds / (60 * 60 * 24 * 30));
      time_units = 'month' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_join_ago_txt = time_amount + ' ' + time_units + ' ago';
  }

  ngOnInit(): void {
    this.calcTime();
    this.interval = setInterval(() => {
      this.calcTime();
    }, 1000);
  }

  submit(): void {
    this.onTourPaxChange();
  }

  recentOn(upd) {
    const min = 4; // 3min
    return new Date(upd).getTime() + min * 60 * 1000 >= new Date().getTime();
  }

  showJoinTime() {
    this.show_join_ago = false;
    setTimeout(() => {
      this.show_join_ago = true;
    }, 5000);
  }

  showLastTime() {
    this.show_last_ago = false;
    setTimeout(() => {
      this.show_last_ago = true;
    }, 5000);
  }

  onTourPaxChange() {
    this.loadingTourPax = true;
    const data = {
      'req_id': this.data.req_id,
      'name': this.name,
      'gender': this.gender,
      'role': this.admin ? 2 : 1,
      'room': this.room,
      'hidden': this.hidden,
      'active': this.active,
    };
    // console.log(data);
    this.httpTour.updateTourPax(this.data.tourpax.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loadingTourPax = false;
          if (res.status < 400) {
            this.data.tourpax = res.results.tp;
            this.close('save');
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.loadingTourPax = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating profile');
        });
  }

  async sendTokenAsync() {
    this.loadingTourPax = true;
    const res = await this.httpSession.requestPasswordToken({email: this.data.tourpax.user.email, url: environment.selfUrl});
    console.log(res);
    if (res.status < 400) {
      this.tokenResetPwd = res.results;
      this.snackSvc.openSnackBar('Email sent');
      this.loadingTourPax = false;
    } else {
      this.snackSvc.openSnackBar('Error');
      this.loadingTourPax = false;
    }
  }

  onDeleteUser() {
    this.loadingTourPax = true;
    this.httpUser.deleteUser(this.data.tourpax.user.id, this.data.req_id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.close('delete');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingTourPax = false;
        },
        error => {
          console.log(error);
          this.loadingTourPax = false;
          this.snackSvc.openSnackBar('Error deleting user');
        });
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
