import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpSessionService} from '../../../shared/services/http/http-session.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  signupForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  errorMessage: string;
  infoMessage: string;
  url: string;
  filteroff = true;
  filtervisible = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthenticationService,
    private httpsessionService: HttpSessionService,
  ) {
    // redirect to main if already logged in
    if (this.authSvc.currentUserValue) {
      this.router.navigate(['tours']);
    }
  }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    // get return url from route parameters or default to 'tours'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'tours';
    this.url = window.location.href.split('/signup')[0];
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signupForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.signupForm.invalid) {
      this.errorMessage = 'Please fill all fields';
      return;
    }

    if (!this.filteroff && this.f.email.value.split('@')[1] !== 'ef.com') {
      this.errorMessage = 'Please use a valid work email';
      this.filtervisible = true;
      return;
    }

    this.loading = true;
    this.httpsessionService.registerNewUser({
      name: this.f.name.value,
      email: this.f.email.value,
      password: this.f.password.value,
      url: this.url
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== 201) {
            this.loading = false;
            if (data.results) {
              this.errorMessage = data.results[Object.keys(data.results)[0]].toString();
            } else {
              this.errorMessage = data.message.toString();
            }
          } else {
            this.authSvc.login({
              email: this.f.email.value,
              password: this.f.password.value
            })
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                data2 => {
                  console.log(data2);
                  this.router.navigate([this.returnUrl]);
                },
                error2 => {
                  console.log(error2);
                  this.errorMessage = error2;
                  this.loading = false;
                });
          }
        },
        error => {
          console.log(error);
          this.errorMessage = error;
          this.loading = false;
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
