import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpSessionService} from '../../../shared/services/http/http-session.service';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {SnackbarService} from '../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-resetpwd',
  templateUrl: './resetpwd.component.html',
  styleUrls: ['./resetpwd.component.scss']
})
export class ResetpwdComponent implements OnInit, OnDestroy {
  loading = false;
  disabled = false;
  resend = false;
  returnUrl: string;
  errorMessage: string;
  tokenResetPwd: string;
  emailUser: string;

  passwordType1 = 'password';
  passwordType2 = 'password';
  passwordIcon1 = 'visibility';
  passwordIcon2 = 'visibility';

  password: string;
  passwordRepeat: string;
  url: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackSvc: SnackbarService,
    private authSvc: AuthenticationService,
    private httpSession: HttpSessionService,
  ) {
    // redirect to main if already logged in
    if (this.authSvc.currentUserValue) {
      this.router.navigate(['home']);
    }
  }

  ngOnInit() {
    // get return url from route parameters or default to 'tours'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    this.emailUser = this.route.snapshot.queryParams['email'] || null;
    this.tokenResetPwd = this.route.snapshot.queryParams['token'] || null;
    this.url = window.location.href.split('password/reset')[0];
    if (!environment.production) {
      console.log(this.tokenResetPwd);
      console.log(this.emailUser);
      console.log(this.returnUrl);
    }
    this.router.navigate([], {
      queryParams: {
        'email': null,
        'token': null,
        'returnUrl': null,
      },
      queryParamsHandling: 'merge'
    });
  }

  onSubmit() {
    if (this.emailUser && this.emailUser.includes('@ef.com')) {
      this.errorMessage = 'TDs do not use password, use Outlook login';
      this.disabled = true;
      return;
    }
    if (!this.tokenResetPwd) {
      this.disabled = true;
      this.errorMessage = 'No token: click recovery link again';
      return;
    }
    if (!this.emailUser) {
      this.errorMessage = 'Error: click recovery link again';
      this.disabled = true;
      return;
    }
    if (!this.password) {
      this.errorMessage = 'Please insert password';
      return;
    }
    if (this.password.length < 6) {
      this.errorMessage = 'Use at least 6 characters';
      return;
    }
    if (!this.passwordRepeat) {
      this.errorMessage = 'Please repeat password';
      return;
    }
    if (this.password !== this.passwordRepeat) {
      this.errorMessage = 'Passwords are different';
      return;
    }
    this.asyncReqPwd();
  }

  async asyncReqPwd() {
    this.loading = true;
    this.errorMessage = null;
    const res = await this.httpSession.requestPasswordChange(
      {
        email: this.emailUser,
        password: this.password,
        password_confirmation: this.passwordRepeat,
        token: this.tokenResetPwd
      });
    console.log(res);
    if (res.status < 400) {
      if (this.returnUrl?.includes('optional_activity')) {
        this.onLogIn(this.emailUser, this.password);
      } else {
        window.location.href = environment.appUrl;
      }
    } else {
      this.loading = false;
      if (res.results && res.results.error === 'This password reset token is invalid') {
        this.resend = true;
      }
      if (res.results) {
        this.errorMessage = res.results[Object.keys(res.results)[0]].toString();
      } else {
        this.errorMessage = res.message.toString();
      }
    }
  }

  onLogIn(email, password) {
    const data = {email: email, password: password};
    // console.log(data);
    this.authSvc.login(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loading = false;
          if (res.status === 200) {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(['home']);
            }
          } else {
            if (res.results) {
              const err = res.results[Object.keys(res.results)[0]].toString();
              this.snackSvc.openSnackBar(err);
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.loading = false;
          this.errorMessage = error;
        });
  }

  eventHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }

  hideShowPassword(x) {
    if (x === 1) {
      this.passwordType1 = this.passwordType1 === 'text' ? 'password' : 'text';
      this.passwordIcon1 = this.passwordIcon1 === 'visibility_off' ? 'visibility' : 'visibility_off';
    } else {
      this.passwordType2 = this.passwordType2 === 'text' ? 'password' : 'text';
      this.passwordIcon2 = this.passwordIcon2 === 'visibility_off' ? 'visibility' : 'visibility_off';
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  async sendTokenAsync() {
    if (!this.emailUser) {
      this.errorMessage = 'Error. No email';
      return;
    }
    this.errorMessage = null;
    this.loading = true;
    const res = await this.httpSession.requestPasswordToken({email: this.emailUser, url: this.url});
    console.log(res);
    if (res.status < 400) {
      this.loading = false;
      this.resend = false;
      this.disabled = true;
      this.snackSvc.openSnackBar(res.message.toString());
    } else {
      this.loading = false;
      if (res.results) {
        this.errorMessage = res.results[Object.keys(res.results)[0]].toString();
      } else {
        this.errorMessage = res.message.toString();
      }
    }
  }
}
