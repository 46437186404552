import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpLocatorService {

  private endpoints: {
    allLocators: string,
    crudLocator: string,
    locatorUser: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allLocators: environment.apiUrl + 'locators/',
      crudLocator: environment.apiUrl + 'locator/',
      locatorUser: environment.apiUrl + 'locator_user/',
    };
  }

  // LOCATOR

  /* gets all locators */
  getAllLocators() {
    return this.http.get<any>(this.endpoints.allLocators);
  }

  /* Creates a new search in finder */
  createLocatorSearch(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudLocator, data);
  }

  /* Gets all locators from one user */
  getLocatorsUser(user_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('user_id', user_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.locatorUser, {params: params});
  }

  /* Delete finder */
  deleteLocator(loc_id: number) {
    return this.http.delete<any>(this.endpoints.crudLocator + loc_id);
  }

}
