import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpFlightService {
  private endpoints: {
    allFlightCalls: string,
    crudFlightCalls: string,
    allTPFlight: string,
    crudTPFlight: string,
    tpFlightApi: string
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allFlightCalls: environment.apiUrl + 'flight_call/',
      crudFlightCalls: environment.apiUrl + 'flight_calls/',
      crudTPFlight: environment.apiUrl + 'tourpax_flight/',
      allTPFlight: environment.apiUrl + 'tourpax_flights/',
      tpFlightApi: environment.apiUrl + 'tourpax_flight_api/',
    };
  }

  /* Gets all flights from tour */
  getFlightsTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudTPFlight + tour_id);
  }

  /* Creates a tour_pax flight */
  createTPflight(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.crudTPFlight, data);
  }
}
