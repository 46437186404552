import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {Subject} from 'rxjs';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {Router} from '@angular/router';
import {formatDate} from '@angular/common';

export interface DialogDataDupe {
  user_id: number;
  tour_id: number;
  reason: string;
}

@Component({
  selector: 'app-dupe-tour',
  templateUrl: './dupe-tour.component.html',
  styleUrls: ['./dupe-tour.component.scss']
})
export class DupeTourComponent {
  loading = false;
  reason: string;
  private onDestroy$ = new Subject<boolean>();

  new_prodid: string;
  tour_starts: string;
  npax: number;

  constructor(
    private router: Router,
    private httpTour: HttpTourService,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<DupeTourComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDupe) {
  }

  info() {
    console.log(this.data);
  }

  duplicateTour() {
    if (!this.new_prodid) {
      this.snackSvc.openSnackBar('Please insert prod id');
      return;
    }
    if (!this.tour_starts) {
      this.snackSvc.openSnackBar('Please insert start date');
      return;
    }
    this.loading = true;
    const data = {
      'user_id': this.data.user_id,
      'tour_id': this.data.tour_id,
      'prodid': this.new_prodid,
      'tour_starts': formatDate(this.tour_starts, 'yyyy-MM-dd', 'en'),
    };
    // console.log(data);
    this.httpTour.duplicateTour(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar('Tour duplicated');
            this.close('ok');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error duplicating tour');
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
