import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpHotelService {

  private endpoints: {
    allHotels: string,
    hotelsCity: string,
    hotelsTour: string,
    crudHotel: string,
    uploadHotelImage: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allHotels: environment.apiUrl + 'hotels/',
      hotelsCity: environment.apiUrl + 'hotels_city/',
      hotelsTour: environment.apiUrl + 'hotels_tour/',
      crudHotel: environment.apiUrl + 'hotel/',
      uploadHotelImage: environment.apiUrl + 'upload_hotel_image/',
    };
  }

  // Hotel

  /* gets all hotels */
  getAllHotels(trash?: boolean) {
    let params = new HttpParams();
    if (trash) {
      params = params.set('trashed', 'ok');
    }
    return this.http.get<any>(this.endpoints.allHotels, {params: params});
  }

  /* Gets hotels from city */
  getHotelsCity(city_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.hotelsCity + city_id);
  }

  /* Gets hotels from tour */
  getHotelsTour(tour_id: number, user_id: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('user_id', user_id);
    return this.http.get<any>(this.endpoints.hotelsTour + tour_id, {params: params});
  }

  /* Gets hotel */
  getHotel(hotel_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudHotel + hotel_id);
  }

  /* Creates a new search in Hotel */
  createHotel(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudHotel, data);
  }

  /* Update Hotel */
  updateHotel(hotel_id: number, data): Observable<any> {
    return this.http.put<any>(this.endpoints.crudHotel + hotel_id, data);
  }

  /* Delete Hotel */
  deleteHotel(hotel_id: string, force?: string): Observable<any> {
    let params = new HttpParams().set('hotel_id', hotel_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudHotel, {params: params});
  }

  // Upload Hotel image
  uploadHotelImage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadHotelImage, data);
  }
}
