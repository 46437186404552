import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Optional} from '../../../../shared/models/optional.model';
import {takeUntil} from 'rxjs/operators';
import {HttpOptionalService} from '../../../../shared/services/http/http-optional.service';
import {Subject} from 'rxjs';
import {Company} from '../../../../shared/models/company.model';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';

export interface AddOptData {
  optional: Optional;
  req_id: number;
  city_id: number;
  company_id: number;
  name: string;
  public_notes: string;
  confirm_notes: string;
  reason: string;
}

@Component({
  selector: 'app-add-optional',
  templateUrl: './add-optional.component.html',
  styleUrls: ['./add-optional.component.scss']
})
export class AddOptionalComponent implements OnInit, OnDestroy {
  loading = false;
  cities: any[] = [];
  city_name: string;

  newOpt: Optional;
  companies: Company[];
  hasInfo = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpOptSvc: HttpOptionalService,
    private httpCitySvc: HttpCityService,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<AddOptionalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddOptData) {
    this.companies = JSON.parse(localStorage.getItem('companies'));
    this.companies = this.companies.filter(c => c.id !== 1);
  }

  ngOnInit(): void {
    this.newOpt = new Optional();
    this.cities = JSON.parse(localStorage.getItem('cities'));
    if (this.data.city_id) {
      this.city_name = this.cities.find(c => '' + c.id === '' + this.data.city_id).name;
      this.newOpt.city_id = this.data.city_id;
    }
    if (this.data.company_id) {
      this.newOpt.company_id = this.data.company_id;
      this.newOpt.companies = ',' + this.data.company_id + ',';
    } else {
      this.newOpt.company_id = 1;
      this.newOpt.companies = ',1,';
    }
    this.newOpt.company_ids = this.newOpt.companies.replace(/^,*/, '').replace(/,*$/, '').split(',').map(i => +i);
    if (this.data.public_notes) {
      this.newOpt.selling_line = this.data.public_notes.replace(/<[^>]*>/g, '');
    }
    if (this.data.confirm_notes) {
      this.newOpt.general_info = this.data.confirm_notes;
    }
    if (this.data.name) {
      this.newOpt.name = this.data.name;
    }
    this.hasInfo = !!this.newOpt.general_info || !!this.newOpt.selling_line;
  }

  submit(): void {
    if (this.loading) {
      return;
    }
    this.onSaveOptional();
  }

  onSelectionCiaChange(event, opt: Optional) {
    opt.company_ids = event.value;
    opt.companies = ',' + opt.company_ids.join(',') + ',';
  }

  onSelectCity(e) {
    this.newOpt.city_id = e.target.value;
    this.city_name = this.cities.find(c => '' + c.id === '' + this.newOpt.city_id).name;
  }

  onSaveOptional() {
    const val = HttpOptionalService.validateInputOptional(this.newOpt);
    if (val) {
      this.snackSvc.openSnackBar(val);
      return;
    }
    this.loading = true;
    const data = {
      'user_id': this.data.req_id,
      'company_id': this.newOpt.company_id,
      'companies': this.newOpt.companies,
      'city_id': this.newOpt.city_id,
      'name': this.newOpt.name,
      'general_info': this.newOpt.general_info,
      'location': this.newOpt.location,
      'map': this.newOpt.map,
      'selling_line': this.newOpt.selling_line,
      'min_pax': this.newOpt.min_pax,
      'price': this.newOpt.price,
      'cost': this.newOpt.cost,
      'driver_share': this.newOpt.driver_share,
      'duration': this.newOpt.duration,
      'supplier': this.newOpt.supplier,
      'contact': this.newOpt.contact,
      'email': this.newOpt.email,
      'phone': this.newOpt.phone,
      'website': this.newOpt.website,
      'safety_category': this.newOpt.safety_category,
      'safety_details': this.newOpt.safety_details,
    };
    // console.log(data);
    this.httpOptSvc.createOptional(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.loading = false;
          console.log(res);
          if (res.status < 400) {
            this.data.optional = res.results.optional;
            this.close('ok');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error saving activity');
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
