import {Injectable} from '@angular/core';
import {HttpUserService} from '../http/http-user.service';
import {SnackbarService} from './snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../session/authentication.service';
import {User} from '../../models/user.model';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditUserService {
  currentUser: User;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private snackSvc: SnackbarService,
    private authSvc: AuthenticationService,
    private httpUser: HttpUserService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  onUserSettsChange(txt) {
    const data = {};
    data[txt] = this.currentUser.user_settings[txt];
    this.httpUser.updateUserSettings(this.currentUser.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.currentUser.user_settings[txt] = res.results.user_settings[txt];
            this.authSvc.updateUser(this.currentUser);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }
}
