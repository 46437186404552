import {Paypal} from './paypal.model';
import {Stripe} from './stripe.model';
import {User} from './user.model';
import {Voucher} from './voucher.model';
import {Tour} from './tour.model';

export class Orders {
  id: number;
  user_id: number;
  tour_id?: number;
  tp_id?: number;
  top_id?: number;
  reference: string;
  t_name: string;
  t_idcode: string;
  t_logo: string;
  t_plan: string;
  amount: number;
  currency_code: string;
  voucher: string;
  status: string;
  notes: string;
  created_at: string;
  deleted_at: string;
  str_fee: number;
  pln_fee: number;
  paypal_id: number;
  stripe_id: number;
  user?: User;
  tour?: Tour;
  edit_mode?: boolean;
  vouch?: Voucher;
  paypal?: Paypal;
  stripe?: Stripe;

  constructor() {
    this.status = '';
    this.currency_code = 'EUR';
  }
}
