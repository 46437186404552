import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogDataMsg {
  tour_notes: string;
}

@Component({
  selector: 'app-tour-notes',
  templateUrl: './tour-notes.component.html',
  styleUrls: ['./tour-notes.component.scss']
})
export class TourNotesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TourNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataMsg) {
  }

  @HostListener('window:keydown.Alt.Enter', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    this.dialogRef.close(this.data);
  }

  ngOnInit(): void {
  }
}
