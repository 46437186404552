import {Component, Inject} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Tour} from '../../../../shared/models/tour.model';
import {Itinerary} from '../../../../shared/models/itinerary.model';

export interface DialogDataDupeItin {
  tour: Tour;
  all_tours: Tour[];
  reason: string;
}

@Component({
  selector: 'app-dupe-itin',
  templateUrl: './dupe-itin.component.html',
  styleUrls: ['./dupe-itin.component.scss']
})
export class DupeItinComponent {
  loading = false;
  private onDestroy$ = new Subject<boolean>();

  old_itin: Itinerary = null;
  new_tour: Tour = null;
  new_itin: Itinerary = null;

  constructor(
    private router: Router,
    private httpTour: HttpTourService,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<DupeItinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDupeItin) {
    this.data.all_tours = this.data.all_tours.filter(t => t.id !== this.data.tour.id);
  }

  info() {
    console.log(this.data);
  }

  submit(): void {
    if (!this.old_itin) {
      this.snackSvc.openSnackBar('Insert old itin');
      return;
    }
    if (!this.new_tour) {
      this.snackSvc.openSnackBar('Insert new tour');
      return;
    }
    if (!this.new_itin) {
      this.snackSvc.openSnackBar('Insert new itin');
      return;
    }
    this.duplicateItinerary();
  }

  oldItinSelected(event) {
    this.old_itin = event.value;
    this.new_tour = null;
    this.new_itin = null;
  }

  newItinSelected(event) {
    const tmp_itin = event.value;
    const old_length = new Date(this.old_itin.check_out).getTime() - new Date(this.old_itin.check_in).getTime();
    const new_length = new Date(tmp_itin.check_out).getTime() - new Date(tmp_itin.check_in).getTime();
    if (old_length !== new_length) {
      this.new_itin = null;
      this.snackSvc.openSnackBar('Not same #days');
    } else {
      this.new_itin = tmp_itin;
    }
  }

  tourSelected(event) {
    this.getTour(event.value.id, 2);
  }

  getTour(tour_id, user_id) {
    // Get tour
    this.httpTour.getTour(tour_id, user_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.new_tour = res.results.tour;
            this.new_tour.itinerary = this.new_tour.itinerary.filter(it => it.city.name === this.old_itin.city.name);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
        });
  }

  duplicateItinerary() {
    const data = {
      'org_itin': this.old_itin.id,
      'des_itin': this.new_itin.id,
      'des_tour': this.new_tour.id,
    };
    // console.log(data);
    this.httpTour.duplicateItinerary(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.close('save');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error duplicating itinerary');
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
