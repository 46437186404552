export class Stripe {
  id: number;
  session_id: string;
  payment_intent: string;
  status: string;
  updated_at: string;

  constructor() {
    this.id = 0;
    this.session_id = 'cs_' + Date.now();
    this.payment_intent = null;
    this.status = 'succeeded';
  }

}
