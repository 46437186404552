import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpFormService {
  private endpoints: {
    allForms: string
    crudForm: string,
    formComp: string,
    reorderForm: string,
    submitForm: string,
    reorderQuestion: string,
    allQuestions: string
    crudQuestion: string,
    formQuestions: string,
    clearQuestion: string,
    crudQuestionTypes: string,
    crudMultiquestion: string,
    addManyMultiquestion: string,
    allAnswers: string
    crudAnswer: string,
    deleteSubmission: string,
    questionAnswer: string,
    tourpaxAnswer: string,
    formTourList: string,
    formExcel: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allForms: environment.apiUrl + 'forms/',
      crudForm: environment.apiUrl + 'form/',
      formComp: environment.apiUrl + 'form_component/',
      reorderForm: environment.apiUrl + 'form_reorder/',
      submitForm: environment.apiUrl + 'submit_form/',
      allQuestions: environment.apiUrl + 'questions/',
      crudQuestion: environment.apiUrl + 'question/',
      reorderQuestion: environment.apiUrl + 'question_reorder/',
      formQuestions: environment.apiUrl + 'questions_form/',
      clearQuestion: environment.apiUrl + 'clear_question/',
      crudQuestionTypes: environment.apiUrl + 'question_types/',
      crudMultiquestion: environment.apiUrl + 'multiquestion/',
      addManyMultiquestion: environment.apiUrl + 'many_multiquestion/',
      allAnswers: environment.apiUrl + 'answers/',
      crudAnswer: environment.apiUrl + 'answer/',
      deleteSubmission: environment.apiUrl + 'answers_delete/',
      questionAnswer: environment.apiUrl + 'answers_question/',
      tourpaxAnswer: environment.apiUrl + 'answers_tourpax/',
      formTourList: environment.apiUrl + 'form_tour_list/',
      formExcel: environment.apiUrl + 'form/excel/',
    };
  }

  // FORM

  /* Gets all forms */
  getAllForms(): Observable<any> {
    return this.http.get<any>(this.endpoints.allForms);
  }

  /* Gets form */
  getForm(form_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudForm + form_id);
  }

  /* Gets form component */
  getFormComponent(comp_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.formComp + comp_id);
  }

  /* Creates a new form */
  createForm(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudForm, data);
  }

  /* Updates form */
  async updateForm(form_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudForm + form_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Reorders form */
  async reorderForm(data: any) {
    return await this.http.post<any>(this.endpoints.reorderForm, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Reorders QUestion's MQ */
  async reorderQuestion(data: any) {
    return await this.http.post<any>(this.endpoints.reorderQuestion, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }


  /* Delete form */
  async deleteForm(form_id: number) {
    const params = new HttpParams().set('form_id', form_id.toString());
    return this.http.delete<any>(this.endpoints.crudForm, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }


  // QUESTION

  /* Gets all Questions */
  getAllQuestions(): Observable<any> {
    return this.http.get<any>(this.endpoints.allQuestions);
  }

  /* Gets all QuestionTypes */
  getAllQuestionTypes(): Observable<any> {
    return this.http.get<any>(this.endpoints.crudQuestionTypes);
  }

  /* Gets questions of form */
  getQuestionsForm(form_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.formQuestions + form_id);
  }

  /* Creates a new question */
  createQuestion(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudQuestion, data);
  }

  /* Updates question */
  async updateQuestion(question_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudQuestion + question_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Delete question */
  async deleteQuestion(question_id: number) {
    const params = new HttpParams().set('question_id', question_id.toString());
    return this.http.delete<any>(this.endpoints.crudQuestion, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Clear question */
  async clearQuestion(question_id: number) {
    return this.http.get<any>(this.endpoints.clearQuestion + question_id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Creates a new multiquestion */
  createMultiquestion(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudMultiquestion, data);
  }

  /* Creates many multiquestion */
  async addManyMultiples(data) {
    return this.http.post<any>(this.endpoints.addManyMultiquestion, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Updates multiquestion */
  async updateMultiquestion(multiquestion_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudMultiquestion + multiquestion_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Delete multiquestion */
  async deleteMultiquestion(multiquestion_id: number) {
    const params = new HttpParams().set('multiquestion_id', multiquestion_id.toString());
    return this.http.delete<any>(this.endpoints.crudMultiquestion, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }


  // ANSWERS

  /* Gets all Answers */
  getAllAnswers(): Observable<any> {
    return this.http.get<any>(this.endpoints.allAnswers);
  }

  /* Gets answers from question */
  getAnswersQuestion(question_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.questionAnswer + question_id);
  }

  /* Gets answers from tourpax */
  getAnswersTourpax(tp_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.tourpaxAnswer + tp_id);
  }

  /* Creates a new answer */
  createAnswer(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudAnswer, data);
  }

  /* Updates answer */
  async updateAnswer(answer_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudAnswer + answer_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Delete answer */
  async deleteAnswer(answer_id: number) {
    const params = new HttpParams().set('answer_id', answer_id.toString());
    return this.http.delete<any>(this.endpoints.crudAnswer, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Delete submission */
  async deleteSubmission(data) {
    return this.http.post<any>(this.endpoints.deleteSubmission, data).toPromise();
  }

  // SUBMIT ANSWER
  submitForm(data): Observable<any> {
    return this.http.post<any>(this.endpoints.submitForm, data);
  }

  // Other

  async createAttList(form_id: number) {
    return this.http.get<any>(this.endpoints.formTourList + form_id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  createExcel(form_id: number): Observable<any> {
    return this.http.get(this.endpoints.formExcel + form_id);
  }

}
