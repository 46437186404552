import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRoomService {

  private endpoints: {
    allRooms: string,
    crudRoom: string,
    roomItin: string,
    roomTour: string,
    roomPAXs: string,
    allBeds: string,
    crudBed: string,
    bedItin: string,
    replicateRooms: string,
    randomRoomies: string,
    roomTogether: string,
    assignPax: string,
    deleteChoices: string,
    createRoomsItin: string,
    createRoomiesPreference: string,
    roomingPdf: string,
    roomingExcel: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allRooms: environment.apiUrl + 'rooms/',
      crudRoom: environment.apiUrl + 'room/',
      roomItin: environment.apiUrl + 'room_itin/',
      roomTour: environment.apiUrl + 'room_tour/',
      roomPAXs: environment.apiUrl + 'room_paxs/',
      allBeds: environment.apiUrl + 'beds/',
      crudBed: environment.apiUrl + 'bed/',
      bedItin: environment.apiUrl + 'bed_itin/',
      createRoomsItin: environment.apiUrl + 'create_rooms_itin/',
      createRoomiesPreference: environment.apiUrl + 'app/tour_pax_room/',
      replicateRooms: environment.apiUrl + 'duplicate_itin/',
      randomRoomies: environment.apiUrl + 'random_roomies/',
      roomTogether: environment.apiUrl + 'room_together/',
      assignPax: environment.apiUrl + 'assign_tour_pax/',
      deleteChoices: environment.apiUrl + 'delete_choices/',
      roomingPdf: environment.apiUrl + 'rooming/pdf/',
      roomingExcel: environment.apiUrl + 'rooming/excel/',
    };
  }

  // ROOMS

  /* gets all rooms */
  getAllRooms() {
    return this.http.get<any>(this.endpoints.allRooms);
  }

  /* Gets one room */
  getRoom(room_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudRoom + room_id);
  }

  /* Gets rooms from tour */
  getRoomTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.roomTour + tour_id);
  }

  /* Gets rooms from hotel (itinerary) */
  getRoomItinerary(itinerary_id: number, user_id: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('user_id', user_id);
    return this.http.get<any>(this.endpoints.roomItin + itinerary_id, {params: params});
  }

  /* Creates a new search in Room */
  createRoom(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudRoom, data);
  }

  /* Update Room */
  updateRoom(room_id: number, data): Observable<any> {
    return this.http.put<any>(this.endpoints.crudRoom + room_id, data);
  }

  /* Rooms several pax */
  updateRoomPAXs(data): Observable<any> {
    return this.http.post<any>(this.endpoints.roomPAXs, data);
  }

  /* Deletes a voucher */
  deleteRoom(room_id: string): Observable<any> {
    return this.http.delete<any>(this.endpoints.crudRoom + room_id);
  }

  /* Get bed */
  getBed(bed_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudBed + bed_id);
  }

  /* Update bed */
  updateBed(bed_id: number, data): Observable<any> {
    return this.http.put<any>(this.endpoints.crudBed + bed_id, data);
  }

  /* Download Rooming PDF */
  downloadRoomingPdf(comp_id: number, view: boolean = false): Observable<Blob> {
    let params = new HttpParams();
    if (view) {
      params = params.set('html', 'true');
    }
    return this.http.get(this.endpoints.roomingPdf + comp_id, {responseType: 'blob', params: params});
  }

  /* Download Rooming Excel */
  downloadRoomingExcel(comp_id: number): Observable<any> {
    return this.http.get(this.endpoints.roomingExcel + comp_id);
  }

  // Create rooms
  createRoomsItin(data): Observable<any> {
    return this.http.post<any>(this.endpoints.createRoomsItin, data);
  }

  // Create rooming preference
  createRooming(data): Observable<any> {
    return this.http.post<any>(this.endpoints.createRoomiesPreference, data);
  }

  // Generate randomize room preferences
  randomRoomies(tour_id): Observable<any> {
    return this.http.get<any>(this.endpoints.randomRoomies + tour_id);
  }

  // Save room type and generate mutual preferences
  roomTogether(data): Observable<any> {
    return this.http.post<any>(this.endpoints.roomTogether, data);
  }

  // Assign PAX into rooms
  assignPAX(data): Observable<any> {
    return this.http.post<any>(this.endpoints.assignPax, data);
  }

  // replicate rooms to other hotel
  replicateRooms(data): Observable<any> {
    return this.http.post<any>(this.endpoints.replicateRooms, data);
  }

  // Remove all room preferences
  deletePreferences(tour_id): Observable<any> {
    return this.http.get<any>(this.endpoints.deleteChoices + tour_id);
  }
}
