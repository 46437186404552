import {Component, Inject} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogDataFilterOrder {
  selectedFilter: string;
  selectedTimeUnit: string;
  lastXNumber: number;
  firstDate: string;
  secondDate: string;
}

@Component({
  selector: 'app-filter-orders',
  templateUrl: './filter-orders.component.html',
  styleUrls: ['./filter-orders.component.scss']
})
export class FilterOrdersComponent {
  constructor(
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<FilterOrdersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataFilterOrder) {
  }

  onCancelClick(): void {
    this.data.selectedFilter = 'close';
    this.dialogRef.close(this.data);
  }

  onApplyClick(): void {
    if (this.data.lastXNumber === null || this.data.lastXNumber === undefined) {
      this.snackSvc.openSnackBar('Add value');
      return;
    }
    if (this.data.selectedFilter === 'lastX') {
      let fromDate: Date;
      if (this.data.selectedTimeUnit === 'days') {
        const today = new Date();
        fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - this.data.lastXNumber);
      } else if (this.data.selectedTimeUnit === 'months') {
        const today = new Date();
        fromDate = new Date(today.getFullYear(), today.getMonth() - this.data.lastXNumber, today.getDate());
      }
      this.data.firstDate = fromDate.toISOString();
    } else if (this.data.selectedFilter === 'afterDate') {
      if (!this.data.firstDate) {
        this.snackSvc.openSnackBar('Add date');
        return;
      }
    } else if (this.data.selectedFilter === 'betweenDate') {
      if (!this.data.firstDate) {
        this.snackSvc.openSnackBar('Add first date');
        return;
      }
      if (!this.data.secondDate) {
        this.snackSvc.openSnackBar('Add second date');
        return;
      }
      if (this.data.firstDate > this.data.secondDate) {
        this.snackSvc.openSnackBar('Error order dates');
        return;
      }
    } else if (this.data.selectedFilter === 'beforeDate') {
      if (!this.data.firstDate) {
        this.snackSvc.openSnackBar('Add date');
        return;
      }
    }
    this.dialogRef.close(this.data);
  }
}
