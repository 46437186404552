import {Component, Inject} from '@angular/core';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';
import {Tour} from '../../../../shared/models/tour.model';
import {Day} from '../../../../shared/models/day.model';
import {HttpItineraryService} from '../../../../shared/services/http/http-itinerary.service';
import {HttpComponentService} from '../../../../shared/services/http/http-component.service';
import {Components} from '../../../../shared/models/components.model';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {User} from '../../../../shared/models/user.model';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';

@Component({
  selector: 'app-copy-component',
  templateUrl: './copy-component.component.html',
  styleUrls: ['./copy-component.component.scss']
})
export class CopyComponentComponent {
  toursUser: Tour[];
  toursUserFiltered: Tour[];
  tourDays: Day[];
  tour_id_dest: number;
  day_id_dest: number;

  loadingTours = false;
  loadingDays = false;
  loading = false;
  profileForm = new UntypedFormGroup({
    tour_id_dest: new UntypedFormControl(''),
    day_id_dest: new UntypedFormControl(''),
    hide_old: new UntypedFormControl(true),
    itin_id_dest: new UntypedFormControl(''),
    start_date: new UntypedFormControl(''),
    component: new UntypedFormControl(''),
    events: new UntypedFormControl(''),
    reason: new UntypedFormControl(''),
  });
  private onDestroy$ = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<CopyComponentComponent>,
              private snackSvc: SnackbarService,
              private httpItin: HttpItineraryService,
              private httpComp: HttpComponentService,
              private httpTour: HttpTourService,
              private activeTourSvc: ActivetourService,
              private textTransform: TextTransformService,
              @Inject(MAT_DIALOG_DATA) public data: { component: Components, user: User }) {
    this.getTours();
  }

  tourSelected(event) {
    this.tourDays = null;
    this.loadingDays = true;
    this.tour_id_dest = event.value;
    this.httpItin.getDaysTour(this.tour_id_dest)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.tourDays = res.results.days;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingDays = false;
        },
        error => {
          console.log(error);
          this.loadingDays = false;
          this.snackSvc.openSnackBar('Error getting tour days');
        });
  }

  daySelected(event) {
    this.day_id_dest = event.value;
  }

  remove(txt) {
    if (this.loading) {
      return;
    }
    if (txt === 'tour') {
      this.profileForm.controls.tour_id_dest.setValue(null);
      this.tour_id_dest = null;
    } else if (txt === 'day') {
      this.profileForm.controls.day_id_dest.setValue(null);
      this.day_id_dest = null;
    }
  }

  getTours() {
    this.loadingTours = true;
    this.httpTour.getToursUser(this.data.user.id, this.data.user.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            localStorage.removeItem('folder_id');
            this.toursUser = res.results.tours;
            this.toursUserFiltered = res.results.tours;
            this.filterOld();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingTours = false;
        },
        error => {
          this.loadingTours = false;
          this.snackSvc.openSnackBar(error);
          console.log(error);
        });
  }

  filterOld() {
    const today = this.textTransform.addDays(new Date(), 0);
    this.toursUserFiltered = this.toursUser.filter(d =>
      today >= this.textTransform.addDays(new Date(d.tour_starts), 0) && today <= this.textTransform.addDays(new Date(d.tour_starts), d.ndays)
      || today < this.textTransform.addDays(new Date(d.tour_starts), 0));
  }

  onChBox(event) {
    this.remove('tour');
    if (event.checked) {
      this.filterOld();
    } else {
      this.toursUserFiltered = this.toursUser;
    }
  }

  submit(): void {
    if (this.profileForm.controls.tour_id_dest.invalid) {
      this.snackSvc.openSnackBar('Invalid tour');
      return;
    }
    if (this.profileForm.controls.day_id_dest.invalid) {
      this.snackSvc.openSnackBar('Invalid day');
      return;
    }
    if (!this.data.component.events || this.data.component.events?.length < 0) {
      this.snackSvc.openSnackBar('Invalid component');
      return;
    }
    this.loading = true;
    const didx = this.tourDays.findIndex(td => td.id === this.day_id_dest);
    this.profileForm.value.itin_id_dest = this.tourDays[didx].itinerary_id;
    this.profileForm.value.start_date = this.tourDays[didx].date;
    const tour_idx = this.toursUser.findIndex(t => t.id === this.profileForm.value.tour_id_dest);
    const it_idx = this.toursUser[tour_idx].itinerary.findIndex(it => it.id === this.profileForm.value.itin_id_dest);
    let npax = '';
    npax = this.data.component.npax.includes(' + ') ? this.toursUser[tour_idx].npax + ' + ' + this.data.component.npax.split(' + ')[1] :
      this.toursUser[tour_idx].npax + ' + 1';
    const data = {
      'comp_type_id': this.data.component.comp_type_id,
      'name': this.data.component.name,
      'start_date': this.profileForm.value.start_date,
      'end_date': this.profileForm.value.start_date,
      'city_id': this.toursUser[tour_idx].itinerary[it_idx].city_id,
      'npax': npax,
      'from': this.data.component.from,
      'to': this.data.component.to,
      'image': this.data.component.image,
      'supplier': this.data.component.supplier,
      'requests': this.data.component.requests,
      'recipients': this.data.component.recipients,
      'subject': null,
      'day_id': this.day_id_dest,
      'start_time': this.data.component.events[0].start_time,
      'end_time': this.data.component.events[0].end_time,
      'meet_place': this.data.component.events[0].meet_place,
      'confirm_notes': this.data.component.confirm_notes,
      'public_notes': this.data.component.events[0].public_notes,
      'private_notes': this.data.component.events[0].private_notes,
      'url': this.data.component.events[0].url,
      'visible': false,
      'duplicate': true,
      'email': this.toursUser[tour_idx].user.email,
      'prodid': this.toursUser[tour_idx].prodid,
      'itinerary_id': this.profileForm.value.itin_id_dest,
      'description': this.data.component.description,
      'show_times': this.data.component.events[0].show_times,
      'mailable': this.data.component.mailable,
    };
    const c = this.data.component.comp_type_id;
    // from new hotel
    if (c === 2 || c === 3 || c === 4 || c === 17) {
      data['from'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
    }
    // to new hotel
    if (c === 3 || c === 16) {
      data['to'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
    }
    // Arrivals/departures
    if (c === 11 || c === 16 || c === 17) {
      if (this.data.component.to?.includes('airport')) {
        data['from'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
        data['to'] = this.toursUser[tour_idx].itinerary[it_idx].city.name + ' airport';
      } else {
        data['from'] = this.toursUser[tour_idx].itinerary[it_idx].city.name + ' airport';
        data['to'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
      }
    }
    if (c === 12) {
      if (this.data.component.from?.includes(' airport')) {
        data['to'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
        data['from'] = this.toursUser[tour_idx].itinerary[it_idx].city.name + ' airport';
      } else if (this.data.component.from?.includes(' station')) {
        data['to'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
        data['from'] = 'Train station';
      } else if (this.data.component.from?.includes(' port')) {
        data['to'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
        data['from'] = this.toursUser[tour_idx].itinerary[it_idx].city.name + ' port';
      }
      if (this.data.component.to?.includes(' airport')) {
        data['from'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
        data['to'] = this.toursUser[tour_idx].itinerary[it_idx].city.name + ' airport';
      } else if (this.data.component.to?.includes(' station')) {
        data['from'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
        data['to'] = 'Train station';
      } else if (this.data.component.to?.includes(' port')) {
        data['from'] = this.toursUser[tour_idx].itinerary[it_idx].hotel.name;
        data['to'] = this.toursUser[tour_idx].itinerary[it_idx].city.name + ' port';
      }
    }
    if (this.data.component.form) {
      data['form_id'] = this.data.component.form.id;
    }
    // console.log(data);
    this.httpComp.createComponentEvent(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.profileForm.value.component = res.results.component;
            this.profileForm.value.events = res.results.events;
            this.close('save');
          } else {
            console.log('Error: component not duplicated');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Duplicating component');
        });
  }

  close(reason) {
    this.profileForm.value.reason = reason;
    this.dialogRef.close(this.profileForm.value);
  }
}
