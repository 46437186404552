import {Component, Inject} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpRoomService} from '../../../../shared/services/http/http-room.service';

export interface DialogDataReplicate {
  itin_from: number;
  comp_hotels: any; // comp_hotels: { 'id': number, 'supplier': string, 'city_name': string, 'itin_id': number }[];
  comp_id: number;
  reason: string;
}

@Component({
  selector: 'app-replicate-rooms',
  templateUrl: './replicate-rooms.component.html',
  styleUrls: ['./replicate-rooms.component.scss']
})
export class ReplicateRoomsComponent {
  loading = false;
  itin_to: number;
  snackbarRef: any = null;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private httpRoom: HttpRoomService,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<ReplicateRoomsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataReplicate) {
    data.comp_hotels = data.comp_hotels.filter(c => c.itin_id !== data.itin_from);
  }

  submit(go?): void {
    if (!this.itin_to) {
      this.snackSvc.openSnackBar('Please insert hotel to import');
      return;
    }
    if (go) {
      this.data.comp_id = this.data.comp_hotels.find(ch => ch.itin_id === this.itin_to).id;
    }
    this.onReplicateRooms();
  }

  onReplicateRooms() {
    this.loading = true;
    const data = {
      'itin_from': this.data.itin_from,
      'itin_to': this.itin_to,
    };
    // console.log(data);
    this.httpRoom.replicateRooms(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.close('ok');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error replicating rooms');
        });
  }

  close(reason) {
    if (reason === 'close' && this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
