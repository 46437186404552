import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value: string, separator: string): string {
    const splits = value.split(separator);
    if (splits.length >= 0) {
      return splits[0];
    } else {
      return '';
    }
  }
}
