import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Components} from '../../../../shared/models/components.model';
import {Subject} from 'rxjs';
import {HttpFormService} from '../../../../shared/services/http/http-form.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {QuestionType} from '../../../../shared/models/question-type.model';
import {Multiquestion} from '../../../../shared/models/multiquestion.model';
import {Question} from '../../../../shared/models/question.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatMenuTrigger} from '@angular/material/menu';
import {Answer} from '../../../../shared/models/answer.model';
import {environment} from '../../../../../environments/environment';
import {NewAnswerComponent} from './new-answer/new-answer.component';
import {MatDialog} from '@angular/material/dialog';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ExcelService} from '../../../../shared/services/common/excel.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, OnChanges {
  questTypes: QuestionType[];

  loading: boolean;
  show_ans: boolean;
  show_ids = false;
  ok_copied = false;
  to_delete: number;
  url: string;

  idxEditQ: number[] = [];
  idxEditMQ: number[] = [];

  @ViewChild('trg1') trg1: MatMenuTrigger;
  @ViewChild('trg2') trg2: MatMenuTrigger;
  @ViewChild('trg3') trg3: MatMenuTrigger;

  @Input() public component: Components;
  @Input() public tour_pax: TourPax[];
  @Input() public loading_comp: boolean;
  @Input() public show_form: boolean;
  @Output() updateForm = new EventEmitter();
  @Output() updateAnswer = new EventEmitter();

  private onDestroy$ = new Subject<boolean>();

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.idxEditQ = [];
      this.checkEmptyQuests();
    }
  }

  constructor(
    private httpForm: HttpFormService,
    public dialog: MatDialog,
    private excelService: ExcelService,
    private breakpointObserver: BreakpointObserver,
    private snackSvc: SnackbarService,
  ) {
  }

  ngOnInit(): void {
    this.questTypes = JSON.parse(localStorage.getItem('questTypes'));
    this.show_ans = false;
    const prod = btoa('' + this.component?.itinerary?.tour?.id).replace(/=/g, '');
    this.url = environment.appUrl + 'form/' + prod + '/' + this.component?.events[0]?.id;
  }

  ngOnChanges(changes: any) {
    if (changes.loading_comp?.previousValue) {
      this.show_form = false;
      this.show_ans = false;
    }
    if (changes.component) {
      if (this.component.form) {
        this.checkEmptyQuests();
        this.processAnswers();
      }
    }
  }

  checkEmptyQuests() {
    this.component.form.questions?.forEach(qt => {
      qt.loading = false;
      for (const m of qt.multiquestions) {
        if (!m.option) {
          this.idxEditMQ.push(m.id);
        }
      }
      for (const m of qt.multiquestions) {
        if (m.option === 'Other...') {
          qt.multiquestions.push(qt.multiquestions.splice(qt.multiquestions.indexOf(m), 1)[0]);
        }
      }
    });
  }

  processAnswers() {
    this.component.form.questions?.forEach(qt => {
      for (const mq of qt.multiquestions) {
        mq.total_ans = 0;
      }
      qt.answers?.forEach(an => {
        if (this.isMulti(qt)) {
          if (an.mq_ids) {
            const tmp = an.mq_ids.split(',');
            for (const mq_id of tmp) {
              if (mq_id && mq_id !== 'deleted') {
                const idx = qt.multiquestions.findIndex(mq => '' + mq.id === '' + mq_id);
                qt.multiquestions[idx].total_ans++;
              }
            }
          }
        }
      });
    });
  }

  // FORM

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.component.form.questions, event.previousIndex, event.currentIndex);
    if (event.previousIndex !== event.currentIndex) {
      const selectedIds = this.component.form.questions.map(({id}) => id);
      let ordered = '';
      for (let j = 1; j <= selectedIds.length; j++) {
        ordered = ordered + j + ':' + selectedIds[j - 1] + ',';
      }
      ordered = ordered.replace(/,\s*$/, '');
      this.onReorderForm(ordered);
    }
  }

  drop2(q, event: CdkDragDrop<any>) {
    q.multiquestions[event.previousContainer.data.index] = event.container.data.item;
    q.multiquestions[event.container.data.index] = event.previousContainer.data.item;
    const selectedIds = q.multiquestions.map(({id}) => id);
    let ordered = '';
    for (let j = 1; j <= selectedIds.length; j++) {
      ordered = ordered + j + ':' + selectedIds[j - 1] + ',';
    }
    ordered = ordered.replace(/,\s*$/, '');
    this.onReorderMQs(q, ordered);
  }

  async onReorderForm(ordered) {
    try {
      const data = {
        'form_id': this.component.form.id,
        'ordered': ordered
      };
      // console.log(data);
      const res = await this.httpForm.reorderForm(data);
      console.log(res);
      if (res.status < 400) {
        this.component.form = res.results.form;
        this.updateForm.emit(res.results.form);
      } else {
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      console.log('*err: ' + error);
    }
  }

  async onReorderMQs(q, ordered) {
    try {
      const data = {
        'question_id': q.id,
      };
      if (ordered) {
        data['ordered'] = ordered;
      }
      // console.log(data);
      const res = await this.httpForm.reorderQuestion(data);
      console.log(res);
      if (res.status < 400) {
        const qidx = this.component.form.questions.findIndex(qt => qt.id === q.id);
        const old = this.component.form.questions[qidx].show_body;
        this.component.form.questions[qidx] = res.results.question;
        this.component.form.questions[qidx].show_body = old;
      } else {
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      console.log('*err: ' + error);
    }
  }

  async onUpdateForm(what) {
    // this.trg1.closeMenu();
    if (this.loading) {
      return;
    }
    try {
      const data = {
        'component_id': this.component.id,
      };
      if (!what) {
        data['visible'] = this.component.form.visible;
        data['multiple'] = this.component.form.multiple;
        data['block'] = this.component.form.block;
      } else if (what === 'visible') {
        this.component.form.visible = !this.component.form.visible;
        data['visible'] = this.component.form.visible;
      } else if (what === 'multiple') {
        this.component.form.multiple = !this.component.form.multiple;
        data['multiple'] = this.component.form.multiple;
      } else if (what === 'block') {
        this.component.form.block = !this.component.form.block;
        data['block'] = this.component.form.block;
      }
      // console.log(data);
      const res = await this.httpForm.updateForm(this.component.form.id, data);
      console.log(res);
      if (res.status < 400) {
        // this.updateForm.emit(res.results.form);
      } else {
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      console.log('*err: ' + error);
    }
  }

  async onDeleteForm() {
    this.trg1.closeMenu();
    if (this.loading) {
      return;
    }
    const snackbarRef = this.snackSvc.openSnackBar('Delete form?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async reason => {
        if (reason.dismissedByAction) {
          try {
            const res = await this.httpForm.deleteForm(this.component.form.id);
            console.log(res);
            if (res.status < 400) {
              this.updateForm.emit(null);
            } else {
              console.log('*err: ' + this.component.form.id);
            }
          } catch (error) {
            console.log('*err: ' + error);
          }
        }
      });
  }

  // QUESTION

  onAddQuestion() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const data = {
      'form_id': this.component.form.id,
      'question_type_id': 1,
      'post_answer': null,
    };
    // console.log(data);
    this.httpForm.createQuestion(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.component.form.questions.push(res.results.question);
            this.idxEditQ.push(res.results.question.id);
            // this.updateForm.emit(this.component.form);
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  onCloseQuestion(q) {
    const idx = this.idxEditQ.findIndex(qt => qt === q.id);
    if (idx >= 0) {
      this.idxEditQ.splice(idx, 1);
    }
  }

  async onUpdateQuestion(q: Question, what, e?) {
    if (this.loading) {
      return;
    }
    const old = q;
    try {
      const data = {
        'question_id': q.id,
      };
      if (!what) {
        data['title'] = q.title;
        data['post_answer'] = q.post_answer;
        data['image'] = q.image;
      } else if (what === 'title' || what === 'desc') {
        data['title'] = q.title;
        data['description'] = q.description;
        q.edit_desc = false;
      } else if (what === 'type' && e) {
        data['question_type_id'] = e.id;
        q.question_type_id = e.id;
        q.question_type = this.questTypes.find(qt => qt.id === e.id);
      } else if (what === 'post_answer') {
        data['post_answer'] = e;
        q.post_answer = e;
      } else if (what === 'image') {
        data['image'] = q.image;
      } else if (what === 'req') {
        data['required'] = q.required;
      } else if (what === 'visible') {
        q.visible = !q.visible;
        data['visible'] = q.visible;
      }
      const idx = this.idxEditQ.findIndex(i => +i === +q.id);
      if (idx >= 0) {
        this.idxEditQ.splice(idx, 1);
      }
      // console.log(data);
      const res = await this.httpForm.updateQuestion(q.id, data);
      console.log(res);
      if (res.status < 400) {
        if (what !== 'title' && what !== 'desc') {
          const new_q = res.results.question;
          new_q.show_body = q.show_body;
        }
      } else {
        q = old;
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      q = old;
      console.log('*err: ' + error);
    }
  }

  onClearQuestion(question: Question) {
    const snackbarRef = this.snackSvc.openSnackBar('Clear all options?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async reason => {
        if (reason.dismissedByAction) {
          setTimeout(() => {
            question.multiquestions = [];
          }, 1);
          try {
            const res = await this.httpForm.clearQuestion(question.id);
            console.log(res);
            if (res.status < 400) {
              question = res.results.question;
              const idx2 = this.component.form.questions.findIndex(qs => qs.id === question.id);
              this.component.form.questions[idx2] = question;
              // this.updateForm.emit(this.component.form);
              this.checkEmptyQuests();
            } else {
              console.log('*err: ' + this.component.form.id);
            }
          } catch (error) {
            console.log('*err: ' + error);
          }
        }
      });
  }

  async onDeleteQuestion(q) {
    if (this.loading) {
      return;
    }
    const snackbarRef = this.snackSvc.openSnackBar('Delete question?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async reason => {
        if (reason.dismissedByAction) {
          try {
            const res = await this.httpForm.deleteQuestion(q.id);
            console.log(res);
            if (res.status < 400) {
              const idx = this.component.form.questions.findIndex(i => i.id === q.id);
              if (idx >= 0) {
                this.component.form.questions.splice(idx, 1);
              }
              // this.updateForm.emit(this.component.form);
              this.checkEmptyQuests();
            } else {
              console.log('*err: ' + this.component.form.id);
            }
          } catch (error) {
            console.log('*err: ' + error);
          }
        }
      });
  }

  // MULTI QUESTION

  onAddMultiquestion(question: any, other?: boolean) {
    // IF theres already one question without option, dont do anything
    if (question.multiquestions.some(mqt => !mqt.option || mqt.option === 'Other...')) {
      return;
    }
    question.loading = true;
    const data = {
      'question_id': question.id,
    };
    if (other) {
      data['option'] = 'Other...';
    }
    // console.log(data);
    this.httpForm.createMultiquestion(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            question.loading = false;
            delete res.results.option.question;
            question.multiquestions.push(res.results.option);
            const idx2 = this.component.form.questions.findIndex(qs => qs.id === question.id);
            this.component.form.questions[idx2] = question;
            if (!other) {
              this.checkEmptyQuests();
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          question.loading = false;
          console.log(error);
        });
  }

  async onUpdateMQuest(mq: Multiquestion, txt?) {
    mq.option = mq.option.replace(/^\s+|\s+$/g, '');
    const inputs = mq.option?.split(/\r|\n/).filter(n => n);
    inputs?.sort(function (a, b) {
      return a.localeCompare(b);
    });
    const q = this.component.form.questions.find(qt => +qt.id === +mq.question_id);
    if (inputs?.length > 1) {
      // CREATE MANY QUESTIONS
      q.loading = true;
      const idx = this.idxEditMQ.findIndex(i => +i === +mq.id);
      if (idx >= 0) {
        this.idxEditMQ.splice(idx, 1);
      }
      this.onDeleteMQuestion(mq);
      const data = {
        'question_id': mq.question_id,
        'options': inputs.join(','),
      };
      // console.log(data);
      const res = await this.httpForm.addManyMultiples(data);
      console.log(res);
      if (res.status < 400) {
        const Qidx = this.component.form.questions.findIndex(qt => +qt.id === +mq.question_id);
        this.component.form.questions[Qidx] = res.results.question;
        this.component.form.questions[Qidx].show_body = true;
        this.checkEmptyQuests();
        q.loading = false;
      } else {
        q.loading = false;
        console.log('*err: ' + this.component.form.id);
        this.idxEditMQ.push(mq.id);
      }
      // NORMAL UPDATE
    } else {
      // q.loading = true;
      const idx2 = this.idxEditMQ.findIndex(i => +i === +mq.id);
      if (idx2 >= 0 && txt !== 'del_desc') {
        this.idxEditMQ.splice(idx2, 1);
      }
      mq.edit_desc = false;
      if (txt === 'del_desc') {
        mq.description = null;
      }
      const data = {
        'option': mq.option,
        'blocked': mq.blocked,
        'hidden': mq.hidden,
        'description': mq.description,
      };
      const idx = this.component.form.questions.findIndex(qqq => qqq.id === mq.question_id);
      const no_other = this.component.form.questions[idx].multiquestions.every(mmm => mmm.option !== 'Other...');
      if (!mq.option && no_other) {
        data['option'] = 'Other...';
      }
      // console.log(data);
      const res = await this.httpForm.updateMultiquestion(mq.id, data);
      console.log(res);
      if (res.status < 400) {
        res.results.option.question.show_body = true;
        const idx0 = this.idxEditMQ.findIndex(i => +i === +mq.id);
        if (idx0 >= 0 && txt !== 'del_desc') {
          this.idxEditMQ.splice(idx0, 1);
        }
        const idx3 = this.component.form.questions.findIndex(qs => qs.id === res.results.option.question.id);
        const idx4 = this.component.form.questions[idx3].multiquestions.findIndex(mqt => mqt.id === mq.id);
        const idx5 = res.results.option.question.multiquestions.findIndex(mqt => mqt.id === mq.id);
        this.component.form.questions[idx3].multiquestions[idx4] = {
          ...this.component.form.questions[idx3].multiquestions[idx4],
          ...res.results.option.question.multiquestions[idx5]
        };
        this.checkEmptyQuests();
        q.loading = false;
      } else {
        this.idxEditMQ.push(mq.id);
        q.loading = false;
        console.log('*err: ' + this.component.form.id);
      }
    }
  }

  onEditMQ(mq) {
    if (!this.idxEditMQ.includes(mq.id)) {
      this.idxEditMQ.push(mq.id);
    }
  }

  async onDeleteMQuestion(mq) {
    const q = this.component.form.questions.find(qt => +qt.id === +mq.question_id);
    try {
      const idx0 = q.multiquestions.findIndex(mqt => +mqt.id === +mq.id);
      if (idx0 >= 0) {
        q.multiquestions.splice(idx0, 1);
      }
      const res = await this.httpForm.deleteMultiquestion(mq.id);
      console.log(res);
      if (res.status < 400) {
        // remove from edit
        const idx = this.idxEditMQ.findIndex(i => +i === +mq.id);
        if (idx >= 0) {
          this.idxEditMQ.splice(idx, 1);
        }
        res.results.question.show_body = true;
        this.checkEmptyQuests();
      } else {
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      console.log('*err: ' + error);
    }
  }

  // ANSWER

  onCloseAnswer(ans: Answer) {
    ans.edit = false;
  }

  async onDeleteAnswer(ans: Answer) {
    this.component.form.questions.map(q => q.show_body_ans = true);
    this.to_delete = ans.submission;
    this.trg3.closeMenu();
    const snackbarRef = this.snackSvc.openSnackBar('Delete answers?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.deleteAnswers(ans);
        } else {
          setTimeout(() => {
            this.trg3.closeMenu();
            this.to_delete = null;
          }, 1);
        }
      });
  }

  async deleteAnswers(ans: Answer) {
    try {
      this.trg3.closeMenu();
      this.to_delete = null;
      const data = {
        'answer_id': ans.id,
        'requester_id': this.component.itinerary.tour.user_id,
      };
      // console.log(data);
      const res = await this.httpForm.deleteSubmission(data);
      console.log(res);
      if (res.status < 400) {
        this.to_delete = null;
        this.component.form.questions = res.results.form.questions;
        this.component.form.questions.every(q => q.show_body_ans = true);
        // this.component.form.questions.every(q => q.answers = q.answers.filter(a => a.submission !== ans.submission));
        this.processAnswers();
      } else {
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      console.log('*err: ' + error);
    }
  }

  async onUpdateAnswer(ans: Answer) {
    try {
      ans.response = ans.response.replace(/^\s+|\s+$/g, '');
      const data = {
        'response': ans.response,
      };
      // console.log(data);
      const res = await this.httpForm.updateAnswer(ans.id, data);
      console.log(res);
      if (res.status < 400) {
        const idx = this.component.form.questions.find(qt => +qt.id === ans.question_id).answers.findIndex(a => +a.id === +ans.id);
        if (idx >= 0) {
          this.component.form.questions.find(qt => +qt.id === ans.question_id).answers[idx] = res.results.answer;
        }
        // ans = res.results.answer;
        ans.edit = false;
      } else {
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      console.log('*err: ' + error);
    }
  }

  // OTHERS

  isMulti(q) {
    return q.question_type.type === 2 || q.question_type.type === 3;
  }

  isInfo(q) {
    return q.question_type.type === 10;
  }

  showBody(q) {
    if (this.isMulti(q)) {
      q.show_body = !q.show_body;
    }
  }

  copyURL() {
    this.ok_copied = true;
    setTimeout(() => {
      this.ok_copied = false;
    }, 2000);
  }

  private getDialogWidths() {
    if (this.breakpointObserver.isMatched('(max-width: 575px)')) {
      return {minWidth: '95vw', maxWidth: '95vw'}; // xs
    } else if (this.breakpointObserver.isMatched('(max-width: 767px)')) {
      return {minWidth: '80vw', maxWidth: '80vw'}; // sm
    } else if (this.breakpointObserver.isMatched('(max-width: 991px)')) {
      return {minWidth: '60vw', maxWidth: '75vw'}; // md
    } else if (this.breakpointObserver.isMatched('(max-width: 1199px)')) {
      return {minWidth: '40vw', maxWidth: '60vw'}; // lg
    } else {
      return {minWidth: '30vw', maxWidth: '50vw'}; // xl
    }
  }

  createAnswer() {
    const dialogRef = this.dialog.open(NewAnswerComponent, {
      ...this.getDialogWidths(),
      data: {'autoFocus': true, user_id: this.component.itinerary.tour.user_id, tour_pax: this.tour_pax, form: this.component.form}
    });
    dialogRef.componentInstance.onUpdate.subscribe(result => {
      // console.log(result['form']);
      if (result && result['reason'] !== 'close') {
        this.component.form = result['form'];
        this.processAnswers();
      }
    });
  }

  createExcel() {
    this.httpForm.createExcel(this.component.form.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.excelService.downloadExcel(res.results.headers, res.results.data, res.results.name);
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error downloading form excel');
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
