import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {User} from '../../../../shared/models/user.model';
import {Voucher} from '../../../../shared/models/voucher.model';
import {Billing} from '../../../../shared/models/billing.model';
import {Referral} from '../../../../shared/models/referral.model';
import {Template} from '../../../../shared/models/template.model';
import {Invoice} from '../../../../shared/models/invoice.model';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {TitleService} from '../../../../shared/services/common/title.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {HttpInvoiceService} from '../../../../shared/services/http/http-invoice.service';
import {HttpTemplateService} from '../../../../shared/services/http/http-template.service';
import {CreateInvoiceComponent} from '../../../components/admin/create-invoice/create-invoice.component';
import {environment} from '../../../../../environments/environment';
import {Contacts} from '../../../../shared/models/contacts.model';
import {OutlookService} from '../../../../shared/services/session/outlook.service';
import {ContactsListComponent} from '../../../../shared/components/contacts-list/contacts-list.component';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {FlagService} from '../../../../shared/services/common/flag.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  currentUser: User;
  user: User;
  billing: Billing;
  invoice: Invoice;
  templates: Template;
  sample: Template;
  vouchers: Voucher[] = [];
  referrals: Referral[] = [];
  referrals_left = [];
  referrals_on: Referral[] = [];
  referrals_off: Referral[] = [];
  selectedIndex = 0;
  idxShow: string[] = [];

  req_id: number;
  credit: number;

  hours: string;
  minutes: string;

  test = false;
  showBilling = false;
  changedTime = false;
  loading = false;
  loading_outlook = false;
  connecting_stripe = false;
  beta: boolean;
  show_safety_check: boolean;
  setts_loading = false;
  setts_loaded = false;
  temp_loading = false;
  countrycode: string;
  phonenumber: string;
  returnUrl: string;
  role_txt: string;
  templateParam: string;
  optionParam: string;
  ref: string;
  ret: string;
  linkURL: string = null;
  ok_copied = false;
  viewAs: number;
  viewAsName: string;

  opt_templates = ['Subject', 'General fields', 'Hotel', 'Transfer', 'Guided Tour', 'Headsets', 'Restaurant', 'Quick updates'];
  ch_temp: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpUser: HttpUserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private outlookSvc: OutlookService,
    private orderSvc: HttpOrdersService,
    private datePipe: DatePipe,
    private flagService: FlagService,
    private userService: HttpUserService,
    private snackSvc: SnackbarService,
    private templateService: HttpTemplateService,
    private httpInvoiceService: HttpInvoiceService,
    private authSvc: AuthenticationService,
  ) {
    this.titleSvc.setTitle('Profile');
    this.webTitleSvc.setTitle('Profile | Planafy');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.beta = this.currentUser.email === 'beta@planafy.com';
        this.user = this.currentUser;
        this.show_safety_check = this.user.user_settings.safety_check !== null;
        const tmp = this.user.user_settings.safety_check;
        this.hours = tmp ? tmp.split(':')[0] : '23';
        this.minutes = tmp ? tmp.split(':')[1] : '00';
        this.viewAs = this.route.snapshot.queryParams.viewAs || this.currentUser && this.currentUser.view_as || null;
      });
  }

  ngOnInit() {
    this.test = environment.stripePublishKey.includes('test');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    this.templateParam = this.route.snapshot.queryParams['temp'] || null;
    this.optionParam = this.route.snapshot.queryParams['opt'] || null;
    this.connecting_stripe = false;
    if (this.viewAs && this.currentUser.role >= User.admin) {
      this.currentUser.view_as = this.viewAs;
      this.authSvc.updateUser(this.currentUser);
    } else {
      if (this.route.snapshot.queryParams.viewAs) {
        this.snackSvc.openSnackBar('Not allowed');
      }
    }
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        'viewAs': null,
      },
      queryParamsHandling: 'merge'
    });
    this.req_id = this.viewAs && this.currentUser.view_as ? this.viewAs : this.currentUser.id;
    this.ref = this.route.snapshot.queryParams['ref'];
    this.countrycode = (this.user?.phone) ? this.user.phone.split(' ')[0] : '+XX';
    this.phonenumber = (this.user?.phone) ? this.user.phone.split(' ')[1] : '';
    if (this.ref !== undefined) {
      console.log('ref');
      this.getAccLink();
      return;
    }
    this.ret = this.route.snapshot.queryParams['ret'];
    if (this.ret !== undefined) {
      console.log('ret');
    }
    if (this.route.snapshot.routeConfig.path === 'info') {
      this.selectedIndex = 0;
      this.router.navigate(['user/info']);
      this.webTitleSvc.setTitle('User Info | Planafy');
      this.getUser();
      this.getUserSettings();
    } else if (this.route.snapshot.routeConfig.path === 'templates') {
      this.selectedIndex = 1;
      this.getUser();
      this.getTemplateUser();
      this.webTitleSvc.setTitle('User templates | Planafy');
      this.router.navigate(['user/templates']);
    } else if (this.route.snapshot.routeConfig.path === 'options') {
      this.selectedIndex = 2;
      this.router.navigate(['user/options']);
      this.webTitleSvc.setTitle('User settings | Planafy');
      this.getUser();
      this.getUserSettings();
    } else if (this.route.snapshot.routeConfig.path === 'referrals') {
      this.selectedIndex = 3;
      this.getUser();
      this.getUserSettings();
      this.router.navigate(['user/referrals']);
      this.webTitleSvc.setTitle('Referrals | Planafy');
    }
  }

  connectToStripe() {
    if (this.connecting_stripe) {
      return;
    }
    this.connecting_stripe = true;
    const data = {
      'user_id': this.req_id,
    };
    this.orderSvc.createConnectedAccount(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.user.user_settings.acct_stripe = res.results.account.id;
            this.user.user_settings.sales_enabled = res.results.account.details_submitted;
            this.authSvc.updateUser(this.currentUser);
            this.getAccLink();
          } else {
            this.connecting_stripe = false;
            this.flagService.setFlag(this.req_id, this.router.url, res.message, '');
            this.snackSvc.openSnackBar('ERROR. ' + res.message);
          }
        },
        error => {
          this.connecting_stripe = false;
          console.log(error);
          this.flagService.setFlag(this.req_id, this.router.url, 'Err connectToStripe', JSON.stringify(error));
          this.snackSvc.openSnackBar('ERROR. creating account');
        });
  }

  getAcc() {
    this.loading = true;
    const data = {};
    if (this.user.user_settings.acct_stripe) {
      data['acct_id'] = this.user.user_settings.acct_stripe;
    } else {
      data['user_id'] = this.req_id;
    }
    // console.log(data);
    this.orderSvc.getConnectedAccount(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.user.user_settings.sales_enabled = res.results.details_submitted;
            if (!this.user.user_settings.currency.includes(res.results.default_currency.toUpperCase())) {
              this.user.user_settings.currency = res.results.default_currency.toUpperCase() + ',' + this.user.user_settings.currency;
              this.onUserSettsChange('currency');
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.flagService.setFlag(this.req_id, this.router.url, 'Err getAcc', JSON.stringify(error));
          this.snackSvc.openSnackBar('ERROR. getting account');
        });
  }

  getAccLink() {
    this.connecting_stripe = true;
    this.loading = true;
    const data = {
      'acct_id': this.user.user_settings.acct_stripe,
      'refresh_url': environment.selfUrl + 'user/info',
      'return_url': environment.selfUrl + 'user/info',
    };
    this.orderSvc.getConnectedLink(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loading = false;
          if (res.status < 400) {
            window.open(res.results.account_link.url, '_self');
          } else {
            this.connecting_stripe = false;
            this.snackSvc.openSnackBar('ERROR. getting account link');
          }
        },
        error => {
          this.loading = false;
          this.connecting_stripe = false;
          console.log(error);
          this.flagService.setFlag(this.req_id, this.router.url, 'Err getAccLink', JSON.stringify(error));
          this.snackSvc.openSnackBar('ERROR. getting account link');
        });
  }

  getAccDashLink() {
    if (!this.user.user_settings.acct_stripe) {
      this.snackSvc.openSnackBar('ERROR. Create account first');
      return;
    }
    this.loading = true;
    const data = {
      'acct_id': this.user.user_settings.acct_stripe,
    };
    this.orderSvc.getConnectedDashLink(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loading = false;
          if (res.status < 400) {
            window.open(res.results.url.url, '_blank');
          } else {
            this.snackSvc.openSnackBar(res.message);
          }
        },
        error => {
          this.loading = false;
          console.log(error);
          this.flagService.setFlag(this.req_id, this.router.url, 'Err getAccDashLink', JSON.stringify(error));
          this.snackSvc.openSnackBar('ERROR. getting dashboard link');
        });
  }

  notify() {
    console.log(this.user.given_name + '&' + this.user.id);
    this.ok_copied = true;
    setTimeout(() => {
      this.ok_copied = false;
    }, 2000);
  }

  logTab(event): void {
    if (event.index === 0) {
      this.location.replaceState('/user/info/');
      this.router.navigate(['user/info']);
    } else if (event.index === 1) {
      this.location.replaceState('/user/templates/');
      this.router.navigate(['user/templates']);
    } else if (event.index === 2) {
      this.location.replaceState('/user/options/');
      this.router.navigate(['user/options']);
    } else if (event.index === 3) {
      this.location.replaceState('/user/referrals/');
      this.router.navigate(['user/referrals']);
    }
  }

  onRemoveView() {
    this.currentUser.view_as = null;
    this.authSvc.updateUser(this.currentUser);
    this.viewAs = null;
    this.viewAsName = null;
    this.req_id = this.currentUser.id;
    this.getUser();
    if (this.selectedIndex === 0) {
      this.router.navigate(['user/info']);
      this.webTitleSvc.setTitle('User Info | Planafy');
      this.getUserSettings();
    } else if (this.selectedIndex === 1) {
      this.getTemplateUser();
      this.webTitleSvc.setTitle('User templates | Planafy');
      this.router.navigate(['user/templates']);
    } else if (this.selectedIndex === 2) {
      this.router.navigate(['user/options']);
      this.webTitleSvc.setTitle('User settings | Planafy');
    } else if (this.selectedIndex === 3) {
      this.getUserSettings();
      this.router.navigate(['user/referrals']);
      this.webTitleSvc.setTitle('Referrals | Planafy');
    }
  }

  /*
  /* HTTP REQUESTS
  *******************/
  getUser() {
    this.loading = true;
    this.httpUser.getUser(this.req_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 201) {
            this.user = res.results.user;
            if (this.currentUser.view_as) {
              this.viewAsName = this.user.given_name + ': ' + this.user.id || 'View: ' + this.user.id;
            } else {
              this.currentUser = this.user;
              this.templates = this.user.template;
              this.replaceBRs2NL();
              this.user.template = this.templates;
              this.authSvc.updateUser(this.currentUser);
            }
            this.beta = this.beta && !this.viewAs;
            this.countrycode = (this.user.phone) ? this.user.phone.split(' ')[0] : '+XX';
            this.phonenumber = (this.user.phone) ? this.user.phone.split(' ')[1] : '';
            switch (this.user.role) {
              case 0:
                this.role_txt = 'Bot';
                break;
              case 1:
                this.role_txt = 'Traveler';
                break;
              case 2:
                this.role_txt = 'Traveler admin';
                break;
              case 5:
                this.role_txt = 'EF Tour Director';
                break;
              case 6:
                this.role_txt = 'UB Tour Director';
                break;
              case 10:
                this.role_txt = 'Admin';
                break;
              default:
                this.role_txt = '';
                break;
            }
            if (!this.currentUser.view_as && this.currentUser.email === 'td.a.santacruz@ef.com' && this.selectedIndex === 0) {
              this.currentUser.avatar = 'A47199BA4E22DC62E3AB5A11929F5F48';
              this.currentUser.role = 10;
              this.role_txt = 'Admin';
              this.authSvc.updateUser(this.currentUser);
              this.meAdmin();
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  getUserSettings() {
    this.setts_loaded = false;
    this.setts_loading = true;
    this.httpUser.getUserSettings(this.req_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 201) {
            this.user.user_settings = res.results.user_settings;
            // only in user/info
            if (this.selectedIndex === 0) {
              this.getAcc();
            }
            // only in user/referral
            if (this.selectedIndex === 3) {
              const invite = btoa(this.user.given_name + '&' + this.user.id);
              this.linkURL = environment.selfUrl + 'login?invite=' + invite.replace(/=/g, '');
            }
            this.vouchers = res.results.vouchers;
            this.billing = res.results.billing;
            this.referrals = res.results.referrals.sort((a, b) => a[status] < b[status] ? 1 : a[status] === b[status] ? 0 : -1);
            this.referrals_on = this.referrals.filter(r => r.status === 1);
            this.referrals_off = this.referrals.filter(r => r.status === 0);
            this.referrals_left = Array(5 - (res.results.referrals.length >= 5 ? 5 : res.results.referrals.length));
            const tmp = this.user.user_settings.safety_check;
            this.hours = tmp ? tmp.split(':')[0] : '23';
            this.minutes = tmp ? tmp.split(':')[1] : '00';
            this.show_safety_check = this.user.user_settings.safety_check !== null;
            // Check edit only in user/options
            if (this.selectedIndex === 2 && this.optionParam) {
              switch (this.optionParam) {
                case 'pt':
                  this.user.user_settings.past_tours = !this.user.user_settings.past_tours;
                  this.onUserSettsChange('past_tours');
                  break;
                case 'pt1':
                  this.user.user_settings.past_tours = true;
                  this.onUserSettsChange('past_tours');
                  break;
                case 'pt0':
                  this.user.user_settings.past_tours = false;
                  this.onUserSettsChange('past_tours');
                  break;
                case 'fi':
                  this.user.user_settings.traceable = !this.user.user_settings.traceable;
                  this.onUserSettsChange('traceable');
                  break;
                case 'fi1':
                  this.user.user_settings.traceable = true;
                  this.onUserSettsChange('traceable');
                  break;
                case 'fi0':
                  this.user.user_settings.traceable = false;
                  this.onUserSettsChange('traceable');
                  break;
                case 'fb':
                  this.user.user_settings.flag_icon = !this.user.user_settings.flag_icon;
                  this.onUserSettsChange('flag_icon');
                  break;
                case 'fb1':
                  this.user.user_settings.flag_icon = true;
                  this.onUserSettsChange('flag_icon');
                  break;
                case 'fb0':
                  this.user.user_settings.flag_icon = false;
                  this.onUserSettsChange('flag_icon');
                  break;
                case 'no':
                  this.user.user_settings.notifications = !this.user.user_settings.notifications;
                  this.onUserSettsChange('notifications');
                  break;
                case 'no1':
                  this.user.user_settings.notifications = true;
                  this.onUserSettsChange('notifications');
                  break;
                case 'no0':
                  this.user.user_settings.notifications = false;
                  this.onUserSettsChange('notifications');
                  break;
                case 'pr':
                  this.user.user_settings.newsletter = !this.user.user_settings.newsletter;
                  this.onUserSettsChange('newsletter');
                  break;
                case 'pr1':
                  this.user.user_settings.newsletter = true;
                  this.onUserSettsChange('newsletter');
                  break;
                case 'pr0':
                  this.user.user_settings.newsletter = false;
                  this.onUserSettsChange('newsletter');
                  break;
                case 'sn':
                  this.user.user_settings.show_notes = !this.user.user_settings.show_notes;
                  this.onUserSettsChange('show_notes');
                  break;
                case 'sn1':
                  this.user.user_settings.show_notes = true;
                  this.onUserSettsChange('show_notes');
                  break;
                case 'sn0':
                  this.user.user_settings.show_notes = false;
                  this.onUserSettsChange('show_notes');
                  break;
                case 'sd':
                  this.user.user_settings.send_driver = !this.user.user_settings.send_driver;
                  this.onUserSettsChange('send_driver');
                  break;
                case 'sd1':
                  this.user.user_settings.send_driver = true;
                  this.onUserSettsChange('send_driver');
                  break;
                case 'sd0':
                  this.user.user_settings.send_driver = false;
                  this.onUserSettsChange('send_driver');
                  break;
                default:
                  this.snackSvc.openSnackBar('Option unknown');
                  break;
              }
              setTimeout(() => {
                this.optionParam = null;
              }, 2500);
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            }
          }
          this.setts_loading = false;
          this.setts_loaded = true;
        },
        error => {
          this.setts_loaded = true;
          this.setts_loading = false;
          console.log(error);
        });
  }

  getTemplateUser() {
    this.temp_loading = true;
    this.templateService.getTemplateUser(this.req_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 201) {
            this.sample = res.results.sample;
            this.templates = res.results.template;
            if (this.templateParam) {
              this.ch_temp = this.templateParam;
              this.onChooseTemplate();
            }
            this.replaceBRs2NL();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.restoreTemplates();
            }
          }
          this.temp_loading = false;
        },
        error => {
          this.temp_loading = false;
          console.log(error);
        });
  }

  onChooseTemplate() {
    this.router.navigate([], {
      queryParams: {
        temp: this.ch_temp,
      },
      queryParamsHandling: 'merge',
    });
  }

  updateBilling() {
    if (!this.billing.id) {
      this.snackSvc.openSnackBar('Error. No billing to update');
      return;
    }
    this.loading = true;
    const data = {
      'user_id': this.req_id,
      'tax_name': this.billing.tax_name,
      'tax_number': this.billing.tax_number,
      'address_f1': this.billing.address_f1,
      'address_f2': this.billing.address_f2,
      'admin_f1': this.billing.admin_f1,
      'admin_f2': this.billing.admin_f2,
      'zip_code': this.billing.zip_code,
      'country': this.billing.country,
    };
    // console.log(data);
    this.httpInvoiceService.updateBilling(this.billing.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.billing = res.results.billing;
            this.snackSvc.openSnackBar('User info updated');
            this.showBilling = false;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  onShowFld(temp) {
    if (!this.idxShow.includes(temp)) {
      this.idxShow.push(temp);
    }
  }

  onHideFld(temp) {
    const removeIdx = this.idxShow.findIndex(i => '' + i === '' + temp);
    this.idxShow.splice(removeIdx, 1);
  }

  onRestoreFld(temp) {
    if (this.setts_loading) {
      return;
    }
    this.setts_loading = true;
    const snackbarRef = this.snackSvc.openSnackBar('Restore field?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.setts_loading = true;
          const data = {
            'req_id': this.req_id,
            'user_id': this.req_id,
          };
          data[temp] = '';
          this.templateService.restoreTemplate(data)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                this.setts_loading = false;
                console.log(res);
                if (res.status === 203) {
                  this.sample = res.results.sample;
                  // this.templates = res.results.template;
                  this.templates[temp] = res.results.template[temp];
                  this.replaceBRs2NL();
                  this.snackSvc.openSnackBar('Saved');
                } else {
                  this.snackSvc.resultsElse(res);
                }
              },
              error => {
                this.setts_loading = false;
                console.log(error);
              });
        } else {
          this.setts_loading = false;
        }
      });
  }

  onUserChange(txt) {
    this.setts_loading = true;
    this.phonenumber = this.phonenumber.replace(/-|\s/g, '');
    this.countrycode = this.countrycode.replace(/-|\s/g, '');
    const val = this.validateUserInput(txt);
    if (val) {
      this.snackSvc.openSnackBar(val);
      this.setts_loading = false;
      return;
    }
    let name = '';
    if (this.user.given_name) {
      name = this.user.given_name;
    }
    if (this.user.surname) {
      name = name + ' ' + this.currentUser.surname;
    }
    const data = {
      'req_id': this.req_id,
      'name': name.trim(),
      'given_name': this.user.given_name,
      'surname': this.user.surname,
      'phone': this.countrycode + ' ' + this.phonenumber,
    };
    // console.log(data);
    this.httpUser.updateUser(this.req_id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.setts_loading = false;
          if (res.status === 203) {
            if (!this.currentUser.view_as) {
              this.templates = res.results.user.template;
              this.replaceBRs2NL();
              this.user.template = this.templates;
            }
            if (txt === 'all') {
              this.router.navigate([this.returnUrl], {queryParams: {ref: true, sub: this.templateParam === 'Subject'}});
            } else {
              this.snackSvc.openSnackBar('Saved');
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating profile');
        });
  }

  onUserSettsChange(txt) {
    if (txt === 'safety_check') {
      if (this.show_safety_check) {
        this.user.user_settings.safety_check = (this.hours ? this.hours : '23') + ':' + (this.minutes ? this.minutes : '00');
      } else {
        this.user.user_settings.safety_check = null;
      }
    }
    this.setts_loading = true;
    const data = {};
    data[txt] = this.user.user_settings[txt];
    // console.log(data);
    this.httpUser.updateUserSettings(this.req_id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.setts_loading = false;
          if (res.status < 400) {
            this.user.user_settings = res.results.user_settings;
            if (!this.currentUser.view_as) {
              this.currentUser.user_settings = this.user.user_settings;
              this.authSvc.updateUser(this.currentUser);
            }
            if (txt === 'safety_check') {
              if (this.show_safety_check) {
                this.snackSvc.openSnackBar('Future safety checks updated');
              } else {
                this.snackSvc.openSnackBar('Safety check emails disabled');
              }
              this.changedTime = false;
            }
            if (this.optionParam) {
              this.snackSvc.openSnackBar('Saved');
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating settings');
        });
  }

  validateUserInput(txt) {
    if (txt === 'given_name' && !this.currentUser.given_name) {
      return 'Please insert valid name';
    }
    if (txt === 'phonenumber' && !this.phonenumber) {
      return 'Please insert a phone number';
    }
    if (txt === 'phonenumber' && this.phonenumber.substring(0, 1) === '+') {
      return 'Please insert only the number';
    }
    if (txt === 'phonenumber' && this.phonenumber.substring(0, 2) === '00') {
      return 'Please insert only the number';
    }
    if (txt === 'countrycode' && !this.countrycode) {
      return 'Please insert int\'l country code';
    }
    if (txt === 'countrycode' &&
      ((this.countrycode.length < 2) ||
        ((this.countrycode.substring(0, 1) !== '+')
          && this.countrycode.substring(0, 2) !== '00'))
    ) {
      return 'Insert valid country code (+XX or 00XX)';
    }
    if (txt === 'countrycode' && this.countrycode.substring(0, 2) === '00') {
      this.countrycode = this.countrycode.replace('00', '+');
    }
    return;
  }

  // Force get Users contact list
  async forceUserContactsList() {
    this.loading_outlook = true;
    const data = {
      session_id: localStorage.getItem('session_id'),
      user_id: this.currentUser.id
    };
    const res = await this.outlookSvc.getContacts(data);
    console.log(res);
    if (res.status < 400) {
      this.loading_outlook = false;
      this.currentUser.contacts = new Contacts(res.results);
      this.currentUser.contacts.updated_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES');
      this.currentUser.contacts.count = this.currentUser.contacts.list.length;
      this.authSvc.updateUser(this.currentUser);
      const snackbarRef = this.snackSvc.openSnackBar(this.currentUser.contacts.count + ' contacts imported', 'View');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            setTimeout(() => {
              this.dialog.open(ContactsListComponent, {
                maxHeight: '90vh',
                data: {'autoFocus': true, 'contacts': this.currentUser.contacts}
              });
            }, 1);
          } else {
            setTimeout(() => {
            }, 1);
          }
        });
    } else {
      this.loading_outlook = false;
      this.snackSvc.resultsElse(res);
    }
  }

  onChangedTime() {
    this.changedTime = true;
  }

  // TEMPLATES

  onEditSubj(txt) {
    if (this.templates.subject) {
      this.templates.subject = this.templates.subject + ' {{' + txt + '}}';
    } else {
      this.templates.subject = '{{' + txt + '}}';
    }
  }

  onChangeTemp(txt) {
    if (this.setts_loading) {
      return;
    }
    this.setts_loading = true;
    const val = this.validateTempInput();
    if (val) {
      this.snackSvc.openSnackBar(val);
      this.setts_loading = false;
      return;
    }
    const data = {
      'req_id': this.req_id,
      'user_id': this.req_id,
    };
    this.replaceNLs2BR();
    data[txt] = this.templates[txt];
    this.templateService.updateTemplate(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            this.templates[txt] = res.results.template[txt];
            this.sample = res.results.sample;
            this.snackSvc.openSnackBar('Saved');
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.replaceBRs2NL();
          this.setts_loading = false;
        },
        error => {
          this.setts_loading = false;
          console.log(error);
        });
  }

  restoreTemplates() {
    if (this.setts_loading) {
      return;
    }
    this.setts_loading = true;
    const data = {
      'req_id': this.req_id,
      'user_id': this.req_id,
      'restore': ''
    };
    this.templateService.restoreTemplate(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            this.templates = res.results.template;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.replaceBRs2NL();
          this.setts_loading = false;
          this.snackSvc.openSnackBar('Saved');
        },
        error => {
          this.setts_loading = false;
          console.log(error);
        });
  }

  validateTempInput() {
    if (!this.templates.greeting) {
      return 'Please insert Greeting';
    }
    if (!this.templates.intro) {
      return 'Please insert Intro';
    }
    if (!this.templates.hotel_room) {
      return 'Please insert Rooming';
    }
    if (!this.templates.hotel_bfast) {
      return 'Please insert Breakfast';
    }
    if (!this.templates.hotel_driver) {
      return 'Please insert Driver';
    }
    if (!this.templates.lated) {
      return 'Please insert Late adds';
    }
    if (!this.templates.caxed) {
      return 'Please insert Cancelleds';
    }
    if (!this.templates.farewell) {
      return 'Please insert Farewell';
    }
    if (!this.templates.signature) {
      return 'Please insert Signature';
    }
    return;
  }

  replaceBRs2NL() {
    const re = /{{br}}/gm;
    this.templates.greeting = this.templates.greeting.replace(re, '\n');
    this.templates.intro = this.templates.intro.replace(re, '\n');
    this.templates.hotel_room = this.templates.hotel_room.replace(re, '\n');
    this.templates.hotel_bfast = this.templates.hotel_bfast.replace(re, '\n');
    this.templates.hotel_tdnight = this.templates.hotel_tdnight.replace(re, '\n');
    this.templates.hotel_driver = this.templates.hotel_driver.replace(re, '\n');
    this.templates.rest = (this.templates.rest) ? this.templates.rest.replace(re, '\n') : null;
    this.templates.guss = (this.templates.guss) ? this.templates.guss.replace(re, '\n') : null;
    this.templates.vox = (this.templates.vox) ? this.templates.vox.replace(re, '\n') : null;
    this.templates.trsfrs = (this.templates.trsfrs) ? this.templates.trsfrs.replace(re, '\n') : null;
    this.templates.driver_info = (this.templates.driver_info) ? this.templates.driver_info.replace(re, '\n') : null;
    this.templates.lated = this.templates.lated.replace(re, '\n');
    this.templates.caxed = this.templates.caxed.replace(re, '\n');
    this.templates.farewell = this.templates.farewell.replace(re, '\n');
    this.templates.signature = this.templates.signature.replace(re, '\n');
  }

  replaceNLs2BR() {
    const re = /\n/g;
    this.templates.greeting = this.templates.greeting.replace(re, '{{br}}');
    this.templates.intro = this.templates.intro.replace(re, '{{br}}');
    this.templates.hotel_room = this.templates.hotel_room.replace(re, '{{br}}');
    this.templates.hotel_bfast = this.templates.hotel_bfast.replace(re, '{{br}}');
    this.templates.hotel_driver = this.templates.hotel_driver.replace(re, '{{br}}');
    this.templates.rest = (this.templates.rest) ? this.templates.rest.replace(re, '{{br}}') : null;
    this.templates.guss = (this.templates.guss) ? this.templates.guss.replace(re, '{{br}}') : null;
    this.templates.vox = (this.templates.vox) ? this.templates.vox.replace(re, '{{br}}') : null;
    this.templates.trsfrs = (this.templates.trsfrs) ? this.templates.trsfrs.replace(re, '{{br}}') : null;
    this.templates.driver_info = (this.templates.driver_info) ? this.templates.driver_info.replace(re, '{{br}}') : null;
    this.templates.lated = this.templates.lated.replace(re, '{{br}}');
    this.templates.caxed = this.templates.caxed.replace(re, '{{br}}');
    this.templates.farewell = this.templates.farewell.replace(re, '{{br}}');
    this.templates.signature = this.templates.signature.replace(re, '{{br}}');
  }

  // REFERRALS

  meAdmin() {
    this.userService.setMeAdmin()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
        },
        error => {
          console.log(error);
        });
  }

  onOKreturn() {
    this.onUserChange('all');
  }

  onSave() {
    // this.snackSvc.openSnackBar('Saved');
    console.log('saved');
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
