import {IncidentType} from './incident-type.model';
import {City} from './city.model';

export class Incident {
  id: number;
  user_id: number;
  city_id: number;
  incident_type_id: number;
  details: string;
  start_datetime: string;
  end_datetime: string;
  n_actives: number;
  n_inactives: number;
  openVote: boolean;
  hide_alert: boolean;
  ratio: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  incident_type: IncidentType;
  city?: City;
  user?: string;
  type?: string;
  subtype?: string;

  constructor() {
    this.details = null;
    this.start_datetime = null;
    this.end_datetime = null;
    this.n_actives = 0;
    this.n_inactives = 0;
    this.openVote = false;
    this.hide_alert = false;
    this.incident_type_id = -1;
  }
}
