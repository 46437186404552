import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../shared/services/common/title.service';
import {Title} from '@angular/platform-browser';
import {User} from '../../../../shared/models/user.model';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  // Active user
  currentUser: User;
  navLinks: any[];
  activeTabIndex = -1;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private webTitleSvc: Title,
    private titleSvc: TitleService
  ) {
    this.webTitleSvc.setTitle('Admin | Planafy');
    this.titleSvc.setTitle('Admin');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.navLinks = [
      {label: 'User', link: 'users'},
      {label: 'Tour', link: 'tours'},
      {label: 'City', link: 'cities'},
      {label: 'Hotel', link: 'hotels'},
      {label: 'Acts', link: 'activities'},
      {label: 'Order', link: 'orders'},
      {label: 'Comp', link: 'comps'},
      {label: 'StrEvts', link: 'stripevts'},
      {label: 'Flag', link: 'flags'},
      {label: 'Incident', link: 'incidents'},
      // {label: 'Locator', link: 'locators'},
    ];
  }

  ngOnInit() {
    // console.log(this.currentUser);
    if (this.currentUser.avatar !== User.avatar) {
      this.router.navigate(['tours']);
      return;
    }
    const url_link = this.route.snapshot.routeConfig.path;
    this.activeTabIndex = this.navLinks.findIndex(nl => '' + nl.link === '' + url_link);
  }

  logTab(event): void {
    this.activeTabIndex = event.index;
    this.location.replaceState('admin/' + this.navLinks[event.index].link);
    this.titleSvc.setTitle('Admin: ' + this.navLinks[event.index].label);
    this.webTitleSvc.setTitle('Admin: ' + this.navLinks[event.index].label + ' | Planafy');
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
