import {UserResponse} from '../interfaces/user.response';
import {UserSettings} from './user-settings.model';
import {Voucher} from './voucher.model';
import {Tour} from './tour.model';
import {Contacts} from './contacts.model';
import {TourPax} from './tour-pax.model';
import {Template} from './template.model';
import {Billing} from './billing.model';
import {Referral} from './referral.model';

export class User {
  public static admin = 10;
  public static ubet = 7;
  public static efub = 6;
  public static efet = 5;
  public static ef = 4;
  public static pax_admin = 2;
  public static pax = 1;
  public static hidden = 0;
  public static avatar = 'A47199BA4E22DC62E3AB5A11929F5F48';

  id: number;
  name: string;
  given_name: string;
  surname: string;
  email: string;
  outlook_id: string;
  google_id: string;
  apple_id: string;
  phone: string;
  gender: string;
  avatar: string;
  role: number;
  view_as?: number;
  user_settings: UserSettings;
  created_at: string;
  deleted_at: string;
  updated_at: string;
  referred_by?: string;
  first_time?: boolean;
  days_worked?: number;
  voucher?: Voucher[];
  tours?: Tour[];
  tour_pax?: TourPax[];
  referrals?: Referral[];
  contacts?: Contacts;
  template?: Template;
  billing?: Billing;

  constructor(userRes?: UserResponse) {
    this.id = userRes?.id;
    this.name = userRes?.name;
    this.given_name = userRes?.given_name;
    this.surname = userRes?.surname;
    this.email = userRes?.email;
    this.outlook_id = userRes?.outlook_id;
    this.google_id = userRes?.google_id;
    this.apple_id = userRes?.apple_id;
    this.avatar = userRes?.avatar;
    this.phone = userRes?.phone;
    this.gender = userRes?.gender;
    this.role = userRes?.role;
    this.user_settings = userRes?.user_settings;
    this.first_time = userRes?.first_time === 'true';
    this.created_at = userRes?.created_at;
    this.deleted_at = userRes?.deleted_at;
    this.updated_at = userRes?.updated_at;
  }
}
