import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {HttpClient} from '@angular/common/http';
import {Clipboard} from '@angular/cdk/clipboard';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {Subject} from 'rxjs';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {takeUntil} from 'rxjs/operators';
import {Activetour} from '../../../../shared/models/activetour.model';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {Router} from '@angular/router';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';

export interface DialogDataAddPaxList {
  user: User;
  tourpax: TourPax[];
}

@Component({
  selector: 'app-add-pax-list',
  templateUrl: './add-pax-list.component.html',
  styleUrls: ['./add-pax-list.component.scss']
})
export class AddPaxListComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;

  loading = false;
  reason: string;
  snackbarRef: any = null;
  selfUrl: string;

  allComplete = false;
  creating = false;
  pax_ids: number[] = [];
  loadingPDF: boolean;
  file: any;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private httpUser: HttpUserService,
    private httpTour: HttpTourService,
    private http: HttpClient,
    private flagService: FlagService,
    private textTransform: TextTransformService,
    private router: Router,
    private clipboard: Clipboard,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<AddPaxListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddPaxList) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.selfUrl = environment.selfUrl;
  }

  ngOnInit(): void {
  }

  submit(): void {
    if (!this.data.user) {
      this.snackSvc.openSnackBar('Please insert user');
      return;
    }
  }

  showComps() {

  }

  // CHAT GPT

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  prepareFilesList(file) {
    if (this.loadingPDF) {
      return;
    }
    this.file = file[0];
    if (!this.file.type.includes('pdf')) {
      // this.file = null;
      this.snackSvc.openSnackBar('File must be a pdf');
      return;
    }
    if (!this.file.name.toLowerCase().includes('rooming')) {
      // this.file = null;
      this.snackSvc.openSnackBar('Upload Rooming list');
      return;
    }
    this.uploadPAX();
  }

  uploadPAX() {
    this.loadingPDF = true;
    const formData = new FormData();
    formData.append('only_comps', '1');
    formData.append('file', this.file);
    formData.append('user_id', '' + this.currentUser.id);
    formData.append('tour_id', '' + this.activeTour.tour.id);
    this.httpTour.uploadPAX(formData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.pax_ids = [];
          this.data.tourpax = res.results.tourpax;
          this.allComplete = false;
          this.data.tourpax.map(c => {
            c.checked = false;
          });
          this.file = null;
        } else {
          if (res.results) {
            this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
          } else {
            this.snackSvc.openSnackBar(res.message.toString());
            console.log(res.message.toString());
          }
        }
        this.loadingPDF = false;
      }, error => {
        this.loadingPDF = false;
        console.log(error);
        if (error.includes('Undefined array key')) {
          this.snackSvc.openSnackBar('Error. Contact support');
        } else {
          this.snackSvc.openSnackBar('Error. Try again');
        }
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error uploadPAX()', JSON.stringify(error));
      });
  }

  clickTourPax(tourpax: TourPax) {
    if (this.creating) {
      return;
    }
    tourpax.checked = !tourpax.checked;
    const idIDx = this.pax_ids.findIndex(c => +c === +tourpax.id);
    if (tourpax.checked) {
      if (idIDx < 0) {
        this.pax_ids.push(tourpax.id);
      }
    } else {
      if (idIDx >= 0) {
        this.pax_ids.splice(idIDx, 1);
      }
    }
  }

  someComplete(): boolean {
    if (!this.data.tourpax) {
      return false;
    }
    return this.data.tourpax.filter(c => c.checked).length > 0 && !this.allComplete;
  }

  deleteTourPaxs() {
    this.data.tourpax = this.data.tourpax.filter(c => !this.pax_ids.includes(c.id));
    this.pax_ids = [];
  }

  selectAll(event) {
    this.pax_ids = [];
    this.allComplete = event.checked;
    this.data.tourpax.forEach(c => c.checked = event.checked);
    if (event.checked) {
      this.data.tourpax.map(c => this.pax_ids.push(c.id));
    }
  }

  resetTDI() {
    this.allComplete = false;
    this.data.tourpax = [];
    this.pax_ids = [];
  }

  close(reason) {
    if (reason === 'close' && this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
    this.reason = reason;
    this.dialogRef.close(reason);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
