import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogDataRooms {
  singles: number;
  doubles: number;
  twins: number;
  triples: number;
  quads: number;
  fives: number;
  sixes: number;
  sevens: number;
  eights: number;
  nines: number;
  tens: number;
  nsingles: number;
  ntwins: number;
  ndoubles: number;
  ntriples: number;
  nquads: number;
  nmales: number;
  nfemales: number;
  reason: string;
}

@Component({
  selector: 'app-edit-rooms',
  templateUrl: './edit-rooms.component.html',
  styleUrls: ['./edit-rooms.component.scss']
})
export class EditRoomsComponent {
  loading = false;
  showMore: boolean;
  totalBs = 0;

  constructor(
    public dialogRef: MatDialogRef<EditRoomsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRooms) {
    this.showMore = this.data.sevens > 0 || this.data.eights > 0 || this.data.nines > 0 || this.data.tens > 0;
  }

  updateNs() {
    this.totalBs = this.data.singles + 2 * (this.data.twins + this.data.doubles) + 3 * (this.data.triples) + 4 * (this.data.quads) +
      5 * (this.data.fives) + 6 * (this.data.sixes) + 7 * (this.data.sevens) + 8 * (this.data.eights) + 9 * (this.data.nines) + 10 * (this.data.tens);
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
