import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {TourAccess} from '../../../../shared/models/tour-access.model';
import {environment} from '../../../../../environments/environment';
import {Tour} from '../../../../shared/models/tour.model';
import {User} from '../../../../shared/models/user.model';

@Component({
  selector: 'app-grant-access',
  templateUrl: './grant-access.component.html',
  styleUrls: ['./grant-access.component.scss']
})
export class GrantAccessComponent {
  loading = false;
  email: string;
  error: string;
  private onDestroy$ = new Subject<boolean>();
  @Output() deleteTA = new EventEmitter();

  constructor(
    private router: Router,
    private httpTour: HttpTourService,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<GrantAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tour: Tour, tour_access: TourAccess[], user: User, reason: string }) {
  }

  submit(): void {
    if (this.loading) {
      return;
    }
    if (!this.email) {
      this.error = 'Insert email';
      return;
    }
    this.createTourAccess();
  }

  createTourAccess() {
    this.loading = true;
    const data = {
      'req_id': this.data.user.id,
      'tour_id': this.data.tour.id,
      'email': this.email,
      'url': environment.selfUrl,
    };
    // console.log(data);
    this.httpTour.createAccess(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const idx = this.data.tour_access.findIndex(t => +t.id === +res.results.tour_access.id);
            if (idx < 0) {
              this.data.tour_access.push(res.results.tour_access);
            }
            this.email = null;
            this.error = null;
          } else {
            if (res.results) {
              this.error = res.results[Object.keys(res.results)[0]].toString();
            } else {
              this.error = res.message.toString();
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.error = error;
          console.log(error);
          this.snackSvc.openSnackBar('Error creating access');
        });
  }

  deleteTourAccess(ta) {
    this.loading = true;
    this.httpTour.deleteAccess(ta.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.deleteTA.emit(ta.id);
            this.data.tour_access = this.data.tour_access.filter(t => t.id !== ta.id);
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error deleting access');
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
