import {City} from './city.model';
import {Itinerary} from './itinerary.model';
import {Room} from './room.model';

export class Hotel {
  id: string;
  city_id: number;
  name: string;
  address: string;
  phone: string;
  image: string;
  private: string;
  public: string;
  url: string;
  confirmed: boolean;
  deleted_at: string;
  loading_img?: boolean;
  rooms_some?: boolean;
  rooms?: any[];
  city?: City;
  itinerary?: Itinerary[];

  constructor(hotel?: any, city?: City) {
    this.name = '';
    this.address = '';
    if (hotel) {
      this.id = hotel.id;
      this.city_id = hotel.city_id;
      this.name = hotel.name;
      this.address = hotel.address;
    }
    if (city) {
      this.city = city;
    }
  }
}
