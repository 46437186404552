import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/session/authentication.service';
import {SnackbarService} from '../services/common/snackbar.service';
import {User} from '../models/user.model';

@Injectable({providedIn: 'root'})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private snackSvc: SnackbarService,
    private authSvc: AuthenticationService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authSvc.currentUserValue;
    if (currentUser.role === User.admin) {
      return true;
    }
    this.snackSvc.openSnackBar('Only for admins!');
    this.router.navigate(['tours']);
    return false;
  }
}
