export class Gsstype {
  id: number;
  type: number;
  icon: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;

  constructor(id?) {
    this.id = id ? id : 1;
  }
}
