import {Component, Inject, OnInit} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {Room} from '../../../../shared/models/room.model';
import {Rooming} from '../../../../shared/models/rooming.model';
import {Tour} from '../../../../shared/models/tour.model';
import {Router} from '@angular/router';

export interface DialogDataAssignRooms {
  tour_pax: TourPax[];
  rooms: Room[];
  preferences: Rooming[];
  tour: Tour;
  idxWrongGender: number[];
  prefs: any;
  matches: any;
  nrooms: any;
  nbed: number;
  npax: number;
  ndriver: number;
  rand: boolean;
}

@Component({
  selector: 'app-assign-rooms',
  templateUrl: './assign-rooms.component.html',
  styleUrls: ['./assign-rooms.component.scss']
})
export class AssignRoomsComponent implements OnInit {
  loading = false;
  reason: string;
  warnings: any = [];

  errorFound = false;
  PAXGenderless = false;
  PAXRoomless = false;
  roomGenderless = false;
  assignedMatches = false;
  plus = false;

  males = 0;
  females = 0;
  ntw = 0;
  ndb = 0;

  constructor(
    private snackSvc: SnackbarService,
    private router: Router,
    public dialogRef: MatDialogRef<AssignRoomsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAssignRooms
  ) {
    this.plus = this.data.tour.code.substring(0, 1) === 'X';
  }

  ngOnInit() {
    this.data.rand = false;
    this.males = this.data.tour_pax?.filter(tp => tp.room === 'St' && tp.user.gender === 'male').length;
    this.females = this.data.tour_pax?.filter(tp => tp.room === 'St' && tp.user.gender === 'female').length;
    this.ntw = this.data.rooms?.filter(r => !r.gender && r.type === 'twin').length;
    this.ndb = this.data.rooms?.filter(r => r.type === 'double').length;
    this.runChecklist();
  }

  runChecklist() {
    if (this.data.tour.rms.mal !== this.males) {
      this.warnings.push(
        {
          'code': 2,
          'txt': 'Males PAX list (' + this.males + ') vs settings (' + this.data.tour.rms.mal + ')',
        }
      );
    }
    if (this.data.tour.rms.fem !== this.females) {
      this.warnings.push(
        {
          'code': 3,
          'txt': 'Females PAX list (' + this.females + ') vs settings (' + this.data.tour.rms.fem + ')',
        }
      );
    }
    if (this.data.tour.rms.dbl !== this.ndb) {
      this.warnings.push(
        {
          'code': 8,
          'txt': 'Doubles (' + this.ndb + ') vs settings (' + this.data.tour.rms.dbl + ')',
        }
      );
    }
    if (this.data.tour.rms.twn !== this.ntw) {
      this.warnings.push(
        {
          'code': 9,
          'txt': 'Twin upgrade (' + this.ntw + ') vs settings (' + this.data.tour.rms.twn + ')',
        }
      );
    }
    const npax = this.data.tour_pax.filter(tp => tp.user.role === 1).length;
    const nadmin = this.data.tour_pax.filter(tp => tp.user.role === 2 && tp.user.name !== 'Bus Driver').length;
    if (this.data.nbed < npax + nadmin + this.data.ndriver) {
      this.warnings.push(
        {
          'code': 10,
          'txt': 'Beds (' + this.data.nbed + ') not enough for ' + npax + '+' + (nadmin + this.data.ndriver) + ' PAX',
        }
      );
    }

    this.checkPAXGenderless();
    this.checkPAXRoomless();
    this.checkGenderRoomless();
    this.checkAssigedPrefsless();
    this.checkNrooms();
    this.errorFound = this.PAXGenderless || this.PAXRoomless || this.roomGenderless || this.assignedMatches;
  }

  checkPAXGenderless() {
    this.PAXGenderless = this.data.tour_pax.filter(tp => !tp.user.gender).length > 0;
  }

  checkPAXRoomless() {
    this.PAXRoomless = this.data.tour_pax.filter(tp => !tp.room).length > 0;
  }

  checkGenderRoomless() {
    const dorm_rooms_no_gender = this.data.rooms.filter(r =>
      !r.gender && !(r.type === 'single' || r.type === 'double' || r.type === 'twin')
    );
    this.data.idxWrongGender = dorm_rooms_no_gender.map(a => a.id);
    const twin_rooms_no_gender = this.data.rooms.filter(r => !r.gender && r.type === 'twin');
    if (this.data.tour.rms.twn < twin_rooms_no_gender.length) {
      const extra_twins = twin_rooms_no_gender.splice(-(twin_rooms_no_gender.length - this.data.tour.rms.twn));
      this.data.idxWrongGender = [...this.data.idxWrongGender, ...extra_twins.map(a => a.id)];
    }
    this.roomGenderless = this.data.idxWrongGender.length > 0;
  }

  checkAssigedPrefsless() {
    const d = this.data.tour_pax.filter(tp => {
      if ((tp.room === 'Db' || tp.room === 'Tw') && this.data.matches.findIndex(m => m.includes(tp.user.name)) < 0) {
        return true;
      }
    });
    this.data.prefs = d;
    if (this.plus) {
      this.assignedMatches = false;
    } else {
      this.assignedMatches = d.length > 0;
    }
  }

  checkNrooms() {
    if (this.data.tour.rms.dbl > 0 && this.data.tour_pax.filter(tp => tp.room === 'Db').length < this.data.tour.rms.dbl * 2) {
      this.warnings.push(
        {
          'code': 4,
          'txt': 'Not enough PAX for the ' + this.data.tour.rms.dbl + ' double' + (this.data.tour.rms.dbl > 1 ? 's' : ''),
        }
      );
    }
    if (this.data.tour.rms.twn > 0 && this.data.tour_pax.filter(tp => tp.room === 'Tw').length < this.data.tour.rms.twn * 2) {
      this.warnings.push(
        {
          'code': 5,
          'txt': 'Not enough PAX for the ' + this.data.tour.rms.twn + ' twin' + (this.data.tour.rms.twn > 1 ? 's' : ''),
        }
      );
    }
    if (this.data.tour.rms.dbl > 0 && this.data.tour_pax.filter(tp => tp.room === 'Db').length > this.data.tour.rms.dbl * 2) {
      this.warnings.push(
        {
          'code': 6,
          'txt': 'Too many PAX for the ' + this.data.tour.rms.dbl + ' double' + (this.data.tour.rms.dbl > 1 ? 's' : ''),
        }
      );
    }
    if (this.data.tour.rms.twn > 0 && this.data.tour_pax.filter(tp => tp.room === 'Tw').length > this.data.tour.rms.twn * 2) {
      this.warnings.push(
        {
          'code': 7,
          'txt': 'Too many PAX for the ' + this.data.tour.rms.twn + ' twin' + (this.data.tour.rms.twn > 1 ? 's' : ''),
        }
      );
    }
  }

  onGoToPax() {
    this.dialogRef.close('close');
    this.router.navigate(['tour', this.data.tour.prodid, 'tourpax'], {queryParams: {returnUrl: window.location.pathname}});
  }

  mngWarn(code) {
    this.warnings = this.warnings.filter(obj => obj.code !== code);
    if (code >= 1 && code <= 7) {
      this.onGoToPax();
    } else if (code >= 8 && code <= 12) {
      this.close('onCreateRooms');
    }
  }

  submit(): void {
    this.close(this.data);
  }

  close(reason) {
    this.reason = reason;
    this.dialogRef.close(reason);
  }
}
