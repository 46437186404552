import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Contacts} from '../../models/contacts.model';

export interface ContactsList {
  reason: string;
  contacts: Contacts;
}

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ContactsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactsList) {
  }

  ngOnInit(): void {
    // console.log('INIT');
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close();
  }
}
