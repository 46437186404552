import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../../shared/services/common/title.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {Title} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {Subject} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {Freetime} from '../../../../shared/models/freetime.model';
import {FreetimeType} from '../../../../shared/models/freetime-type.model';
import {HttpFreetimeService} from '../../../../shared/services/http/http-freetime.service';
import {environment} from '../../../../../environments/environment';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {AddCityComponent} from '../../../components/add-city/add-city.component';
import {VersioningService} from '../../../../shared/services/common/versioning.service';

@Component({
  selector: 'app-freetime',
  templateUrl: './freetime.component.html',
  styleUrls: ['./freetime.component.scss']
})
export class FreetimeComponent implements OnInit, OnDestroy {
  currentUser: User;
  baseUrlLogo: string;
  page_title: string;
  city_name: string;
  city_cover: string;
  city_deets: string;

  show_citydeets = false;
  edit_city = false;
  edit_freetime = false;
  loadingCities = false;

  url_city: string;
  url_type: string;
  url_typename: string;
  city_id: number;
  type_id: number;

  cities: any[] = [];
  freetime_types: FreetimeType[] = [];
  freetimes: Freetime[] = [];

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpCitySvc: HttpCityService,
    private httpFreetimeService: HttpFreetimeService,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private route: ActivatedRoute,
    private router: Router,
    private versioning: VersioningService,
    private location: Location,
    public dialog: MatDialog,
    private ngxPicaService: NgxPicaService,
    private ngxPicaImageService: NgxPicaImageService,
    private textTransformService: TextTransformService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.titleSvc.setTitle('Free Time');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit() {
    this.baseUrlLogo = environment.baseUrl;
    this.url_city = this.route.snapshot.params['city_name'];
    this.url_type = this.route.snapshot.params['ft_type'];
    this.url_typename = this.route.snapshot.params['ft_name'];
    this.edit_city = this.route.snapshot.queryParams['edit'] || null;

    if (!this.url_city) {
      this.page_title = null;
      this.webTitleSvc.setTitle('Free Time | Planafy');
      this.getAllCities();
      return;
    }
    if (this.url_city && !this.url_type) {
      this.page_title = this.textTransformService.dehyphenize(this.url_city);
      this.webTitleSvc.setTitle(this.page_title + ' Free Time | Planafy');
      this.getAllFreetimeTypeCity(this.page_title);
      return;
    }
    if (this.url_city && this.url_type) {
      this.page_title = this.textTransformService.dehyphenize(this.url_typename);
      const city_name = this.textTransformService.dehyphenize(this.url_city);
      this.webTitleSvc.setTitle(this.page_title + ' - ' + city_name + ' | Planafy');
      this.getAllFreetimes(+this.url_type);
    }
  }

  printIt() {
    console.log(this.cities);
    console.log(this.freetime_types);
    console.log(this.freetimes);
  }

  /*
  *  CITY
  */
  getAllCities() {
    this.loadingCities = true;
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.versioning.check(res.results.version);
            // Save all cities
            this.cities = res.results.cities;
            if (this.currentUser.user_settings.edit_freetimes) {
              this.cities = [{id: 0, name: '** Not Found **', country: 'Add new'}].concat(res.results.cities);
            }
            this.city_id = null;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingCities = false;
        },
        error => {
          this.loadingCities = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  onAddCity() {
    const dialogRef = this.dialog.open(AddCityComponent, {
      data: {'autoFocus': true}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'name': this.textTransformService.titleCase(result['name']),
          'country': this.textTransformService.titleCase(result['country']),
          'confirmed': true
        };
        this.httpCitySvc.saveCity(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const city = res.results.city;
                const CityIdx = this.cities.findIndex(x => x.name === city.name && x.country === city.country);
                if (CityIdx < 0) {
                  this.cities.push(city);
                  localStorage.setItem('cities', JSON.stringify(this.cities));
                } else {
                  this.snackSvc.openSnackBar('City already exists');
                }
                this.city_id = city.id;
                this.cities.sort(function (a, b) {
                  return a.name[0].localeCompare(b.name[0]);
                });
                this.onSelectionFTCity({target: {value: res.results.city.id}});
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
            });
      } else {
        this.city_id = null;
      }
    });
  }

  onEditCity() {
    this.edit_city = true;
    this.router.navigate([], {
      queryParams: {
        'edit': true,
      },
      queryParamsHandling: 'merge'
    });
  }

  uploadCityImage(event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      this.city_cover = null;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('city_id', '' + this.city_id);
              this.httpCitySvc.uploadCityImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      this.city_cover = res.results.city.image;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    console.log(error);
                  });
            }, (err: NgxPicaErrorInterface) => {
              console.log('image not resized');
              console.log(err.err);
            });
        }, (err: NgxPicaErrorInterface) => {
          console.log('image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  updateCity(txt, value) {
    const data = {
      'req_id': this.currentUser.id
    };
    if (txt === 'image') {
      data['del_image'] = true;
    }
    data[txt] = value;
    this.httpCitySvc.updateCity(this.city_id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (txt === 'image') {
              this.city_cover = null;
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  /*
  *  FT TYPE
  */
  onSelectionFTCity(event) {
    // avoid early triggers
    if (event.target.value === '') {
      return;
    }
    if ('' + event.target.value === '' + 0) {
      this.onAddCity();
      return;
    }
    const new_index = this.cities.findIndex(item => '' + item.id === '' + event.target.value);
    if (Number(event.target.value)) {
      this.city_id = Number(event.target.value);
      this.router.navigate(['freetime', this.textTransformService.urlize(this.cities[new_index].name)]);
    }
  }

  getAllFreetimeTypeCity(city) {
    this.httpFreetimeService.getAllFreetimeTypesCity(city)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Insert all cities
            this.freetime_types = res.results.freetime_types_city;
            this.city_name = res.results.city_name;
            this.city_cover = res.results.city_image;
            this.city_deets = res.results.city_description;
            this.city_id = res.results.city_id;
            this.type_id = null;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting activities in city');
        });
  }

  onAddFTType() {
    const data = {
      'req_id': this.currentUser.id,
      'city_id': this.city_id,
      'name': '-',
    };
    this.httpFreetimeService.createFTType(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const item = res.results.freetime_type;
            item.name = null;
            item.image = null;
            this.freetime_types.push(item);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  uploadFTtypeImage(event, ftt) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      ftt.image = null;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              let newFile: File;
              if (file.type.includes('gif')) {
                newFile = new File([imageCompressed], file.name, {type: imageResized.type});
              } else {
                newFile = new File([imageResized], file.name, {type: imageResized.type});
              }
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('freetime_type_id', '' + ftt.id);
              this.httpFreetimeService.uploadFTTypeImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      ftt.image = res.results.freetime_type.image;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    console.log(error);
                  });
            }, (err: NgxPicaErrorInterface) => {
              console.log('image not resized');
              console.log(err.err);
            });
        }, (err: NgxPicaErrorInterface) => {
          console.log('image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  updateFTtype(txt, ftt) {
    const data = {
      'req_id': this.currentUser.id
    };
    if (txt === 'image') {
      ftt[txt] = null;
      data['del_image'] = true;
    }
    data[txt] = ftt[txt];
    this.httpFreetimeService.updateFTtype(ftt.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (txt === 'image') {
              ftt.image = null;
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  onSaveFTtype() {
    if (this.freetime_types.find(i => i.name === null || i.name === '')) {
      this.snackSvc.openSnackBar('Error. Name required');
    } else {
      this.edit_city = false;
      this.router.navigate([], {
        queryParams: {
          'edit': null,
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  deleteFTtype(ftt) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete item?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          const data = {
            'req_id': this.currentUser.id,
            'freetime_type_id': ftt.id
          };
          this.httpFreetimeService.deleteFreetimeType(data)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  const fttypeIdx = this.freetime_types.findIndex(i => '' + i.id === '' + ftt.id);
                  this.freetime_types.splice(fttypeIdx, 1);
                } else {
                  this.snackSvc.resultsElse(res);
                }
              },
              error => {
                console.log(error);
              });
        }
      });
  }


  /*
  *  FREETIME
  */
  onGetAllFreetimes(fttype) {
    if (this.edit_city) {
      return;
    }
    const type_url = this.textTransformService.urlize(fttype.name);
    this.router.navigate(['freetime', this.url_city, fttype.id, type_url]);
  }

  getAllFreetimes(type_id) {
    this.httpFreetimeService.getFreetimesType(type_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Insert all cities
            this.freetimes = res.results.freetimes;
            this.type_id = type_id;
            this.city_id = null;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting activities in city');
        });
  }

  onAddFreetime() {
    const data = {
      'req_id': this.currentUser.id,
      'freetime_type_id': this.type_id,
      'name': '-',
    };
    this.httpFreetimeService.createFreetime(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const item = res.results.freetime;
            item.name = null;
            item.show_deets = false;
            this.freetimes.unshift(item);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  onEditFreetime() {
    this.edit_freetime = true;
  }

  updateFTifEmpty(txt, ft) {
    if (!ft[txt]) {
      this.updateFreetime(txt, ft);
    }
  }

  updateFreetime(txt, ft) {
    const data = {
      'req_id': this.currentUser.id
    };
    if (txt === 'image') {
      ft[txt] = null;
      data['del_image'] = true;
    }
    data[txt] = ft[txt].trim();
    this.httpFreetimeService.updateFreetime(ft.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          ft[txt] = ft[txt].trim();
          if (res.status < 400) {
            if (txt === 'image') {
              ft.image = null;
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  uploadFreetimeImage(event, ft) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      ft.image = null;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              let newFile: File;
              if (file.type.includes('gif')) {
                newFile = new File([imageCompressed], file.name, {type: imageResized.type});
              } else {
                newFile = new File([imageResized], file.name, {type: imageResized.type});
              }
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('freetime_id', '' + ft.id);
              this.httpFreetimeService.uploadFTImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      ft.image = res.results.freetime.image;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    console.log(error);
                  });
            }, (err: NgxPicaErrorInterface) => {
              console.log('image not resized');
              console.log(err.err);
            });
        }, (err: NgxPicaErrorInterface) => {
          console.log('image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  onSaveFreetime() {
    if (this.freetimes.find(i => i.name === null || i.name === '' || i.name === '-')) {
      this.snackSvc.openSnackBar('Please provide a name');
    } else {
      this.edit_freetime = false;
    }
  }

  deleteFreetime(ft) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete item?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          const data = {
            'req_id': this.currentUser.id,
            'freetime_id': ft.id
          };
          this.httpFreetimeService.deleteFreetime(data)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  const ftIdx = this.freetimes.findIndex(i => '' + i.id === '' + ft.id);
                  this.freetimes.splice(ftIdx, 1);
                } else {
                  this.snackSvc.resultsElse(res);
                }
              },
              error => {
                console.log(error);
              });
        }
      });
  }

  /*
  *  OTHER
  */

  goBack() {
    let txt = 'freetime/';
    const type = this.route.snapshot.params['ft_type'];
    if (type) {
      const city_name = this.route.snapshot.params['city_name'];
      this.page_title = city_name;
      txt = txt + city_name;
      this.webTitleSvc.setTitle(city_name + ' Free Time | Planafy');
    } else {
      this.webTitleSvc.setTitle('Free Time | Planafy');
    }
    this.router.navigate([txt]);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
