import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ComponentTime {
  time: string;
  sc: boolean;
  isMobile: boolean;
  isSafari: boolean;
}

@Component({
  selector: 'app-time-changer',
  templateUrl: './time-changer.component.html',
  styleUrls: ['./time-changer.component.scss']
})
export class TimeChangerComponent {

  hours: string;
  minutes: string;

  constructor(
    public dialogRef: MatDialogRef<TimeChangerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentTime) {
    if (this.data?.time?.includes(':')) {
      const tmp = this.data.time.split(':');
      this.hours = tmp[0];
      this.minutes = tmp[1];
    }
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Escape') {
      this.close('close');
    } else if (event.key === 'Enter') {
      this.submit();
    }
  }

  onChangedTime() {
    this.data.time = this.hours + ':' + this.minutes;
  }

  submit(): void {
    this.close(this.data);
  }

  close(reason) {
    this.dialogRef.close(reason);
  }
}
