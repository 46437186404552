import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {Voucher} from '../../../../shared/models/voucher.model';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-edit-vouchers',
  templateUrl: './edit-vouchers.component.html',
  styleUrls: ['./edit-vouchers.component.scss']
})
export class EditVouchersComponent implements OnInit, OnDestroy {
  currentUser: User;
  loading = false;

  private onDestroy$ = new Subject<boolean>();
  @Output() deleteVoucher = new EventEmitter();
  @Output() updateVoucher = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<EditVouchersComponent>,
    private router: Router,
    public dialog: MatDialog,
    private httpUser: HttpUserService,
    private httpTour: HttpTourService,
    private httpOrders: HttpOrdersService,
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private httpOrdSvc: HttpOrdersService,
    private snackSvc: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: { voucher: Voucher, reason: string }
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit(): void {
    this.data.voucher.edit_mode = false;
  }

  onUpdateVouch(v: Voucher, type?: string) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let data;
    if (type && type === 'restore') {
      data = {
        'restore': true,
      };
    } else {
      data = {
        'req_id': 2,
        'user_id': v.user_id,
        'tour_id': v.tour_id,
        'name': v.name.toUpperCase(),
        'used_at': v.used_at,
      };
      if (v.price_pday) {
        data['price_pday'] = v.price_pday;
      }
      if (v.tour_cost) {
        data['tour_cost'] = v.tour_cost;
      }
      if (v.amount_off) {
        data['amount_off'] = v.amount_off;
      }
    }
    // console.log(data);
    this.httpOrdSvc.updateVoucher(v.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            v = res.results.voucher;
            v.edit_mode = false;
            this.data.voucher = v;
            this.updateVoucher.emit(v);
          } else {
            console.log('Error: voucher not updated');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR. Updating voucher');
        });
  }

  onDeleteVouch(v: Voucher) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    if (v.deleted_at) {
      this.httpOrdSvc.deleteVoucher('' + v.id, 'true')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.deleteVoucher.emit(v.id);
              this.data.reason = 'force_deleted';
              this.dialogRef.close(this.data);
            } else {
              // Error deleting order
              if (res.results) {
                this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
              } else {
                console.log(res.message.toString());
              }
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.snackSvc.openSnackBar('ERROR. Force deleting voucher');
            console.log(error);
          });
    } else {
      this.httpOrdSvc.deleteVoucher('' + v.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              // console.log('ok');
              v.deleted_at = 'true';
              v.edit_mode = false;
            } else {
              v.deleted_at = null;
              // Error deleting city itinerary in DB
              if (res.results) {
                this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
              } else {
                console.log(res.message.toString());
              }
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
            v.deleted_at = null;
            this.snackSvc.openSnackBar('ERROR. Deleting voucher');
            console.log(error);
          });
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
