export class AppVersion {
  id: number;
  android_version: string;
  ios_version: string;
  created_at: string;

  constructor(a: any) {
    this.id = a.id;
    this.android_version = a.android_version;
    this.ios_version = a.ios_version;
    this.created_at = a.created_at;
  }
}
