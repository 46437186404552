import {Component, Inject} from '@angular/core';
import {Activetour} from '../../../shared/models/activetour.model';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../shared/services/common/snackbar.service';
import {ActivetourService} from '../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss']
})
export class AddCityComponent {
  activeTour: Activetour;
  private onDestroy$ = new Subject<boolean>();

  profileForm = new UntypedFormGroup({
    reason: new UntypedFormControl(''),
    name: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<AddCityComponent>,
              private snackSvc: SnackbarService,
              private activeTourSvc: ActivetourService,
              @Inject(MAT_DIALOG_DATA) public data: { city: '', supplier: '' }) {
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
  }

  submit(): void {
    if (this.profileForm.controls.name.invalid) {
      this.snackSvc.openSnackBar('Invalid name');
      return;
    }
    if (this.profileForm.controls.country.invalid) {
      this.snackSvc.openSnackBar('Invalid country');
      return;
    }
    this.close('send');
  }

  close(reason) {
    this.profileForm.value.reason = reason;
    this.dialogRef.close(this.profileForm.value);
  }
}
