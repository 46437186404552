import {Component, OnInit} from '@angular/core';
import {Router, Scroll} from '@angular/router';
import {delay, filter, observeOn} from 'rxjs/operators';
import {ViewportScroller} from '@angular/common';
import {asyncScheduler} from 'rxjs';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Planafy - Smart tours';

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) {
  }

  ngOnInit() {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0);
    // });

    // Check web version only in live
    if (environment.selfUrl !== 'https://planafy.com/') {
      console.log('Not live');
    }

    this.router.events
      .pipe(
        filter((e: any): e is Scroll => e instanceof Scroll),
        delay(0),
        observeOn(asyncScheduler),
      ).subscribe(e => {
      if (e.position) {
        // backward navigation
        this.viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        this.viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
