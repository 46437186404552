import {Component, Inject} from '@angular/core';
import {Activetour} from '../../../../shared/models/activetour.model';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';

@Component({
  selector: 'app-add-hotel',
  templateUrl: './add-hotel.component.html',
  styleUrls: ['./add-hotel.component.scss']
})
export class AddHotelComponent {
  activeTour: Activetour;
  cities: any[] = [];
  city_id: string;
  city_name: string;

  private onDestroy$ = new Subject<boolean>();

  profileForm = new UntypedFormGroup({
    reason: new UntypedFormControl(''),
    name: new UntypedFormControl('', Validators.required),
    address: new UntypedFormControl('', Validators.required),
    city_id: new UntypedFormControl('', Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<AddHotelComponent>,
              private snackSvc: SnackbarService,
              private httpCitySvc: HttpCityService,
              private activeTourSvc: ActivetourService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.getAllCities();
  }

  submit(): void {
    if (this.profileForm.controls.name.invalid) {
      this.snackSvc.openSnackBar('Invalid name');
      return;
    }
    if (this.profileForm.controls.address.invalid) {
      this.snackSvc.openSnackBar('Invalid address');
      return;
    }
    if (this.profileForm.controls.city_id.invalid) {
      this.snackSvc.openSnackBar('Invalid city');
      return;
    }
    this.close('send');
  }

  getAllCities() {
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Insert all cities
            this.cities = res.results.cities;
            if (this.data.city_id) {
              this.profileForm.controls['city_id'].setValue(this.data.city_id);
              this.city_name = this.cities.find(city => city.id === this.data.city_id).name;
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  onSelectCity(e) {
    this.city_id = e.target.value;
  }

  close(reason) {
    this.profileForm.value.reason = reason;
    this.dialogRef.close(this.profileForm.value);
  }
}
