export class Billing {
  id: number;
  user_id: number;
  tax_name: string;
  tax_number: string;
  address_f1: string;
  address_f2: string;
  admin_f1: string;
  admin_f2: string;
  zip_code: string;
  country: string;

  constructor(user_name: string) {
    this.tax_name = user_name;
    this.tax_number = '';
    this.address_f1 = '';
    this.address_f2 = '';
    this.admin_f1 = '';
    this.admin_f2 = '';
    this.zip_code = '';
    this.country = '';
  }
}
