import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivetourService} from '../../services/session/activetour.service';
import {AuthenticationService} from '../../services/session/authentication.service';
import {SnackbarService} from '../../services/common/snackbar.service';
import {HttpCityService} from '../../services/http/http-city.service';
import {Subject} from 'rxjs';
import {Activetour} from '../../models/activetour.model';
import {User} from '../../models/user.model';
import {FlagService} from '../../services/common/flag.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpComponentService} from '../../services/http/http-component.service';
import {Components} from '../../models/components.model';
import {formatDate} from '@angular/common';
import {TextTransformService} from '../../helpers/texttransform.service';

export interface CompsList {
  reason: string;
  components: any[];
}

@Component({
  selector: 'app-tdicomps',
  templateUrl: './tdicomps.component.html',
  styleUrls: ['./tdicomps.component.scss']
})
export class TDIcompsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;

  old_comps: Components[];
  allComplete = false;
  creating = false;
  comp_ids: number[] = [];
  loadingPDF: boolean;
  file: any;

  onSave = new EventEmitter();
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
    private httpCitySvc: HttpCityService,
    private httpCompSvc: HttpComponentService,
    private flagService: FlagService,
    private textTransform: TextTransformService,
    private router: Router,
    public dialogRef: MatDialogRef<TDIcompsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CompsList
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
  }

  ngOnInit(): void {
    this.data.components = [];
    this.old_comps = this.activeTourSvc.getTDIcomps(this.activeTour.tour.prodid);
    if (this.old_comps) {
      this.data.components = this.old_comps;
    }
    this.data.components.map(c => c.checked = false);
    this.allComplete = this.data.components.every(c => c.checked);
  }

  showComps() {
    console.log(this.data.components);
  }

  // CHAT GPT

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  prepareFilesList(file) {
    if (this.loadingPDF) {
      return;
    }
    this.file = file[0];
    if (!this.file.type.includes('pdf')) {
      // this.file = null;
      this.snackSvc.openSnackBar('File must be a pdf');
      return;
    }
    if (!this.file.name.toLowerCase().includes('tdi')) {
      // this.file = null;
      this.snackSvc.openSnackBar('Upload TDI pdf');
      return;
    }
    this.uploadTDI();
  }

  uploadTDI() {
    this.loadingPDF = true;
    const formData = new FormData();
    formData.append('only_comps', '1');
    formData.append('file', this.file);
    formData.append('user_id', '' + this.currentUser.id);
    formData.append('tour_id', '' + this.activeTour.tour.id);
    this.httpCitySvc.uploadTDI(formData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.comp_ids = [];
          this.data.components = res.results.components;
          this.data.components = this.data.components.filter(c => {
            // return c.comp_type_id !== 1 && c.comp_type_id !== 12 && c.comp_type_id !== 16 && c.comp_type_id !== 17 && c.comp_type_id !== 10;
            return c.comp_type_id !== 1;
          });
          this.activeTourSvc.setTDIcomps(this.activeTour.tour.prodid, this.data.components);
          this.allComplete = false;
          this.data.components.map(c => {
            c.checked = false;
            // if (c.recipients) {
            //   const tmp = c.recipients.split(',');
            //   tmp.map((r, i, a) => a[i] = r.split('@')[0]);
            //   c.recipients2 = tmp.join(', ');
            //   c.recipients2 = '@ ' + c.recipients2;
            // }
          });
          this.file = null;
        } else {
          if (res.results) {
            this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
          } else {
            this.snackSvc.openSnackBar(res.message.toString());
            console.log(res.message.toString());
          }
        }
        this.loadingPDF = false;
      }, error => {
        this.loadingPDF = false;
        console.log(error);
        if (error.includes('Undefined array key')) {
          this.snackSvc.openSnackBar('Error. Contact support');
        } else {
          this.snackSvc.openSnackBar('Error. Try again');
        }
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error uploadTDI()', JSON.stringify(error));
      });
  }

  clickComp(comp) {
    if (this.creating) {
      return;
    }
    comp.checked = !comp.checked;
    const idIDx = this.comp_ids.findIndex(c => +c === +comp.id);
    if (comp.checked) {
      if (idIDx < 0) {
        this.comp_ids.push(comp.id);
      }
    } else {
      if (idIDx >= 0) {
        this.comp_ids.splice(idIDx, 1);
      }
    }
  }

  someComplete(): boolean {
    if (!this.data.components) {
      return false;
    }
    return this.data.components.filter(c => c.checked).length > 0 && !this.allComplete;
  }

  deleteComps() {
    this.data.components = this.data.components.filter(c => !this.comp_ids.includes(c.id));
    this.activeTourSvc.setTDIcomps(this.activeTour.tour.prodid, this.data.components);
    this.comp_ids = [];
  }

  selectAll(event) {
    this.comp_ids = [];
    this.allComplete = event.checked;
    this.data.components.forEach(c => c.checked = event.checked);
    if (event.checked) {
      this.data.components.map(c => this.comp_ids.push(c.id));
    }
  }

  resetTDI() {
    this.allComplete = false;
    this.data.components = [];
    this.old_comps = [];
    this.comp_ids = [];
    this.activeTourSvc.setTDIcomps(this.activeTour.tour.prodid, []);
  }

  async createComponent(comp) {
    try {
      // console.log(comp);
      const res = await this.httpCompSvc.createComponentEventAsyn(comp);
      console.log(res);
      if (res.status < 400) {
        this.onSave.emit(res.results.component);
        // Remove comp from TDI list
        this.data.components = this.data.components.filter(c => +comp.id !== +c.id);
        this.activeTourSvc.setTDIcomps(this.activeTour.tour.prodid, this.data.components);
        this.allComplete = this.data.components.every(c => c.checked);
        this.comp_ids = this.comp_ids.filter(c => +comp.id !== +c);
      } else {
        let err = '';
        if (res.results) {
          err = res.results[Object.keys(res.results)[0]].toString();
        } else {
          err = res.message ? res.message.toString() : res;
        }
        comp['err'] = 'Error: ' + err;
        console.log('*err: ' + comp.id);
      }
      // If list empty, finish creating OR if all remaining have errors
      if (this.comp_ids.length === 0 || this.comp_ids.length > 0 && this.data.components.every(c => c.err)) {
        this.creating = false;
        this.onSave.emit('end');
      }
    } catch (error) {
      comp['err'] = 'Error: ' + error;
    }
  }

  submit() {
    this.data.components.map(c => c.err = null);
    const comps = this.data.components.filter(c => this.comp_ids.includes(c.id));
    for (const comp of comps) {
      this.onSave.emit('creating');
      this.creating = true;
      const itinIdx = this.activeTour.itinerary.findIndex(it => {
        return this.textTransform.betDays(it.check_in, it.check_out, comp.start_date) ||
          this.textTransform.betDays(this.activeTour.tour.tour_starts, this.activeTour.tour.tour_starts, comp.start_date);
      });
      if (itinIdx >= 0) {
        const itin_id = this.activeTour.itinerary[itinIdx].id;
        const city_id = this.activeTour.itinerary[itinIdx].city_id;
        comp['itinerary_id'] = itin_id;
        comp['city_id'] = city_id;
        if ('' + comp['comp_type_id'] === '2' || '' + comp['comp_type_id'] === '4') {
          comp['from'] = this.activeTour.itinerary[itinIdx].hotel_name;
          comp['to'] = 'Guided Sightseeing';
        } else if ('' + comp['comp_type_id'] === '3') {
          comp['from'] = this.activeTour.itinerary[itinIdx].hotel_name;
          comp['to'] = this.activeTour.itinerary[itinIdx].hotel_name;
        } else if ('' + comp['comp_type_id'] === '16') {
          comp['from'] = this.activeTour.itinerary[itinIdx].city.name + ' airport';
          comp['to'] = this.activeTour.itinerary[itinIdx].hotel_name;
        } else if ('' + comp['comp_type_id'] === '17') {
          comp['from'] = this.activeTour.itinerary[itinIdx].hotel_name;
          comp['to'] = this.activeTour.itinerary[itinIdx].city.name + ' airport';
        }
        if (comp['confirm_notes']) {
          comp['confirm_notes'] = comp['confirm_notes'].substring(0, 2048);
        }
        if (comp['description']) {
          comp['description'] = comp['description'].substring(0, 65535);
        }
        if (comp['from']) {
          comp['from'] = comp['from'].substring(0, 100);
        }
        if (comp['name']) {
          comp['name'] = comp['name'].substring(0, 100);
        }
        if (comp['recipients']) {
          comp['recipients'] = this.fixRecipients(comp['recipients'].substring(0, 400));
        }
        if (comp['requests']) {
          comp['requests'] = comp['requests'].substring(0, 65535);
        }
        if (comp['supplier']) {
          comp['supplier'] = comp['supplier'].substring(0, 100);
        }
        if (comp['to']) {
          comp['to'] = comp['to'].substring(0, 100);
        }
        this.createComponent(comp);
      } else {
        this.creating = false;
        this.onSave.emit('end');
        comp.checked = false;
        comp['err'] = 'Date not on tour: ' + formatDate(new Date(comp.start_date), 'dd-MM-yyyy', 'en');
        this.comp_ids = this.comp_ids.filter(c => +comp.id !== +c);
      }
    }
  }

  fixRecipients(recs): string {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emails = recs.split(',');
    const validEmails = emails.filter(email => emailRegex.test(email.trim()));
    return validEmails.join(',');
  }

  close(reason) {
    this.data.reason = reason;
    if (this.creating && (reason === 'close' || !reason)) {
      this.snackSvc.openSnackBar('Components will be created');
    }
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
