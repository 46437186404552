import {Injectable} from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  blob: Blob;

  constructor() {
  }

  findLongestItem(data, i) {
    let longestNameLength = 0;
    let longestName = '';
    data.forEach(row => {
      const name = row[i];
      if (name && name.length > longestNameLength) {
        longestNameLength = name.length;
        longestName = name;
      }
    });
    return longestName;
  }

  downloadExcel(headers: any[], data: any[], fileName: string): any {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(headers.includes('Timestamp') ? 'Responses' : 'Rooming');
    // Add headers
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell(cell => {
      cell.font = {bold: true};
    });
    // Add data
    data.forEach((row) => {
      if (headers.includes('Timestamp')) {
        // row[0] = row[0].replace(/T/g, ' ').replace(/.000000Z/g, '');
      }
      worksheet.addRow(row);
    });
    // Freeze first row
    worksheet.views = [
      {state: 'frozen', xSplit: 0, ySplit: 1}
    ];
    // Autofilter
    worksheet.autoFilter = {
      from: 'A1',
      to: {
        row: data.length + 1,
        column: headers.length
      }
    };
    // All columns according to maz size
    for (let i = 0; i < headers.length; i++) {
      worksheet.getColumn(i + 1).width = this.findLongestItem([headers, ...data], i).length + 2;
    }
    // FORM excel
    if (headers.includes('Timestamp')) {
      // Timestamp column
      worksheet.getColumn(1).numFmt = 'dd/mm/yyyy h:mm:ss';
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      saveAs(blob, fileName);
    });
  }
}
