import {City} from './city.model';
import {Comptype} from './comptype.model';
import {Image} from './image.model';

export class Activity {
  id: string;
  comp_type_id: number;
  city_id: number;
  image_id: number;
  all_cities: string;
  name: string;
  from: string;
  to: string;
  supplier: string;
  email: string;
  notes: string;
  when: string;
  prev_city: string;
  next_city: string;
  companies: string;
  updated_at: string;
  deleted_at: string;
  comp_type?: Comptype;
  city?: City;
  image?: Image;
  loading_img?: boolean;
  cia?: string;
  company_ids?: number[];

  constructor(city: any) {
    this.all_cities = city;
    this.when = 'CI+1';
  }
}
