import {Component, Inject} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Billing} from '../../../../shared/models/billing.model';
import {takeUntil} from 'rxjs/operators';
import {HttpInvoiceService} from '../../../../shared/services/http/http-invoice.service';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {Invoice} from '../../../../shared/models/invoice.model';

export interface DialogDataCreateInvoice {
  user_id: number;
  order_id: number;
  billing: Billing;
  invoice: Invoice;
  reason: string;
}

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent {
  loading = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private flagService: FlagService,
    private httpInvoiceService: HttpInvoiceService,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<CreateInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCreateInvoice) {
  }

  info() {
    console.log(this.data);
  }

  submit(): void {
    if (!this.data.billing.tax_name) {
      this.snackSvc.openSnackBar('Error. Invalid name');
      return;
    }
    if (!this.data.billing.tax_number) {
      this.snackSvc.openSnackBar('Error. Invalid tax number');
      return;
    }
    if (!this.data.billing.address_f1) {
      this.snackSvc.openSnackBar('Error. Invalid address');
      return;
    }
    if (!this.data.billing.admin_f1) {
      this.snackSvc.openSnackBar('Error. Invalid state/region');
      return;
    }
    if (!this.data.billing.zip_code) {
      this.snackSvc.openSnackBar('Error. Invalid postal code');
      return;
    }
    if (!this.data.billing.country) {
      this.snackSvc.openSnackBar('Error. Invalid country');
      return;
    }
    if (this.data.order_id) {
      this.createInvoice();
    } else {
      this.createBilling();
    }
  }

  createInvoice() {
    this.loading = true;
    this.flagService.setFlag(this.data.user_id, this.router.url, 'Requests invoice', '');
    const data = {
      'orders_id': this.data.order_id,
      'user_id': this.data.user_id,
      'tax_name': this.data.billing.tax_name,
      'tax_number': this.data.billing.tax_number,
      'address_f1': this.data.billing.address_f1,
      'address_f2': this.data.billing.address_f2,
      'admin_f1': this.data.billing.admin_f1,
      'admin_f2': this.data.billing.admin_f2,
      'zip_code': this.data.billing.zip_code,
      'country': this.data.billing.country,
    };
    // console.log(data);
    this.httpInvoiceService.createInvoicewithBilling(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.invoice = res.results.invoice;
            this.close('save');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  createBilling() {
    this.loading = true;
    const data = {
      'user_id': this.data.user_id,
      'tax_name': this.data.billing.tax_name,
      'tax_number': this.data.billing.tax_number,
      'address_f1': this.data.billing.address_f1,
      'address_f2': this.data.billing.address_f2,
      'admin_f1': this.data.billing.admin_f1,
      'admin_f2': this.data.billing.admin_f2,
      'zip_code': this.data.billing.zip_code,
      'country': this.data.billing.country,
    };
    // console.log(data);
    this.httpInvoiceService.createBilling(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.invoice = res.results.invoice;
            this.close('save');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

}
