import {Contact} from './contact.model';

export class Contacts {
  count: number;
  updated_at: string;
  list: Contact[] = [];

  constructor(contactsRes?: any) {
    this.count = contactsRes?.count || 0;
    this.list = [];
    if (contactsRes?.contacts.length > 0) {
      contactsRes.contacts.forEach(c => {
        if (c.emailAddresses && c.emailAddresses.length > 0) {
          this.list.push(new Contact(c));
          this.list.sort(function (a, b) {
            return a.email[0].localeCompare(b.email[0]);
          });
        }
      });
    }
  }
}
