export class Company {
  id: number;
  name: string;
  branch: string;
  full_name: string;
  logo: string;
  details: string;

  constructor() {
    this.name = '';
    this.branch = '';
    this.full_name = '';
  }
}
