import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {Subject} from 'rxjs';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpFlightService} from '../../../../shared/services/http/http-flight.service';
import {Tour} from '../../../../shared/models/tour.model';

export interface DialogDataAddFlight {
  tour: Tour;
}

@Component({
  selector: 'app-add-flight',
  templateUrl: './add-flight.component.html',
  styleUrls: ['./add-flight.component.scss']
})
export class AddFlightComponent implements OnInit, OnDestroy {
  loading = false;
  reason: string;
  flight_name: string;
  flight_date: string;
  split: string;
  tour_pax: TourPax[] = [];
  group: TourPax[] = [];

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private snackSvc: SnackbarService,
    private httpFlight: HttpFlightService,
    public dialogRef: MatDialogRef<AddFlightComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddFlight) {
  }

  ngOnInit(): void {
  }

  validateInput() {
    return null;
  }

  togglePax(tp) {
    if (!this.group.includes(tp)) {
      this.group.push(tp);
    } else {
      const idx = this.group.findIndex(t => +t.id === +tp.id);
      this.group.splice(idx, 1);
    }
    if (!this.tour_pax.includes(tp)) {
      this.tour_pax.push(tp);
    } else {
      const idx = this.tour_pax.findIndex(t => +t.id === +tp.id);
      this.tour_pax.splice(idx, 1);
    }
    this.tour_pax.sort(function (a, b) {
      return a.user.name.localeCompare(b.user.name);
    });
  }

  submit(): void {
    if (this.group.length < 2) {
      return;
    }
    const wrong = this.validateInput();
    if (wrong) {
      this.snackSvc.openSnackBar(wrong);
      return;
    }
    this.createTPFlight();
  }

  createTPFlight() {
    this.loading = true;
    const data = {
      'tour_pax_id': this.group.map(g => g.id).join(','),
      'flight_name': this.flight_name,
      'flight_date': this.flight_date,
      'split': this.split,
    };
    this.httpFlight.createTPflight(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const tp_f = res.results.tourpax_flight;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  close(reason) {
    this.reason = reason;
    this.dialogRef.close(reason);
  }
}
