import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpTemplateService {
  private endpoints: {
    crudTemplate: string,
    allTemplate: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      crudTemplate: environment.apiUrl + 'template',
      allTemplate: environment.apiUrl + 'templates',
    };
  }

  /* gets template from user */
  getTemplateUser(req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.crudTemplate, {params: params});
  }

  /* gets async templates from user */
  async getTemplateUserAsync(req_id: number) {
    const params = new HttpParams()
      .set('req_id', req_id.toString());
    return await this.http.get<any>(this.endpoints.crudTemplate, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Restore template */
  restoreTemplate(paramsToRestore: any): Observable<any> {
    return this.http.post<any>(this.endpoints.crudTemplate, paramsToRestore);
  }

  /* Update template */
  updateTemplate(paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudTemplate, paramsToUpdate);
  }

}
