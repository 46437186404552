import {Component, Inject} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-form-flags',
  templateUrl: './form-flags.component.html',
  styleUrls: ['./form-flags.component.scss']
})
export class FormFlagsComponent {

  constructor(
    public dialogRef: MatDialogRef<FormFlagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  profileForm = new UntypedFormGroup({
    reason: new UntypedFormControl(''),
    problem: new UntypedFormControl('', Validators.required),
    solution: new UntypedFormControl(''),
  });

  submit(): void {
    if (this.profileForm.invalid) {
      return;
    }
    this.close('send');
  }

  close(reason) {
    this.profileForm.value.reason = reason;
    this.dialogRef.close(this.profileForm.value);
  }

}
