import {Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Orders} from '../../../../shared/models/orders.model';
import {takeUntil} from 'rxjs/operators';
import {Voucher} from '../../../../shared/models/voucher.model';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {User} from '../../../../shared/models/user.model';
import {Stripe} from '../../../../shared/models/stripe.model';
import {AddVoucherComponent} from '../add-voucher/add-voucher.component';

@Component({
  selector: 'app-edit-orders',
  templateUrl: './edit-orders.component.html',
  styleUrls: ['./edit-orders.component.scss']
})
export class EditOrdersComponent implements OnInit, OnDestroy {
  currentUser: User;
  vouchers: Voucher[] = [];
  loading = false;
  o_loading = false;

  private onDestroy$ = new Subject<boolean>();
  @Output() deleteOrder = new EventEmitter();
  @Output() updateOrder = new EventEmitter();
  @Output() newOrder = new EventEmitter();
  @Output() deleteVoucher = new EventEmitter();
  @Output() updateVoucher = new EventEmitter();


  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Enter') {
      this.onUpdateOrd();
    }
  }

  constructor(
    public dialogRef: MatDialogRef<EditOrdersComponent>,
    private router: Router,
    public dialog: MatDialog,
    private httpUser: HttpUserService,
    private httpTour: HttpTourService,
    private httpOrders: HttpOrdersService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: { orders: Orders, reason: string }
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit(): void {
    this.onGetOrder(this.data.orders);
    this.data.orders.edit_mode = this.data.orders.id === 0;
  }

  notify(txt) {
    this.snackSvc.openSnackBar(txt + ' copied');
  }

  // ORDERS

  onGetOrder(order) {
    this.o_loading = true;
    this.httpOrders.getOrderFull(order.id, 2)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.data.orders = res.results.order || null;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
          this.o_loading = false;
        },
        error => {
          this.o_loading = false;
          console.log(error);
        });
  }

  onEditOrd(ord: Orders) {
    ord.edit_mode = !ord.edit_mode;
  }

  onAddOrder(ord: Orders) {
    if (ord.user_id === null) {
      this.snackSvc.openSnackBar('ERROR. User missing');
      return;
    }
    if (ord.amount === null) {
      this.snackSvc.openSnackBar('ERROR. Amount missing');
      return;
    }
    if (ord.str_fee === null) {
      this.snackSvc.openSnackBar('ERROR. STR missing');
      return;
    }
    if (ord.pln_fee === null) {
      this.snackSvc.openSnackBar('ERROR. PLN missing');
      return;
    }
    if (!ord.currency_code) {
      this.snackSvc.openSnackBar('ERROR. Currency code missing');
      return;
    }
    this.loading = true;
    const data = {
      'user_id': ord.user_id,
      'amount': ord.amount,
      'currency_code': 'EUR',
      'pln_fee': ord.pln_fee,
      'str_fee': ord.str_fee,
      'voucher': ord.voucher,
      'status': ord.status,
    };
    if (ord.vouch && ord.vouch.id) {
      data['voucher_id'] = ord.vouch.id;
    }
    if (ord.stripe && ord.stripe.id) {
      data['stripe_id'] = ord.stripe.id;
    }
    if (ord.tour_id) {
      data['tour_id'] = ord.tour_id;
    }
    if (ord.tp_id) {
      data['tp_id'] = ord.tp_id;
    }
    if (ord.top_id) {
      data['top_id'] = ord.top_id;
    }
    // console.log(data);
    this.httpOrders.addOrder(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            ord = res.results.orders;
            ord.edit_mode = false;
            this.data.orders = ord;
            this.newOrder.emit(ord);
          } else {
            console.log('Error: order not created');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Creating order');
        });
  }

  onUpdateOrd(ord?: Orders, type?: string) {
    if (!ord) {
      ord = this.data.orders;
    }
    const old_vouch = ord.vouch;
    if (this.loading) {
      return;
    }
    this.loading = true;
    let data;
    if (type && type === 'restore') {
      data = {
        'restore': true,
      };
    } else {
      data = {
        'req_id': 2,
        'user_id': ord.user_id,
        'reference': ord.reference,
        't_name': ord.t_name,
        't_idcode': ord.t_idcode,
        't_logo': ord.t_logo,
        't_plan': ord.t_plan,
        'amount': ord.amount,
        'notes': ord.notes,
        'currency_code': ord.currency_code,
        'str_fee': ord.str_fee,
        'pln_fee': ord.pln_fee,
        'voucher': ord.voucher,
        'status': ord.status,
      };
      if (ord.stripe) {
        data['stripe_id'] = ord.stripe.id;
      }
    }
    // console.log(data);
    this.httpOrders.updateOrder(ord.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            ord = res.results.orders;
            if (old_vouch && type && type !== 'restore') {
              this.onUpdateVouch(old_vouch, type ? type : null);
            } else {
              ord.vouch = old_vouch;
            }
            if (ord.stripe && ord.stripe.id !== 0 && type && type !== 'restore') {
              this.onUpdateStripe(ord);
            }
            ord.edit_mode = false;
            this.data.orders = ord;
            this.updateOrder.emit(ord);
          } else {
            console.log('Error: order not updated');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating order');
        });
  }

  onDeleteOrd(ord: Orders) {
    if (this.loading) {
      return;
    }
    if (ord.deleted_at) {
      this.loading = true;
      this.httpOrders.deleteOrder(ord.id, 'true')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.deleteOrder.emit(ord.id);
              this.data.reason = 'force_deleted';
              this.dialogRef.close(this.data);
            } else {
              // Error deleting order
              if (res.results) {
                this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
              } else {
                console.log(res.message.toString());
              }
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.snackSvc.openSnackBar('ERROR. Force deleting order');
            console.log(error);
          });
    } else {
      this.loading = true;
      this.httpOrders.deleteOrder(ord.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.data.orders.edit_mode = false;
              this.data.orders.deleted_at = 'true';
            } else {
              // Error deleting city itinerary in DB
              if (res.results) {
                this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
              } else {
                console.log(res.message.toString());
              }
            }
            this.loading = false;
          },
          error => {
            ord.deleted_at = null;
            this.loading = false;
            this.snackSvc.openSnackBar('ERROR. Deleting order');
            console.log(error);
          });
    }
  }

  // STRIPE

  onClickAddS(ord: Orders) {
    ord.stripe = new Stripe();
    this.data.orders.stripe = ord.stripe;
  }

  onAddStripe(ord: Orders) {
    if (ord.stripe.status === null) {
      this.snackSvc.openSnackBar('ERROR. Status missing');
      return;
    }
    if (ord.stripe.session_id === null) {
      this.snackSvc.openSnackBar('ERROR. Session missing');
      return;
    }
    if (ord.stripe.payment_intent === null) {
      this.snackSvc.openSnackBar('ERROR. PI missing');
      return;
    }
    this.loading = true;
    const data = {
      'session_id': ord.stripe.session_id,
      'status': ord.stripe.status,
      'payment_intent': ord.stripe.payment_intent,
    };
    // console.log(data);
    this.httpOrders.addStripeOrder(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            ord.stripe = res.results.stripe;
            ord.stripe_id = ord.stripe.id;
            this.data.orders = ord;
            if (this.data.orders.id !== 0) {
              this.newOrder.emit(ord);
            }
          } else {
            console.log('Error: order not created');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Creating order');
        });
  }

  onUpdateStripe(ord: Orders) {
    if (ord.stripe && !ord.stripe.session_id) {
      this.snackSvc.openSnackBar('Set session ID');
      return;
    }
    const data = {
      'status': ord.stripe.status,
      'payment_intent': ord.stripe.payment_intent,
      'session_id': ord.stripe.session_id,
    };
    // console.log(data);
    this.httpOrders.updateStripeOrder(ord.stripe.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            ord.stripe = res.results.stripe;
          } else {
            console.log('Error: stripe not updated');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating stripe');
        });
  }

  // VOUCHER

  createVoucher(ord, user_id) {
    const dialogRef = this.dialog.open(AddVoucherComponent, {
      data: {'autoFocus': true, user_id: user_id, user: null}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        ord.vouch = result['voucher'];
        ord.voucher = result['voucher']['name'] + '#' + result['voucher']['id'];
        this.data.orders.vouch = ord.vouch;
        this.data.orders.voucher = ord.voucher;
        if (this.data.orders.id !== 0) {
          this.updateOrder.emit(ord);
        }
      }
    });
  }

  onUpdateVouch(v: Voucher, type?: string) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let data;
    if (type && type === 'restore') {
      v.deleted_at = null;
      data = {
        'restore': true,
      };
    } else {
      data = {
        'req_id': 2,
        'user_id': v.user_id,
        'tour_id': v.tour_id,
        'name': v.name,
        'used_at': v.used_at,
      };
      if (v.price_pday) {
        data['price_pday'] = v.price_pday;
      }
      if (v.tour_cost) {
        data['tour_cost'] = v.tour_cost;
      }
    }
    // console.log(data);
    this.httpOrders.updateVoucher(v.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            v = res.results.voucher;
            v.edit_mode = false;
            this.data.orders.vouch = v;
            this.data.orders.voucher = v.name;
            this.updateVoucher.emit(v);
          } else {
            console.log('Error: voucher not updated');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR. Updating voucher');
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
