import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../shared/services/session/authentication.service';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {User} from '../shared/models/user.model';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  currentUser: User;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private authSvc: AuthenticationService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => this.currentUser = user);
    // redirect to main if already logged in
    if (this.authSvc.currentUserValue) {
      this.router.navigate(['tours']);
    } else {
      window.location.href = 'https://we.planafy.com';
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
