export class Contact {
  id: string;
  name: string;
  email: string;

  constructor(contactRes?: any) {
    if (contactRes) {
      this.id = contactRes.id;
      if (contactRes.emailAddresses.length > 0) {
        this.name = contactRes.emailAddresses[0].name;
        this.email = contactRes.emailAddresses[0].address;
        if (this.name.includes('@') && contactRes.displayName && contactRes.displayName !== '') {
          this.name = contactRes.displayName;
        }
      } else {
        this.email = null;
      }
    }
  }
}
