import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {User} from '../../../../shared/models/user.model';
import {TitleService} from '../../../../shared/services/common/title.service';
import {Location} from '@angular/common';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {HttpOptionalService} from '../../../../shared/services/http/http-optional.service';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {AddCityComponent} from '../../../components/add-city/add-city.component';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {Optional} from '../../../../shared/models/optional.model';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {Company} from '../../../../shared/models/company.model';
import {AddOptionalComponent} from '../../../components/tour/add-optional/add-optional.component';
import {VersioningService} from '../../../../shared/services/common/versioning.service';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {HttpTourOptionalService} from '../../../../shared/services/http/http-tour-optional.service';
import {TourOptional} from '../../../../shared/models/tour-optional.model';

@Component({
  selector: 'app-optionals',
  templateUrl: './optionals.component.html',
  styleUrls: ['./optionals.component.scss']
})
export class OptionalsComponent implements OnInit, OnDestroy {
  currentUser: User;

  addNewOpt = false;
  edit = true;
  loadingOpts = false;
  loadingCities = false;
  loading = false;

  baseUrl: string;

  companies: Company[];
  cities: any[] = [];
  city_id: number;
  city_edit_id: number;
  opt_id: number;

  idxOptE: any[] = [];

  newOpt: Optional;
  optionals_city: Optional[] = [];
  @ViewChild('cityNew') searchElemCity: ElementRef;
  @ViewChild('top_opt') topOpt: ElementRef;
  @ViewChildren('opt_target') searchElementOpt: QueryList<ElementRef>;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpCitySvc: HttpCityService,
    private httpOptSvc: HttpOptionalService,
    private httpTourOptSvc: HttpTourOptionalService,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private versioning: VersioningService,
    private flgSvc: FlagService,
    private ngxPicaService: NgxPicaService,
    private ngxPicaImageService: NgxPicaImageService,
    private textTransformService: TextTransformService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.companies = JSON.parse(localStorage.getItem('companies'));
    this.titleSvc.setTitle('Activities');
    this.webTitleSvc.setTitle('Activities | Planafy');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.idxOptE = [];
    }
  }

  ngOnInit() {
    if (this.currentUser.role < User.admin) {
      this.companies = this.companies.filter(it => it.id !== 1);
    }
    this.baseUrl = environment.baseUrl;
    if (this.currentUser.role <= User.ef) {
      this.snackSvc.openSnackBar('Sorry, not allowed');
      this.router.navigate(['tours']);
      return;
    }
    this.getAllCities();
  }

  printIt() {
    // console.log(this.cities);
    // console.log(this.optionals_city);
    // console.log(this.newOpt);
  }

  getAllCities() {
    this.loadingCities = true;
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.versioning.check(res.results.version);
            // Insert all cities
            this.cities = res.results.cities;
            const city_name = this.route.snapshot.params['city_name'];
            if (city_name) {
              const cityIdx = this.cities.findIndex(it => it.name === this.textTransformService.dehyphenize(city_name));
              this.city_id = this.cities[cityIdx].id;
              this.getAllOptionalsCity(this.city_id);
            } else {
              this.city_id = null;
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingCities = false;
        },
        error => {
          this.loadingCities = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  onSelectionOpt(event) {
    if (event.target.value === '') {
      return;
    }
    this.opt_id = Number(event.target.value);
    const idx = this.searchElementOpt.toArray().findIndex(it => '' + it.nativeElement.id === '' + this.opt_id);
    if (idx >= 0) {
      setTimeout(() => {
        this.searchElementOpt.toArray()[idx].nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 1);
    }
  }

  onSelectionOptCity(event, edit?) {
    // avoid early triggers
    if (event.target.value === '') {
      return;
    }
    const new_index = this.cities.findIndex(item => '' + item.id === '' + event.target.value);
    // If edit trigger, change city to edit var
    if (edit) {
      this.city_edit_id = Number(event.target.value);
    } else {
      this.city_id = Number(event.target.value);
    }
    if (edit && this.addNewOpt) {
      this.newOpt = new Optional();
      this.newOpt.city_id = Number(event.target.value);
    }
    // If only viewing
    if (this.city_id && !edit) {
      this.getAllOptionalsCity(this.city_id);
      this.location.replaceState('/activities/' + this.textTransformService.urlize(this.cities[new_index].name));
      return;
    }
    // If editing and change, do nothing?
    if (this.city_edit_id && edit) {
      return;
    }
  }

  onClearOpt() {
    this.opt_id = null;
    setTimeout(() => {
      this.topOpt.nativeElement.scrollIntoView({behavior: 'auto', block: 'start'});
    }, 1);
  }

  onClearSelection() {
    this.city_id = null;
    this.opt_id = null;
    this.optionals_city = [];
    this.location.replaceState('/activities');
  }

  updateOptsCity() {
    this.optionals_city.map(o => {
      o.company_ids = o.companies.replace(/^,*/, '').replace(/,*$/, '').split(',').map(i => +i);
      o.cia = '';
      if (o.company_ids.includes(1)) {
        o.cia += 'PL,';
      }
      if (o.company_ids.includes(2)) {
        o.cia += 'UB,';
      }
      if (o.company_ids.includes(3)) {
        o.cia += 'ET,';
      }
      if (o.company_ids.includes(4)) {
        o.cia += 'GAT,';
      }
      if (o.company_ids.includes(5)) {
        o.cia += 'GY,';
      }
      o.cia = o.cia.replace(/^,*/, '').replace(/,*$/, '');
      if (o.cia === '') {
        o.cia = null;
      }
    });
  }

  getAllOptionalsCity(city_id) {
    this.addNewOpt = false;
    this.loadingOpts = true;
    this.optionals_city = [];
    const data = {
      'user_id': this.currentUser.id,
      'city_id': city_id
    };
    // console.log(data);
    this.httpOptSvc.getOptionalsCity(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Insert all cities
            this.optionals_city = res.results.optionals_city;
            this.updateOptsCity();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingOpts = false;
        },
        error => {
          this.loadingOpts = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting activities in city');
        });
  }

  // Add new optional
  onAddNewOpt() {
    if (this.loadingCities) {
      return;
    }
    if (!this.city_id) {
      this.snackSvc.openSnackBar('Select city first');
      return;
    }
    const dialogRef = this.dialog.open(AddOptionalComponent, {
      minWidth: '80vw',
      maxWidth: '90vw',
      data: {
        'autoFocus': true,
        'req_id': this.currentUser.id,
        'city_id': this.city_id,
        'company_id': (this.currentUser.role >= 6 ? 2 : (this.currentUser.role === 5 ? 3 : 1)),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const opt = result['optional'];
        if (this.city_id === opt.city_id) {
          this.optionals_city.push(opt);
          this.updateOptsCity();
        }
        // this.onSelectionOptCity({target: {value: this.city_edit_id}});
      } else {
        // console.log('Didnt add opt');
      }
    });
  }

  onAddCity() {
    const dialogRef = this.dialog.open(AddCityComponent, {
      data: {'autoFocus': true}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'confirmed': true,
          'name': this.textTransformService.titleCase(result['name']),
          'country': this.textTransformService.titleCase(result['country']),
        };
        this.httpCitySvc.saveCity(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                this.cities.push(res.results.city);
                localStorage.setItem('cities', JSON.stringify(this.cities));
                this.city_edit_id = res.results.city.id;
                this.city_id = res.results.city.id;
                this.cities.sort(function (a, b) {
                  return a.name[0].localeCompare(b.name[0]);
                });
                const event = {target: {value: res.results.city.id}};
                this.onSelectionOptCity(event, true);
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
            });
      } else {
        this.city_id = null;
        this.city_edit_id = null;
      }
    });
  }

  onEditOpt(opt) {
    opt.edit_fields = '';
    this.idxOptE.push(opt.id);
  }

  onSelectionCiaChange(event, opt: Optional) {
    opt.company_ids = event.value;
    opt.companies = ',' + opt.company_ids.join(',') + ',';
    opt.edit_fields = opt.edit_fields + ',companies';
    if (opt.companies === ',,') {
      opt.companies = null;
    }
  }

  setDefaultPic(opt) {
    this.flgSvc.setFlag(this.currentUser.id, this.router.url,
      'Component (' + opt.id + ') "' + opt.name + '" failed loading image',
      'Failed image path: ' + opt.image);
    this.onUpdateOpt('del_image', opt);
  }

  onUpdateOpt(txt, opt: Optional) {
    const data = {
      'city_id': opt.city_id,
    };
    const val = HttpOptionalService.validateInputOptional(opt);
    if (val) {
      this.snackSvc.openSnackBar(val);
      return;
    }
    if (txt === 'all') {
      const fields = opt.edit_fields?.replace(/(^,)|(,$)/g, '').split(',');
      for (const f of fields) {
        data[f] = opt[f];
      }
      if (opt['access']) {
        data['access'] = opt['access'].replace(/,*\s*$/, '') + ',';
      }
    } else if (txt === 'del_image') {
      opt['image'] = null;
      data['image'] = null;
      data['del_image'] = true;
    } else {
      data[txt] = opt[txt].toString().trim();
    }
    data['user_id'] = this.currentUser.id;
    // console.log(data);
    this.httpOptSvc.updateOptional(opt.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Insert optional
            opt = res.results.optional;
            const updateIdx = this.optionals_city.findIndex(o => '' + o.id === '' + opt.id);
            if (updateIdx >= 0) {
              this.optionals_city[updateIdx] = opt;
            }
            this.updateOptsCity();
            const removeIdx = this.idxOptE.findIndex(i => '' + i === '' + opt.id);
            this.idxOptE.splice(removeIdx, 1);
            opt.edit_fields = '';
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              const removeIdx = this.idxOptE.findIndex(i => '' + i === '' + opt.id);
              this.idxOptE.splice(removeIdx, 1);
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error updating activity');
        });
  }

  uploadOptionalImage(event, opt) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      opt.image = null;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('optional_id', '' + opt.id);
              this.httpOptSvc.uploadOptImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      opt.image = res.results.optional.image;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    console.log(error);
                  });

            }, (err: NgxPicaErrorInterface) => {
              console.log('image not resized');
              console.log(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          console.log('image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  onDeleteOpt(opt) {
    const snackbarRef = this.snackSvc.openSnackBar('Delete activity?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          const data = {
            'req_id': this.currentUser.id,
            'optional_id': opt.id,
          };
          this.httpOptSvc.deleteOptional(data)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  // Remove activity
                  const removeIdx = this.idxOptE.findIndex(i => '' + i === '' + opt.id);
                  this.idxOptE.splice(removeIdx, 1);
                  const remIdx = this.optionals_city.findIndex(i => '' + i.id === '' + opt.id);
                  this.optionals_city.splice(remIdx, 1);
                  this.getAllOptionalsCity(opt.city_id);
                } else {
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    this.snackSvc.openSnackBar(res.message.toString());
                    console.log(res.message.toString());
                  }
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('Error deleting activity');
              });
        }
      });
  }

  onDeleteTourOpt(topt: TourOptional, opt: Optional) {
    const snackbarRef = this.snackSvc.openSnackBar('Delete tour activity?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.httpTourOptSvc.deleteTourOptional(this.currentUser.id, topt.id, 'true')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  // Remove tour activity
                  const remIdx = opt.tour_optional.findIndex(i => '' + i.id === '' + topt.id);
                  opt.tour_optional.splice(remIdx, 1);
                } else {
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    this.snackSvc.openSnackBar(res.message.toString());
                    console.log(res.message.toString());
                  }
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('Error deleting activity');
              });
        }
      });
  }

  goComp(c_id) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['admin', 'comps'], {queryParams: {c: c_id}}));
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
