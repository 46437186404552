import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpIncidentService {

  private endpoints: {
    allIncidents: string,
    crudIncident: string,
    incidentTypes: string,
    incidentsUser: string
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allIncidents: environment.apiUrl + 'incidents/',
      crudIncident: environment.apiUrl + 'incident/',
      incidentTypes: environment.apiUrl + 'incident_types/',
      incidentsUser: environment.apiUrl + 'incidents_user/',
    };
  }


  // INCIDENTS

  /* gets all incidents */
  getAllIncidentTypes() {
    return this.http.get<any>(this.endpoints.incidentTypes);
  }

  /* gets all incidents */
  getAllIncidents() {
    return this.http.get<any>(this.endpoints.allIncidents);
  }

  /* gets all user incidents */
  getAllIncidentsUser(user_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.incidentsUser + user_id);
  }

  /* Get incident */
  getIncident(inc_id: number): Observable<any> {
    const params = new HttpParams().set('inc_id', inc_id.toString());
    return this.http.get<any>(this.endpoints.crudIncident, {params: params});
  }

  /* Creates a new incident */
  createIncident(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudIncident, data);
  }

  /* Update Incident */
  updateIncident(inc_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudIncident + inc_id, paramsToUpdate);
  }

  /* Delete incident */
  deleteIncident(incident_id: number, req_id: number, force?: string): Observable<any> {
    let params = new HttpParams()
      .set('incident_id', incident_id.toString())
      .set('req_id', req_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudIncident, {params: params});
  }

}
