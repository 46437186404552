import {NgModule} from '@angular/core';
import {HttpSessionService} from './http/http-session.service';
import {HttpUserService} from './http/http-user.service';
import {AuthGuard} from '../guards/auth.guard';
import {DatePipe} from '@angular/common';

@NgModule({
  providers: [
    AuthGuard,
    HttpSessionService,
    HttpUserService,
    DatePipe
  ],
  imports: []
})
export class ServiceModule {
}
