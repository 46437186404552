import {Injectable} from '@angular/core';
import {HttpFlagService} from '../http/http-flag.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlagService {

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpflagService: HttpFlagService,
  ) {
  }

  setFlag(user: number, url: string, problem: string, solution: string) {
    const params = {
      'user_id': user,
      'flag_url': url,
      'problem': problem.substring(0, 199),
      'solution': solution.substring(0, 199),
    };
    this.httpflagService.createFlag(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res1 => {
          console.log(res1);
        },
        error => {
          console.log(error);
        });
  }
}
