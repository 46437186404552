import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {OutlookService} from '../../../../shared/services/session/outlook.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Tour} from '../../../../shared/models/tour.model';
import {Components} from '../../../../shared/models/components.model';
import {Router} from '@angular/router';

export interface DialogData {
  tour: Tour;
  components: Components[];
  user_id: number;
  secs_undo: number;
  type: string;
}

@Component({
  selector: 'app-update-pax',
  templateUrl: './update-pax.component.html',
  styleUrls: ['./update-pax.component.scss']
})
export class UpdatePaxComponent {

  reason: string;
  type_txt: string;
  n_update: number;
  updateLoading: boolean;
  comp_ids: number[] = [];
  err_ids: number[] = [];
  allComplete = false;
  snackbarRef: any = null;

  private onDestroy$ = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<UpdatePaxComponent>,
              public dialog: MatDialog,
              private router: Router,
              private outlookSvc: OutlookService,
              private snackSvc: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.comp_ids = [];
    this.data.components.map(c => {
      c.checked = false;
      if (c.recipients) {
        c.recipients = c.recipients.replace(/,/g, ', ');
      }
    });
    if (this.data.type === 'lated') {
      this.type_txt = 'late added';
      this.n_update = this.data.tour.nlated;
      this.data.components.map(c => c.updated = c.confirm_notes && c.confirm_notes.includes('Updated ' + this.n_update + ' add'));
    } else if (this.data.type === 'caxed') {
      this.type_txt = 'cancelled';
      this.data.components.map(c => c.updated = c.confirm_notes && c.confirm_notes.includes('Updated ' + this.n_update + ' cax'));
      this.n_update = this.data.tour.ncaxed;
    } else if (this.data.type === 'diets') {
      this.type_txt = 'diets';
      this.n_update = null;
      this.data.components.map(c => c.updated = c.confirm_notes && c.confirm_notes.includes('Updated diets'));
    }
  }

  info() {
    console.log('data', this.data);
    console.log('comps', this.comp_ids);
  }

  updateAllComplete(comp_id, event) {
    this.allComplete = this.data.components.every(c => c.checked);
    const idIDx = this.comp_ids.findIndex(c => c === comp_id);
    if (event) {
      this.comp_ids.push(comp_id);
    } else {
      this.comp_ids.splice(idIDx, 1);
    }
  }

  someComplete(): boolean {
    if (!this.data.components) {
      return false;
    }
    return this.data.components.filter(c => c.checked).length > 0 && !this.allComplete;
  }

  selectAll(event) {
    this.comp_ids = [];
    this.allComplete = event.checked;
    this.data.components.forEach(c => c.checked = event.checked);
    if (event.checked) {
      this.data.components.map(c => this.comp_ids.push(c.id));
    }
  }

  submit(): void {
    if (this.comp_ids.length < 1) {
      this.snackSvc.openSnackBar('Select at least one component');
      return;
    }
    this.onSendUpdateMail(this.data.type);
  }

  clickComp(comp) {
    if (this.updateLoading) {
      return;
    }
    comp.checked = !comp.checked;
    this.updateAllComplete(comp.id, comp.checked);
  }

  goToPax() {
    if (this.updateLoading) {
      return;
    }
    this.router.navigate(['pax', this.data.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
    this.dialogRef.close();
  }

  onSendUpdateMail(type) {
    this.updateLoading = true;
    // Show snackbar to undo Send
    const supp = (this.comp_ids.length === 1) ? ' supplier' : ' suppliers';
    this.snackbarRef = this.snackSvc.openSnackBar('Update ' + this.comp_ids.length + supp, 'Send', this.data.secs_undo);
    this.snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          if (this.data.type === 'lated' || this.data.type === 'caxed') {
            this.sendPAX(type);
          } else if (this.data.type === 'diets') {
            this.sendDiets(type);
          } else {
            this.snackSvc.openSnackBar('Not contemplated');
          }
        } else {
          setTimeout(() => {
            this.updateLoading = false;
          }, 1);
        }
      });
  }

  sendPAX(type) {
    const params = {
      'req_id': this.data.user_id,
      'session_id': localStorage.getItem('session_id'),
      'tour_id': this.data.tour.id,
      'type': type,
      'list': this.comp_ids,
    };
    this.outlookSvc.updatePaxMessage(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.tour.nlated = 0;
            this.data.tour.ncaxed = 0;
            if (res.status === 200) {
              this.snackSvc.openSnackBar('Messages sent');
              this.close(this.comp_ids);
            } else if (res.status === 201) {
              this.comp_ids = [];
              this.err_ids = res.results.errors;
              this.snackSvc.openSnackBar('Pax updates sent with errors');
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.updateLoading = false;
        },
        err => {
          console.log(err);
          this.updateLoading = false;
          this.snackSvc.openSnackBar('Error sending pax updates');
        });
  }

  sendDiets(type) {
    const params = {
      'req_id': this.data.user_id,
      'session_id': localStorage.getItem('session_id'),
      'tour_id': this.data.tour.id,
      'type': type,
      'list': this.comp_ids,
    };
    this.outlookSvc.updateDietsMessage(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (res.status === 200) {
              this.snackSvc.openSnackBar('Messages sent');
              this.close(this.comp_ids);
            } else if (res.status === 201) {
              this.comp_ids = [];
              this.err_ids = res.results.errors;
              this.snackSvc.openSnackBar('Dietary updates sent with errors');
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.updateLoading = false;
        },
        err => {
          console.log(err);
          this.updateLoading = false;
          this.snackSvc.openSnackBar('Error sending dietary updates');
        });
  }

  close(reason) {
    if (reason === 'close' && this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
    this.reason = reason;
    this.dialogRef.close(reason);
  }

}
