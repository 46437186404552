import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpSessionService} from '../../../shared/services/http/http-session.service';

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.scss']
})
export class ForgotpwdComponent implements OnInit {
  fgtpwdForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  errorMessage: string;
  infoMessage: string;
  url: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthenticationService,
    private httpSession: HttpSessionService,
  ) {
    // redirect to main if already logged in
    if (this.authSvc.currentUserValue) {
      this.router.navigate(['tours']);
    }
  }

  ngOnInit() {
    this.fgtpwdForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

    // get return url from route parameters or default to 'tours'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'tours';
    this.url = window.location.href.split('password')[0];
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.fgtpwdForm.controls;
  }

  onSubmit() {
    this.errorMessage = null;
    this.infoMessage = null;
    this.submitted = true;
    // stop here if form is invalid
    if (this.fgtpwdForm.invalid) {
      return;
    }
    this.loading = true;
    this.sendTokenAsync();
  }

  onGoToLogin() {
    this.router.navigate(['login']);
  }

  async sendTokenAsync() {
    const res = await this.httpSession.requestPasswordToken({email: this.f.email.value, url: this.url});
    this.loading = false;
    console.log(res);
    if (res.status === 200) {
      this.infoMessage = res.message.toString();
    } else {
      if (res.results) {
        this.errorMessage = res.results[Object.keys(res.results)[0]].toString();
      } else {
        this.errorMessage = res.message.toString();
      }
    }
  }
}
