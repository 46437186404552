import {FreetimeType} from './freetime-type.model';
import {Gsstype} from './gsstype.model';
import {Itinerary} from './itinerary.model';
import {Image} from './image.model';

export class City {
  id: number;
  name: string;
  country: string;
  description: number;
  image: string;
  gss_image: string;
  language: string;
  currency: string;
  confirmed: boolean;
  local_transport: boolean;
  city_map: boolean;
  population: string;
  timezone: string;
  lat: string;
  lon: string;
  gss_type_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  gss_type?: Gsstype;
  images?: Image[];
  itinerary?: Itinerary[];
  components_count: number;
  optionals_count: number;
  activities_count: number;
  freetime_types?: FreetimeType[];
  img_loading?: boolean;
  gss_loading?: boolean;
  other_loading?: boolean;
  img_file?: string;
  sub_desc?: string;
  files?: any[];

  constructor(city?: any) {
    this.name = '';
    this.country = '';
    this.gss_type_id = 1;
    this.local_transport = false;
    this.gss_type = new Gsstype();
    if (city) {
      this.id = city.id;
      this.name = city.name;
      this.country = city.country;
      this.gss_type_id = city.gss_type_id;
      this.local_transport = false;
      this.gss_type = city.gss_type ? city.gss_type : new Gsstype(city.gss_type_id);
    }
  }
}
