import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {OutlookService} from '../../../../shared/services/session/outlook.service';
import {Subject} from 'rxjs';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {Components} from '../../../../shared/models/components.model';
import {Itinerary} from '../../../../shared/models/itinerary.model';
import {HttpRoomService} from '../../../../shared/services/http/http-room.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {Activetour} from '../../../../shared/models/activetour.model';
import {User} from '../../../../shared/models/user.model';

export interface DialogDataSendRooming {
  reason: string;
  compURL: string;
  user: User;
  itin: Itinerary;
  itidx: number;
  comp: Components;
  all_pax: boolean;
}

@Component({
  selector: 'app-send-rooming',
  templateUrl: './send-rooming.component.html',
  styleUrls: ['./send-rooming.component.scss']
})
export class SendRoomingComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  loading = false;
  comIdx: number;
  reason: string;
  message: any = [];
  errors: any = [];
  pdfBlob: any;
  excelBlob: any;
  pdf_at: string;
  excel_at: string;
  file_at: string;

  recipients: string[] = [];
  show_recs = false;
  retrySend = false;
  sendingMail = false;
  sentMail = false;
  creatingDraft = false;
  createdDraft = false;
  creatingPDF = false;
  creatingExcel = false;
  createdPDF = false;
  createdExcel = false;
  attachingPDF = false;
  attachedPDF = false;
  attachingExcel = false;
  attachedExcel = false;
  attachingFile = false;
  attachedFile = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService,
    private router: Router,
    private outlookSvc: OutlookService,
    private httpRoom: HttpRoomService,
    private flagService: FlagService,
    public dialogRef: MatDialogRef<SendRoomingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataSendRooming
  ) {
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
  }

  ngOnInit() {
    this.comIdx = this.activeTour.components.findIndex(it => '' + it.id === '' + this.data.comp.id);
    this.data.comp = this.activeTour.components[this.comIdx];
    this.recipients = this.data.comp.recipients?.split(',').map(i => i.trim());
    if (this.data.comp.description === 'rooming_sent') {
      this.errors.push(
        {
          'code': 0,
          'conded': false,
          'conding': false,
          'txt': 'Rooming already sent',
          'action': 'reSend',
          'tooltip': 'Retry',
        }
      );
      return;
    }
    if (!this.data.comp.sent_at) {
      this.errors.push(
        {
          'code': 1,
          'conded': this.data.comp.sent_at,
          'conding': this.retrySend,
          'txt': 'Send the confirmation email first',
          'action': 'onGoToComp',
          'tooltip': 'Go',
        }
      );
      return;
    }
    if (!this.data.all_pax) {
      this.errors.push(
        {
          'code': 6,
          'conded': false,
          'conding': false,
          'txt': 'Not all PAX in rooms',
          'action': 'ignorePAX',
          'tooltip': 'Ignore',
        }
      );
      return;
    }
    // reset
    this.createdDraft = false;
    this.createdPDF = false;
    this.attachedPDF = false;
    this.attachedExcel = false;
    if (this.errors.length === 0) {
      this.runChecklist();
    }
  }

  runChecklist() {
    if (this.data.comp.description) {
      this.createdDraft = true;
      const msgId = this.data.comp.description;
      this.message.webLink = 'https://outlook.office365.com/owa/?ItemID=' + msgId + '&exvsurl=1&viewmodel=ReadMessageItem';
      this.createPDF(this.data.comp.description);
    } else {
      this.createDraft();
    }
  }

  updRecs(e) {
    this.data.comp.recipients = e;
    // Remove last comma if present
    if (this.data.comp.recipients) {
      this.data.comp.recipients = this.data.comp.recipients.replace(/,\s*$/, '').replace(/\s/g, '');
      this.activeTour.components[this.comIdx]['recipients'] = this.data.comp.recipients;
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.recipients = this.data.comp.recipients.split(',');
    }
  }

  // DRAFT

  createDraft() {
    this.createdDraft = false;
    this.creatingPDF = false;
    this.creatingExcel = false;
    this.createdPDF = false;
    this.createdExcel = false;
    this.attachingPDF = false;
    this.attachedPDF = false;
    this.attachingExcel = false;
    this.attachedExcel = false;
    this.attachingFile = false;
    this.attachedFile = false;
    this.creatingDraft = true;
    const data = {
      'user_id': this.data.user.id,
      'component_id': this.data.comp.id,
      'session_id': localStorage.getItem('session_id'),
      'folder_id': localStorage.getItem('folder_id'),
      'recipients': this.recipients.join(','),
    };
    // console.log(data);
    this.outlookSvc.writeHotelRooming(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.createdDraft = true;
            this.creatingDraft = false;
            this.errors = this.errors.filter(obj => obj.code !== 2);
            this.message = res.results.message;
            this.data.comp = res.results.component;
            // Update field in memory
            this.activeTour.components[this.comIdx]['description'] = this.message.id;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.createPDF(this.message.id);
          } else {
            this.creatingDraft = false;
            const code = res.message;
            this.errors.push(
              {
                'code': 2,
                'conded': this.createdDraft,
                'conding': this.creatingDraft,
                'txt': code,
                'action': 'createDraft',
                'tooltip': 'Retry',
              }
            );
            this.flagService.setFlag(this.data.user.id, this.router.url, code, '' + res.message);
          }
        },
        err => {
          console.log(err);
          this.creatingDraft = false;
          this.errors.push(
            {
              'code': 2,
              'conded': this.createdDraft,
              'conding': this.creatingDraft,
              'txt': 'Error creating draft',
              'action': 'createDraft',
              'tooltip': 'Retry',
            }
          );
          this.flagService.setFlag(this.data.user.id, this.router.url, 'Error creating draft', '' + err);
        });
  }

  // PDF

  createPDF(msg_id) {
    this.creatingPDF = true;
    this.httpRoom.downloadRoomingPdf(this.data.comp.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        x => {
          this.pdfBlob = new Blob([x], {type: 'application/pdf'});
          this.creatingPDF = false;
          this.createdPDF = true;
          this.errors = this.errors.filter(obj => obj.code !== 3);
          this.attachPDF(msg_id, this.pdfBlob);
        },
        err => {
          console.log(err);
          this.creatingPDF = false;
          this.errors.push(
            {
              'code': 3,
              'conded': this.createdPDF,
              'conding': this.creatingPDF,
              'txt': 'Error creating pdf',
              'action': 'createPDF',
              'tooltip': 'Retry',
            }
          );
          this.flagService.setFlag(this.data.user.id, this.router.url, 'Error creating rooming pdf', '' + err);
        });
  }

  async attachPDF(msg_id, data) {
    this.attachingPDF = true;
    const codeprod = this.activeTour.tour.code + '.' + this.activeTour.tour.prodid;
    const name = codeprod + '-Hotel_' + (this.data.itidx + 1) + this.data.itin.city.name + '.pdf';
    const formData: FormData = new FormData();
    formData.append('session_id', localStorage.getItem('session_id'));
    formData.append('component_id', '' + this.data.comp.id);
    formData.append('message_id', '' + msg_id);
    formData.append('req_id', '' + this.data.user.id);
    formData.append('file', data, name);
    const res = await this.outlookSvc.attachFile(formData);
    console.log(res);
    if (res.status < 400) {
      this.attachingPDF = false;
      this.attachedPDF = true;
      this.errors = this.errors.filter(obj => obj.code !== 4);
      this.pdf_at = res.results.attachment.id;
    } else {
      this.attachingPDF = false;
      this.attachedPDF = false;
      this.errors.push(
        {
          'code': 4,
          'conded': this.attachedPDF,
          'conding': this.attachingPDF,
          'txt': 'Error attaching PDF',
          'action': 'attachPDF',
          'arg1': msg_id,
          'arg2': data,
          'tooltip': 'Retry',
        }
      );
      if (res.results) {
        this.flagService.setFlag(this.data.user.id, this.router.url, 'Error attaching pdf',
          res.results[Object.keys(res.results)[0]].toString());
      } else {
        this.flagService.setFlag(this.data.user.id, this.router.url, 'Error attaching pdf',
          res.message ? res.message.toString() : res);
      }
    }
  }

  downloadPDF() {
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(this.pdfBlob);
    const link = document.createElement('a');
    link.href = data;
    // Add the number of city to name
    const codeprod = this.activeTour.tour.code + '.' + this.activeTour.tour.prodid;
    const itidx = this.activeTour.itinerary.findIndex(it => '' + it.id === '' + this.data.comp.itinerary_id);
    const itin = this.activeTour.itinerary[itidx];
    // const date = formatDate(new Date(itin.check_in), 'ddLLL', 'en');
    link.download = codeprod + '-Hotel_' + (itidx + 1) + itin.city.name + '.pdf';
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  // FILE

  async onAttachFile(files: FileList) {
    this.attachingFile = true;
    const fileToUpload: File = files.item(0);
    if (files.length > 0) {
      const formData: FormData = new FormData();
      formData.append('session_id', localStorage.getItem('session_id'));
      formData.append('component_id', '' + this.data.comp.id);
      formData.append('message_id', '' + this.message.id);
      formData.append('req_id', '' + this.data.user.id);
      formData.append('file', fileToUpload, fileToUpload.name);
      const res = await this.outlookSvc.attachFile(formData);
      console.log(res);
      if (res.status < 400) {
        this.attachingFile = false;
        this.attachedFile = true;
        this.file_at = res.results.attachment.id;
      } else {
        this.attachingFile = false;
        this.attachedFile = false;
        this.snackSvc.resultsElse(res);
        this.errors.push(
          {
            'code': 4,
            'conded': this.attachedFile,
            'conding': this.attachingFile,
            'txt': 'Error attaching File',
            'action': 'onAttachFile',
            'arg1': this.data.comp.description,
            'arg2': files,
            'tooltip': 'Retry',
          }
        );
      }
    } else {
      this.attachingFile = false;
      this.attachedFile = false;
    }
  }

  // REMOVE ATTACHMENT

  outlookRemoveAttachment(type, attachment_id: string) {
    if (type === 'pdf') {
      this.attachingPDF = true;
      this.attachedPDF = false;
    } else if (type === 'xlsx') {
      this.attachingExcel = true;
      this.attachedExcel = false;
    } else {
      this.attachingFile = true;
      this.attachedFile = false;
    }
    const data = {
      'req_id': this.data.user.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.data.comp.id,
      'message_id': this.message.id,
      'attachment_id': attachment_id,
    };
    // console.log('data', data);
    this.outlookSvc.removeAttachment(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (type === 'pdf') {
              this.attachingPDF = false;
              this.attachedPDF = false;
            } else if (type === 'xlsx') {
              this.attachingExcel = false;
              this.attachedExcel = false;
            } else {
              this.attachingFile = false;
              this.attachedFile = false;
            }
          } else {
            if (type === 'pdf') {
              this.attachingPDF = false;
              this.attachedPDF = true;
            } else if (type === 'xlsx') {
              this.attachingExcel = false;
              this.attachedExcel = true;
            } else {
              this.attachingFile = false;
              this.attachedFile = true;
            }
            this.snackSvc.resultsElse(res);
          }
        },
        err => {
          if (type === 'pdf') {
            this.attachingPDF = false;
            this.attachedPDF = true;
          } else if (type === 'xlsx') {
            this.attachingExcel = false;
            this.attachedExcel = true;
          } else {
            this.attachingFile = false;
            this.attachedFile = true;
          }
          console.log(err);
        });
  }

  // SEND
  sendMail() {
    if (!this.data.comp.recipients) {
      this.snackSvc.openSnackBar('Recipients needed');
      return;
    }
    if (!this.data.comp.description) {
      this.snackSvc.openSnackBar('Error. Contact support');
      this.flagService.setFlag(this.data.user.id, this.router.url, 'Send Rooming Mail has no message id (description)',
        'Component [' + this.data.comp.id + '] ' + this.data.comp.name);
      return;
    }
    this.sendingMail = true;
    const data = {
      'req_id': this.data.user.id,
      'component_id': this.data.comp.id,
      'session_id': localStorage.getItem('session_id'),
      'message_id': this.data.comp.description,
      'folder_id': localStorage.getItem('folder_id'),
      'recipients': this.recipients.join(','),
    };
    // console.log(data)
    this.outlookSvc.sendHotelRooming(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.sendingMail = false;
          if (res.status === 200) {
            this.sentMail = true;
            // Update field in memory
            this.data.comp.description = 'rooming_sent';
            this.activeTour.components[this.comIdx]['description'] = 'rooming_sent';
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.close('sent');
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        err => {
          this.sendingMail = false;
          this.sentMail = false;
          console.log(err);
          this.errors.push(
            {
              'code': 5,
              'conded': this.sentMail,
              'conding': this.sendingMail,
              'txt': 'Error sending email',
              'action': 'sendMail',
              'tooltip': 'Retry',
            }
          );
          this.flagService.setFlag(this.data.user.id, this.router.url, 'Error sending email', '' + err);
        });
  }

  mngErr(action, arg1?, arg2?) {
    if (action === 'onGoToComp') {
      this.errors = this.errors.filter(obj => obj.code !== 1);
      this.onGoToComp();
    } else if (action === 'createDraft') {
      this.errors = this.errors.filter(obj => obj.code !== 2);
      this.createDraft();
    } else if (action === 'createPDF') {
      this.errors = this.errors.filter(obj => obj.code !== 3);
      this.createPDF(arg1);
    } else if (action === 'attachPDF') {
      this.errors = this.errors.filter(obj => obj.code !== 4);
      this.attachPDF(arg1, arg2);
    } else if (action === 'reSend') {
      this.errors = [];
      this.data.comp.description = null;
      this.activeTour.components[this.comIdx]['description'] = null;
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.createDraft();
    } else if (action === 'ignorePAX') {
      this.errors = this.errors.filter(obj => obj.code !== 6);
      this.createDraft();
    }
  }

  onGoToComp() {
    this.retrySend = true;
    this.errors = this.errors.filter(obj => obj.code !== 1);
    this.close('close');
    this.router.navigate([this.data.compURL]);
  }

  goToOutlook() {
    window.open(this.message.webLink, '_blank');
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
