import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpImageService {
  private endpoints: {
    allImages: string,
    crudImage: string,
    imagesCity: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allImages: environment.apiUrl + 'images/',
      crudImage: environment.apiUrl + 'image/',
      imagesCity: environment.apiUrl + 'images_city/',
    };
  }

  // CITY

  /* Gets all */
  allImages(): Observable<any> {
    const params = new HttpParams()
      .set('trashed', 'ok');
    return this.http.get<any>(this.endpoints.allImages, {params: params});
  }

  /* Gets all */
  async getAllImages() {
    return await this.http.get<any>(this.endpoints.allImages).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Gets  */
  getImage(image_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudImage + image_id);
  }

  /* Get  */
  getImagesCity(city_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.imagesCity + city_id);
  }

  /* Gets  */
  updateImage(image_id: number, data: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudImage + image_id, data);
  }

  /* Stores */
  storeImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.crudImage, data);
  }

  /* deletes other image city */
  deleteImage(image_id: any, req_id: any, force?: string): Observable<any> {
    let params = new HttpParams()
      .set('image_id', image_id.toString())
      .set('req_id', req_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudImage, {params: params});
  }
}
