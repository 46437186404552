import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpTourOptionalService {

  private endpoints: {
    tourOptionals: string,
    tourOptional: string,
    tourOpt: string,
    tourOptPax: string,
    tourOptPay: string,
    paymentTop: string
    topsForPax: string
    refundTOP: string
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      tourOptionals: environment.apiUrl + 'tour_optionals/',
      tourOptional: environment.apiUrl + 'tour_optional/',
      tourOpt: environment.apiUrl + 'tour_opt/',
      tourOptPax: environment.apiUrl + 'tour_opt_pax/',
      tourOptPay: environment.apiUrl + 'tour_opt_pay/',
      paymentTop: environment.apiUrl + 'send_pay_top/',
      topsForPax: environment.apiUrl + 'tops_for_pax/',
      refundTOP: environment.apiUrl + 'refund_top/'
    };
  }


  // Get All Tour Activities
  getTourOptionals(tour_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.tourOptionals, {params: params});
  }

  // Get one Tour Optional
  getTourOptional(to_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.tourOptional + to_id);
  }

  // Create Tour Optional
  createTourOptional(data): Observable<any> {
    return this.http.post<any>(this.endpoints.tourOptional, data);
  }

  // Update one Tour Optional
  updateTourOptional(to_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.tourOptional + to_id, paramsToUpdate);
  }

  // Delete one Tour Optional
  deleteTourOptional(req_id: number, to_id: number, force: string): Observable<any> {
    let params = new HttpParams()
      .set('req_id', req_id.toString())
      .set('to_id', to_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.tourOptional, {params});
  }

  // Create Tour Opt Pax (SIGN UP TO OPTIONAL)
  createTourOptPax(data): Observable<any> {
    return this.http.post<any>(this.endpoints.tourOptPax, data);
  }

  // Get one Tour Optional
  getTourOpt(to_id: number, user_id: number): Observable<any> {
    const params = new HttpParams()
      .set('to_id', to_id.toString())
      .set('user_id', user_id.toString());
    return this.http.get<any>(this.endpoints.tourOpt, {params: params});
  }

  // Get one Tour Optional PAX
  getTourOptionalPax(top_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.tourOptPax + top_id);
  }

  /* Gets all possible optionals for a user */
  getTourPax(user_id: number, tour_id: number): Observable<any> {
    const params = new HttpParams()
      .set('user_id', user_id.toString())
      .set('tour_id', tour_id.toString());
    return this.http.get<any>(this.endpoints.topsForPax, {params: params});
  }

  // Get one Tour Optional Payment
  getTourOptionalPay(top_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.tourOptPay + top_id);
  }

  // Send Payment Link to TP
  sendTOPaymentLink(data): Observable<any> {
    return this.http.post<any>(this.endpoints.paymentTop, data);
  }

  // Refund payment of top
  refundTOPpay(data): Observable<any> {
    return this.http.post<any>(this.endpoints.refundTOP, data);
  }

  // Update one Tour Optional
  updateTourOptionalPax(top_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.tourOptPax + top_id, paramsToUpdate);
  }

  /* Deletes a Tour Optional Pax top_id */
  deleteTourOptPax(tp_id: number, top_id: number, app: number, silent: number, user_id?: number): Observable<any> {
    let params = new HttpParams()
      .set('tour_optional_id', top_id.toString())
      .set('app', app)
      .set('silent', silent);
    if (user_id) {
      params = params.set('user_id', user_id.toString());
    }
    if (tp_id) {
      params = params.set('tour_pax_id', tp_id.toString());
    }
    return this.http.delete<any>(this.endpoints.tourOptPax, {params});
  }

}
