import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  previousUrl: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.previousUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }
}
