import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {Hotel} from '../../../../shared/models/hotel.model';
import {HttpHotelService} from '../../../../shared/services/http/http-hotel.service';
import {AddHotelComponent} from '../../../components/hotel/add-hotel/add-hotel.component';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {City} from '../../../../shared/models/city.model';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-adm-hotels',
  templateUrl: './adm-hotels.component.html',
  styleUrls: ['./adm-hotels.component.scss']
})
export class AdmHotelsComponent implements OnInit, OnDestroy {
  currentUser: User;
  hotels: Hotel[] = [];
  hotelsFilt: Hotel[] = [];
  cities: City[] = [];

  filter: string;
  sortBy: string;
  baseUrl: string;
  loading = false;
  searchText: string;
  searching = false;
  showSearch = false;

  idxHotEdit: any[] = [];

  private onDestroy$ = new Subject<boolean>();
  @ViewChildren('hotel_target') searchElementHotelsRefs: QueryList<ElementRef>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private httpHotel: HttpHotelService,
    private httpCitySvc: HttpCityService,
    private textTransform: TextTransformService,
    private ngxPicaService: NgxPicaService,
    private ngxPicaImageService: NgxPicaImageService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Escape') {
      this.idxHotEdit = [];
    }
  }

  ngOnInit(): void {
    this.sortBy = null;
    this.baseUrl = environment.baseUrl;
    this.getAllHotels();
    this.getAllCities();
  }

  onEditHotel(hotel) {
    if (!hotel.deleted_at) {
      this.idxHotEdit.push(hotel.id);
    }
  }

  onOkHotel(hotel_id) {
    const removeIdx = this.idxHotEdit.findIndex(i => '' + i === '' + hotel_id);
    this.idxHotEdit.splice(removeIdx, 1);
  }

  getAllHotels() {
    this.loading = true;
    this.httpHotel.getAllHotels(true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Get all hotels + trashed
            this.hotels = res.results.hotels;
            this.sortBy = null;
              this.filterBy();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting hotels');
        });
  }

  getAllCities() {
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Insert all cities
            this.cities = res.results.cities;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  orderBy(txt) {
    if (this.sortBy === txt) {
      this.sortBy = null;
      this.hotelsFilt.sort(function (a, b) {
        return a.city.country.localeCompare(b.city.country) || a.city.name.localeCompare(b.city.name) || a.name.localeCompare(b.name);
      });
    } else {
      this.hotelsFilt = this.hotelsFilt.sort((a, b) => a[txt] < b[txt] ? 1 : a[txt] === b[txt] ? 0 : -1);
      this.sortBy = txt;
    }
  }

  filterBy(role?) {
    if (this.filter === role) {
      this.filter = null;
      this.hotelsFilt = this.hotels;
    } else {
      if (role === 'deleted') {
        this.hotelsFilt = this.hotels.filter(h => h.deleted_at);
        this.filter = role;
      } else if (role === 'image') {
        this.hotelsFilt = this.hotels.filter(h => !h.image);
        this.filter = role;
      } else if (role === 'url') {
        this.hotelsFilt = this.hotels.filter(h => !h.url);
        this.filter = role;
      } else if (role === 'confirmed') {
        this.hotelsFilt = this.hotels.filter(h => !h.confirmed);
        this.filter = role;
      } else {
        this.filter = null;
        this.hotelsFilt = this.hotels;
      }
    }
  }

  addNewHotel() {
    const dialogRef = this.dialog.open(AddHotelComponent, {
      data: {'autoFocus': true}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'name': this.textTransform.titleCase(result['name']),
          'address': this.textTransform.titleCase(result['address']),
          'city_id': result['city_id'],
          'url': result['url'],
          'confirmed': true,
        };
        // console.log(params);
        this.httpHotel.createHotel(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const hotel = new Hotel(res.results.hotel);
                const HotelIdx = this.hotelsFilt.findIndex(x => x.name === hotel.name && x.address === hotel.address);
                if (HotelIdx < 0) {
                  this.hotelsFilt.push(res.results.hotel);
                } else {
                  this.snackSvc.openSnackBar('Hotel already exists');
                }
                if (this.sortBy) {
                  this.orderBy(this.sortBy);
                } else {
                  this.hotelsFilt.sort(function (a, b) {
                    return a.city.country.localeCompare(b.city.country) || a.city.name.localeCompare(b.city.name) || a.name.localeCompare(b.name);
                  });
                }
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  onUpdateHotel(txt, hotel, e) {
    if (txt !== 'restore' && txt !== 'del_image' && txt !== 'confirmed' && (hotel.deleted_at || !this.idxHotEdit.includes(hotel.id))) {
      return;
    }
    const data = {
      'req_id': this.currentUser.id
    };
    if (txt === 'name') {
      data['name'] = hotel.name;
    } else if (txt === 'address') {
      data['address'] = hotel.address;
    } else if (txt === 'confirmed') {
      data['confirmed'] = 1;
    } else if (txt === 'restore') {
      data['restore'] = true;
      hotel.loading_img = true;
    } else if (txt === 'del_image') {
      hotel.loading_img = true;
      data['image'] = null;
      data['del_image'] = true;
    } else if (txt === 'all') {
      data['name'] = hotel.name;
      data['address'] = hotel.address;
      data['confirmed'] = hotel.confirmed;
      data['city_id'] = hotel.city.id;
      data['url'] = hotel.url;
    } else if (e && e.value) {
      data[txt] = e.value;
    } else if (e && e.target.value) {
      data[txt] = e.target.value;
    }
    // console.log(data);
    this.httpHotel.updateHotel(hotel.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (txt !== 'del_image') {
              const removeIdx = this.idxHotEdit.findIndex(i => '' + i === '' + hotel.id);
              this.idxHotEdit.splice(removeIdx, 1);
            }
            hotel = res.results.hotel;
            const idx = this.hotels.findIndex(it => '' + it.id === '' + hotel.id);
            this.hotels[idx] = res.results.hotel;
            const idx1 = this.hotelsFilt.findIndex(it => '' + it.id === '' + hotel.id);
            this.hotelsFilt[idx1] = res.results.hotel;
            this.filterBy();
            if (!this.sortBy) {
              this.hotelsFilt.sort(function (a, b) {
                return a.city.country.localeCompare(b.city.country) || a.city.name.localeCompare(b.city.name) || a.name.localeCompare(b.name);
              });
            } else {
              this.hotelsFilt = this.hotelsFilt.sort((a, b) => a[this.sortBy] < b[this.sortBy] ? 1 : a[this.sortBy] === b[this.sortBy] ? 0 : -1);
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          hotel.loading_img = false;
        },
        error => {
          hotel.loading_img = false;
          console.log(error);
        });
  }

  uploadHotelImage(hotel: Hotel, event) {
    if (hotel.deleted_at) {
      this.snackSvc.openSnackBar('Hotel deleted');
      return;
    }
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      hotel.loading_img = true;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 0, 800, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('hotel_id', hotel.id);
              formData.append('req_id', '' + this.currentUser.id);
              this.httpHotel.uploadHotelImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    hotel.loading_img = false;
                    if (res.status < 400) {
                      hotel = res.results.hotel;
                      this.hotelsFilt.find(h => '' + h.id === '' + hotel.id).image = hotel.image;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    hotel.loading_img = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('ERROR. Uploading hotel image');
                  });

            }, (err: NgxPicaErrorInterface) => {
              hotel.loading_img = false;
              console.log('hotel image not resized');
              console.log(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          hotel.loading_img = false;
          console.log('hotel image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  openImg(url) {
    window.open(this.baseUrl + url, '_blank');
  }

  openExternalUrl(url) {
    window.open(url, '_blank');
  }

  onDeleteHotel(hotel) {
    if (hotel.itinerary.length > 0) {
      this.snackSvc.openSnackBar('Error. Hotel ' + hotel.id + ' active on tour');
      return;
    }
    hotel.loading_img = true;
    this.httpHotel.deleteHotel(hotel.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          hotel.deleted_at = true;
          const removeIdx = this.idxHotEdit.findIndex(i => '' + i === '' + hotel.id);
          this.idxHotEdit.splice(removeIdx, 1);
        } else {
          this.snackSvc.resultsElse(res);
        }
        hotel.loading_img = false;
      }, error => {
        hotel.loading_img = false;
        this.snackSvc.openSnackBar('Error deleting hotel ' + hotel.id);
        console.log(error);
      });
  }

  forceDeleteHotel(hotel) {
    if (hotel.itinerary.length > 0) {
      this.snackSvc.openSnackBar('Error. Hotel ' + hotel.id + ' active on tour');
      return;
    }
    this.httpHotel.deleteHotel(hotel.id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          const Hidx = this.hotelsFilt.findIndex(it => '' + it.id === '' + hotel.id);
          this.hotelsFilt.splice(Hidx, 1);
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.snackSvc.openSnackBar('Error force deleting hotel ' + hotel.id);
        console.log(error);
      });

  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  keyEscape() {
    if (this.searchText) {
      this.searchText = null;
    } else {
      this.showSearch = false;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
