import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';

export interface ScheduleSendDialog {
  scheduled_send: string;
  timezone: string;
  isMobile: boolean;
  isSafari: boolean;
}

@Component({
  selector: 'app-schedule-send',
  templateUrl: './schedule-send.component.html',
  styleUrls: ['./schedule-send.component.scss'],
})
export class ScheduleSendComponent {

  time: string;
  date: string;
  selected: Date | null;

  constructor(
    private datePipe: DatePipe,
    private snackSvc: SnackbarService,
    private textTransform: TextTransformService,
    public dialogRef: MatDialogRef<ScheduleSendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScheduleSendDialog) {
    this.selected = new Date();
    this.date = this.datePipe.transform(this.selected, 'yyyy-MM-dd');
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.close('close');
    }
  }

  // Function to filter out dates in the past
  futureDateFilter(date: Date) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date >= currentDate;
  }

  timeChanger(event) {
    this.time = event.target.value;
    this.checkTime();
  }

  checkTime() {
    if (this.time) {
      const currentTime = new Date();
      const [givenHours, givenMinutes] = this.time.split(':').map(Number);
      const givenTimeDate = new Date(this.date + ' ' + this.time);
      givenTimeDate.setHours(givenHours, givenMinutes, 0, 0);
      if (givenTimeDate < currentTime) {
        this.time = String(currentTime.getHours()).padStart(2, '0') + ':' + String(currentTime.getMinutes()).padStart(2, '0');
      }
    }
  }

  convertDates(event, txt?) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (txt && txt === 'cal') {
      if (event >= currentDate) {
        this.selected = event;
      } else {
        this.selected = currentDate;
      }
      this.date = this.datePipe.transform(this.selected, 'yyyy-MM-dd');
    } else {
      if (new Date(this.date) >= currentDate) {
        this.selected = new Date(this.date);
      } else {
        this.selected = currentDate;
      }
      this.date = this.datePipe.transform(this.selected, 'yyyy-MM-dd');
    }
    this.checkTime();
  }

  submit(): void {
    if (!this.date || this.date && !this.textTransform.isValidDate(this.date)) {
      this.snackSvc.openSnackBar('Invalid date format');
      return;
    }
    if (!this.time || this.time && !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(this.time)) {
      this.snackSvc.openSnackBar('Invalid time format');
      return;
    }
    if (new Date(this.date + ' ' + this.time + ':59') < new Date()) {
      this.snackSvc.openSnackBar('Invalid time');
      this.checkTime();
      return;
    }
    this.data.scheduled_send = new Date(this.date + ' ' + this.time).toISOString().replace('.000Z', '');
    this.close(this.data);
  }

  close(reason) {
    this.dialogRef.close(reason);
  }

}
