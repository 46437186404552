import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {takeUntil} from 'rxjs/operators';
import {loadStripe} from '@stripe/stripe-js';
import {environment} from '../../../../../environments/environment';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {Subject} from 'rxjs';
import {Orders} from '../../../../shared/models/orders.model';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TitleService} from '../../../../shared/services/common/title.service';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {Activetour} from '../../../../shared/models/activetour.model';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';

@Component({
  selector: 'app-pax-tips',
  templateUrl: './pax-tips.component.html',
  styleUrls: ['./pax-tips.component.scss']
})
export class PaxTipsComponent implements OnInit, OnDestroy {
  currentUser: User;
  activeTour: Activetour;
  tour_pax: TourPax;
  tours_pax: TourPax[] = [];

  order: Orders;
  orders: Orders[] = [];

  baseUrlLogo: string;
  max: number;
  tid: string;
  tour_id: string;
  phrase: string;
  sent_icon: string;
  curr_symbol: string;
  rec_day = 0;
  err: string;
  txt: string;
  payment_intent: any;
  stripePromise: any = null;
  stripe: any = null;
  pushed_pay = false;
  loadingTours = false;
  showAmount = true;

  name: string;
  phrases: string[] = [];

  recommended: number;
  amount: number;
  ext_amount: number;
  fee: number;
  str_fee = 0;
  pln_fee = 0;
  fix_pln: number;
  per_pln: number;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private flagService: FlagService,
    private snackSvc: SnackbarService,
    private httpTour: HttpTourService,
    private httpOrderSvc: HttpOrdersService,
    private router: Router,
    private route: ActivatedRoute,
    private webTitleSvc: Title,
    private titleSvc: TitleService
  ) {
    this.webTitleSvc.setTitle('Appreciation | Planafy');
    this.titleSvc.setTitle('Appreciation');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    // Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(environment.stripePublishKey)
      .then(res => {
        this.stripe = res;
      });
    const name = this.currentUser.name.split(' ')[0];
    this.name = this.currentUser.given_name ? this.currentUser.given_name : name;
    this.phrases = [
      'Show your love to the wonderful',
      'Nobody does it better than',
      'Show some love to the one and only',
      'Send some love to the best TD ever',
      'Thanks a bunch, really, from',
      'Your generosity means a lot to',
      'Thank you for sending your gratitude to',
      'Thank you! Your gratitude means a lot to',
    ];
    this.baseUrlLogo = environment.baseUrl;
  }

  ngOnInit(): void {
    if (this.currentUser.role >= User.ef) {
      this.snackSvc.openSnackBar('Access here as PAX, not TD');
      this.router.navigate(['tours']);
      return;
    }
    this.err = null;
    this.txt = null;
    const sessionId = this.route.snapshot.queryParams['session_id'] ? this.route.snapshot.queryParams['session_id'] : null;
    if (sessionId && sessionId !== 'error') {
      this.verifySession(sessionId);
    } else if (sessionId && sessionId === 'error') {
      this.err = 'Payment not completed';
    }
    this.phrase = this.phrases[Math.floor(Math.random() * this.phrases.length)];
    this.tid = this.route.snapshot.params.tour_id || null;
    if (this.tid) {
      this.tours_pax = this.activeTourSvc.getToursPax();
      if (!this.tours_pax) {
        this.getToursPax();
        return;
      }
      this.tour_id = atob(this.tid);
      this.tour_pax = this.tours_pax.find(tp => '' + tp.tour.id === '' + this.tour_id);
      this.changeCurr(this.tour_pax.tips_currency);
    } else {
      this.getToursPax();
    }
  }

  newPhrase() {
    this.phrase = this.phrases[Math.floor(Math.random() * this.phrases.length)];
  }

  verifySession(sessionId) {
    const data = {
      'user_id': this.currentUser.id,
      'session_id': sessionId,
    };
    this.httpOrderSvc.verifyStripeSession(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const verified = res.results.verified;
            if (verified) {
              this.txt = 'Thank you for your generosity!';
              this.snackSvc.openSnackBar('Payment successful');
            } else {
              this.err = 'Err:1 Payment ok but not verified. Contact support';
              this.snackSvc.openSnackBar('ERROR:1. Verifying payment');
            }
          } else {
            this.err = 'Err:2 Payment ok but not verified. Contact support';
            this.snackSvc.openSnackBar('ERROR:2. Verifying payment');
          }
        },
        error => {
          console.log(error);
          this.err = 'Err:3 Payment ok but not verified. Contact support';
          this.snackSvc.openSnackBar('ERROR:3. Verifying payment');
        });
  }

  choose_icon() {
    if (this.amount < this.recommended / 2) {
      this.sent_icon = 'sentiment_satisfied';
    } else if (this.amount > this.recommended / 2 && this.amount < this.recommended * 1.5) {
      this.sent_icon = 'sentiment_satisfied_alt';
    } else if (this.amount > this.recommended * 1.5 && this.amount < this.recommended * 2) {
      this.sent_icon = 'sentiment_very_satisfied';
    } else if (this.amount > this.recommended * 2) {
      this.sent_icon = 'celebration';
    } else {
      this.sent_icon = 'sentiment_satisfied_alt';
    }
  }

  rem() {
    if (this.amount >= 2) {
      this.amount = this.amount - 2;
    } else {
      this.amount = 0;
    }
    this.choose_icon();
    this.calcVals();
  }

  add() {
    this.amount = this.amount + 2;
    this.choose_icon();
    this.calcVals();
  }

  doChange(event) {
    this.amount = event.value;
    this.choose_icon();
    this.calcVals();
  }

  changeCurr(txt) {
    this.curr_symbol = (txt === 'GBP' ? '£' : (txt === 'USD' ? '$' : (txt === 'AUD' ? 'AU$' : '€')));
    this.rec_day = (txt === 'GBP' ? 5 : (txt === 'AUD' ? 10 : 6));
    this.showAmount = this.tour_pax.tour.user_id !== 7048 && this.tour_pax.tour.user_id !== 6935; // Balasz, Eva
    this.recommended = this.showAmount ? this.tour_pax.tour.ndays * this.rec_day : 0;
    this.amount = this.recommended;
    this.max = this.tour_pax.tour.ndays * this.rec_day * 2;
    this.calcVals();
  }

  setRec() {
    this.amount = this.tour_pax.tour.ndays * this.rec_day;
    this.calcVals();
  }

  /*
  /* HTTP REQUESTS
  *******************/

  getToursPax() {
    this.loadingTours = true;
    this.httpTour.getToursPax(this.currentUser.id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.tours_pax = res.results.tours;
            this.activeTourSvc.setToursPax(this.tours_pax);
            if (this.tid) {
              this.tour_id = atob(this.tid);
              this.tour_pax = this.tours_pax.find(tp => '' + tp.tour_id === '' + this.tour_id);
              if (this.tour_pax) {
                this.changeCurr(this.tour_pax.tips_currency);
              } else {
                // TD disabled the online tips
                this.tours_pax = null;
                this.loadingTours = false;
              }
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTours = false;
        },
        error => {
          console.log(error);
          this.loadingTours = false;
        });
  }

  onClickTour(tour_pax: TourPax) {
    this.tour_pax = tour_pax;
    this.recommended = this.tour_pax.tour.ndays * this.rec_day;
    this.amount = this.recommended;
    this.max = this.recommended * 2;
    this.calcVals();
    const tour = this.tours_pax.find(tp => '' + tp.id === '' + tour_pax.id).tour;
    this.activeTour = new Activetour();
    if (tour) {
      this.activeTour.tour = tour;
    }
    this.activeTourSvc.setActiveTour(this.activeTour);
    this.router.navigate(['appreciation', btoa('' + this.tour_pax.tour.id)]);
  }

  onGoHome() {
    this.router.navigate(['home']);
  }

  /*
  /* STRIPE
  *******************/

  roundToTwo(num) {
    return +(Math.round(Number(num + 'e+2')) + 'e-2');
  }

  calcVals() {
    this.fix_pln = 1.2;
    if (this.amount >= 100) {
      this.per_pln = 1.0455;
    } else {
      this.per_pln = 1.0566;
    }
    // this.str_fee = this.roundToTwo(0.3 + (this.amount * 0.0322));
    // this.pln_fee = this.roundToTwo(this.fix_pln + (this.amount * this.per_pln));
    if (this.amount === 0) {
      this.str_fee = 0;
      this.pln_fee = 0;
    }
    this.ext_amount = this.roundToTwo(this.fix_pln + (this.amount * this.per_pln));
    this.str_fee = this.roundToTwo(0.3 + (this.amount * 0.034));
    this.pln_fee = this.roundToTwo(this.ext_amount - this.amount - this.str_fee);
    this.fee = this.roundToTwo(this.str_fee + this.pln_fee);
    // const td_gets = this.roundToTwo(this.ext_amount - this.fee);
    // console.log('\n\n**************** NEW CALC ********************');
    // console.log('amount', this.amount);
    // console.log('str', this.str_fee, '%', this.roundToTwo(this.str_fee * 100 / this.amount));
    // console.log('pln', this.pln_fee, '%', this.roundToTwo(this.pln_fee * 100 / this.amount));
    // console.log('s+p', this.fee, '%', this.roundToTwo(this.fee * 100 / this.amount));
    // console.log('td', td_gets, '%', this.roundToTwo(this.ext_amount - this.fee) * 100 / this.amount);
  }

  createTipCheckout() {
    this.err = null;
    this.calcVals();
    if (!this.amount) {
      this.snackSvc.openSnackBar('Please insert amount');
      return;
    }
    this.pushed_pay = true;
    const data = {
      'tp_id': this.tour_pax.id,
      'amount': this.roundToTwo(this.ext_amount * 100),
      'fee': this.roundToTwo((this.str_fee + this.pln_fee) * 100),
      'success_url': window.location.origin + '/appreciation/' + btoa('' + this.tour_pax.tour.id),
      'cancel_url': window.location.origin + '/appreciation/' + btoa('' + this.tour_pax.tour.id),
      'returnUrl': null,
    };
    // console.log(data);
    this.httpOrderSvc.setStripeTipsExpCheckout(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            window.open(res.results.checkout_session.url, '_self');
          } else {
            this.pushed_pay = false;
            this.snackSvc.openSnackBar('Error(4). Contact your Tour Director');
          }
        },
        error => {
          this.pushed_pay = false;
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, '' + error, '');
          this.snackSvc.openSnackBar('Error(5). Contact your Tour Director');
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
