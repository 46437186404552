import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppleService {
  private endpoints: {
    apple_url: string,
    apple_token: string,
    apple_register: string,
  };

  constructor(
    private http: HttpClient,
    private authSvc: AuthenticationService
  ) {
    this.endpoints = {
      apple_url: environment.apiUrl + 'apple_url',
      apple_token: environment.apiUrl + 'apple_token',
      apple_register: environment.apiUrl + 'apple_register',
    };
  }

  async appleGetUrl2() {
    return await this.http.get<any>(this.endpoints.apple_url).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  appleGetUrl(url: string): Observable<any> {
    const params = new HttpParams()
      .set('environment_url', url.toString());
    return this.http.get<any>(this.endpoints.apple_url, {params: params});
  }

  appleGetToken(data): Observable<any> {
    return this.http.post<any>(this.endpoints.apple_token, data);
  }

  registerApple(data): Observable<any> {
    return this.http.post<any>(this.endpoints.apple_register, data)
      .pipe(map(res => {
        // login successful if there's a jwt token in the response
        if (res.results && res.results.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', btoa(JSON.stringify(res.results)));
          localStorage.setItem('accessToken', JSON.stringify(res.results.access_token));
          this.authSvc.updateUser(res.results);
        }
        return res;
      }));
  }
}
