import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {Incident} from '../../../../shared/models/incident.model';
import {Subject} from 'rxjs';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {HttpIncidentService} from '../../../../shared/services/http/http-incident.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-adm-incidents',
  templateUrl: './adm-incidents.component.html',
  styleUrls: ['./adm-incidents.component.scss']
})
export class AdmIncidentsComponent implements OnInit, OnDestroy {
  currentUser: User;
  incidents: Incident[];
  loading = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private snackSvc: SnackbarService,
    private authSvc: AuthenticationService,
    private httpIncidentService: HttpIncidentService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit() {
    this.getAllIncidents();
  }

  printInfo() {
    console.log(this.incidents);
  }

  getAllIncidents() {
    this.loading = true;
    this.httpIncidentService.getAllIncidents()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.incidents = res.results.incidents;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  onRestoreIncident(inc) {
    this.loading = true;
    const data = {
      'restore': true,
    };
    // console.log(data);
    this.httpIncidentService.updateIncident(inc.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            inc.deleted_at = res.results.incident.deleted_at;
            this.incidents[this.incidents.findIndex(i => '' + i.id === '' + inc.id)].deleted_at = inc.deleted_at;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  deleteIncident(inc) {
    this.loading = true;
    this.httpIncidentService.deleteIncident(inc.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            inc.deleted_at = true;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  onForceDelete(inc) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Force delete incident?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpIncidentService.deleteIncident(inc.id, this.currentUser.id, 'true')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  this.incidents.splice(this.incidents.findIndex(it => '' + it.id === '' + inc.id), 1);
                } else {
                  this.snackSvc.resultsElse(res);
                }
              },
              error => {
                console.log(error);
              });
        }
      });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
