import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TextTransformService {

  constructor(
    private datePipe: DatePipe,
  ) {
  }

  titleCase(str) {
    // If airport dont title case
    if (str.search('irport') === -1) {
      return str.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
        return match.toUpperCase();
      });
    } else {
      return str;
    }
  }

  upperFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  urlize(txt) {
    return txt
      .replace(/\s+/g, '-')
      .toLowerCase();
  }

  dehyphenize(txt) {
    return txt
      .toLowerCase()
      .replace(/-/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{4}\-\d{2}\-\d{2}$/.test(dateString)) {
      return false;
    }
    // Parse the date parts to integers
    const parts = dateString.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);
    // Check the ranges of month and year
    if (year < 100 || year > 9000 || month === 0 || month > 12) {
      return false;
    }
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }
    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  dateDiffInDays(a, b) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor(Math.abs(utc2 - utc1) / (1000 * 60 * 60 * 24));
  }

  betDays(start, end, needle): boolean {
    return new Date(start) <= new Date(needle) && new Date(end) >= new Date(needle);
  }

  betDaysNe(start, end, needle): boolean {
    return new Date(start) <= new Date(needle) && new Date(end) > new Date(needle);
  }

  addDays(date: Date, days: number): string {
    date.setDate(date.getDate() + days);
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
}
