import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SupportComponent} from './pages/support/support.component';
import {TosComponent} from './pages/tos/tos.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {ReturnComponent} from './pages/return/return.component';

const legalRoutes: Routes = [
  {
    path: 'legal', children: [
      {path: 'terms-of-service', component: TosComponent},
      {path: 'privacy-policy', component: PrivacyComponent},
      {path: 'return-policy', component: ReturnComponent},
    ]
  },
  {path: 'support', component: SupportComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(legalRoutes)
  ],
  declarations: [
    TosComponent,
    PrivacyComponent,
    SupportComponent,
    ReturnComponent,
  ]
})
export class LegalRoutingModule {
}
