import {Question} from './question.model';
import {Components} from './components.model';

export class Form {
  id: number;
  component_id: number;
  visible: boolean;
  multiple: boolean;
  block: boolean;
  component?: Components;
  questions?: Question[];

  constructor() {
    this.questions = [];
  }
}
