export class Message {
  bccRecipients: [{ 'emailAddress': { 'name': string, 'address': string } }];
  body: { 'contentType': string, 'content': string };
  bodyPreview: string;
  categories: string[];
  ccRecipients: [{ 'emailAddress': { 'name': string, 'address': string } }];
  changeKey: string;
  conversationId: string;
  conversationIndex: string;
  createdDateTime: string;
  flag: { 'flagStatus': string };
  singleValueExtendedProperties: [{ 'id': string, 'value': string }];
  scheduled_date?: string;
  from: [{ 'emailAddress': { 'name': string, 'address': string } }];
  hasAttachments: string;
  id: string;
  importance: string;
  inferenceClassification: string;
  internetMessageHeaders: string;
  internetMessageId: string;
  isDeliveryReceiptRequest: string;
  isDraft: boolean;
  isRead: boolean;
  isReadReceiptRequested: string;
  lastModifiedDateTime: string;
  parentFolderId: string;
  receivedDateTime: string;
  replyTo: [{ 'emailAddress': { 'name': string, 'address': string } }];
  sender: { 'emailAddress': { 'name': string, 'address': string } };
  sentDateTime: string;
  subject: string;
  toRecipients: [{ 'emailAddress': { 'name': string, 'address': string } }];
  uniqueBody: string;
  webLink: string;
  folded: boolean;
  showDeets: boolean;
  attachments?: [{ 'id': string, 'lastModifiedDateTime': string, 'name': string, 'contentType': string, 'size': string, 'contentBytes': string }];

  constructor() {
    this.isRead = false;
    this.folded = true;
    this.isDraft = true;
    this.showDeets = false;
    this.conversationId = '';
  }

}
