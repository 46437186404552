import {Component, Inject} from '@angular/core';
import {Activetour} from '../../../../shared/models/activetour.model';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.scss']
})
export class AddCreditComponent {
  activeTour: Activetour;
  userId: number;
  private onDestroy$ = new Subject<boolean>();

  creditForm = new UntypedFormGroup({
    reason: new UntypedFormControl(''),
    amount: new UntypedFormControl('', Validators.required),
  });

  constructor(public dialogRef: MatDialogRef<AddCreditComponent>,
              private snackSvc: SnackbarService,
              private activeTourSvc: ActivetourService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.userId = data.user_id;
  }

  submit(): void {
    if (this.creditForm.controls.amount.invalid) {
      this.snackSvc.openSnackBar('Invalid amount');
      return;
    }
    this.close('send');
  }

  close(reason) {
    this.creditForm.value.reason = reason;
    this.creditForm.value.user_id = this.userId;
    this.dialogRef.close(this.creditForm.value);
  }
}
