import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, HostListener} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Contact} from '../../models/contact.model';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {User} from '../../models/user.model';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, SPACE];
  filteredContacts: Observable<Contact[]>;
  recipientCtrl = new UntypedFormControl();
  private optionSelectedFlag = false;

  @Input() public currentUser: User;
  @Input() public label: string;
  @Input() public recipients: string[];
  @Output() updateRecipients = new EventEmitter();
  @Output() updateContacts = new EventEmitter();
  @ViewChild('recipientsInput') recipientsInput: ElementRef<HTMLInputElement>;

  constructor() {
    if (!this.recipients) {
      this.recipients = [];
    }
    this.filteredContacts = this.recipientCtrl.valueChanges
      .pipe(
        startWith(''),
        map((c: string | null) => c ? this._filter(c) : this.currentUser.contacts.list.slice()));
  }

  ngOnInit(): void {
    if (this.currentUser.contacts.count < 1) {
      this.updateContacts.emit();
    }
  }

  private _filter(value: string): Contact[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.currentUser.contacts.list.filter(c => c.email.toLowerCase().includes(filterValue) || c.name.toLowerCase().includes(filterValue));
  }

  inputChip(event: any) {
    // console.log(this.recipientsInput.nativeElement.value);
    if (event.data) {
      const last = this.recipientsInput.nativeElement.value.charAt(this.recipientsInput.nativeElement.value.length - 1);
      if (last === ' ' || last === ',') {
        this.add({value: this.recipientsInput.nativeElement.value.replace(/,\s*$/, '')});
      }
    }
  }

  add(event: any): void {
    // console.log(event);
    const value = (event.value || '').trim();
    if (value) {
      this.recipients.push(value);
    }
    this.recipientsInput.nativeElement.value = null;
    this.recipientCtrl.setValue(null);
    this.updateRecipients.emit(this.recipients.join(','));
  }

  clickChip(rec: string): void {
    this.remove(rec);
    this.recipientsInput.nativeElement.value = rec;
    this.recipientCtrl.setValue(rec);
  }

  remove(fruit: string): void {
    const index = this.recipients.indexOf(fruit);
    if (index >= 0) {
      this.recipients.splice(index, 1);
      this.updateRecipients.emit(this.recipients.join(','));
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.optionSelectedFlag = true;
    if (!event.option.value.email) {
      this.recipientsInput.nativeElement.value = '';
      this.recipientCtrl.setValue(null);
      return;
    }
    this.recipients.push(event.option.value.email);
    this.recipientsInput.nativeElement.value = '';
    this.recipientCtrl.setValue(null);
    this.updateRecipients.emit(this.recipients.join(','));
    this.optionSelectedFlag = false; // Reset the flag after handling the selection
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event): void {
    if (!this.recipientsInput.nativeElement.contains(event.target as Node) && !this.optionSelectedFlag) {
      const value = this.recipientsInput.nativeElement.value.trim();
      if (value) {
        this.add({value: value});
      }
    }
  }
}
