import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpUserService {
  private endpoints: {
    allUsers: string,
    allUsers2: string,
    crudUser: string,
    register: string,
    userSetts: string,
    meAdmin: string,
    referralCRUD: string,
    referrals: string,
    referralsUser: string,
    updateApp: string,
    checkApp: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      register: environment.apiUrl + 'register/',
      crudUser: environment.apiUrl + 'user/',
      allUsers: environment.apiUrl + 'users',
      allUsers2: environment.apiUrl + 'users2',
      userSetts: environment.apiUrl + 'user_settings/',
      meAdmin: environment.apiUrl + 'me_admin/',
      referralCRUD: environment.apiUrl + 'referral/',
      referrals: environment.apiUrl + 'referrals/',
      referralsUser: environment.apiUrl + 'referrals_user/',
      checkApp: environment.apiUrl + 'app/check_version/',
      updateApp: environment.apiUrl + 'app/update_version/',
    };
  }

  /* Update user */
  setMeAdmin(): Observable<any> {
    return this.http.get(this.endpoints.meAdmin);
  }

  /* Update user */
  getAllUsers(role: string): Observable<any> {
    const params = new HttpParams()
      .set('role', role.toString());
    return this.http.get(this.endpoints.allUsers, {params: params});
  }

  /* Create PAX user */
  getAllUsers2(take?: number, skip?: number, search?: string): Observable<any> {
    let params = new HttpParams();
    if (take) {
      params = params.set('take', take.toString());
    }
    if (skip) {
      params = params.set('skip', skip.toString());
    }
    if (search) {
      params = params.set('search', search.toString());
    }
    return this.http.get<any>(this.endpoints.allUsers2, {params: params});
  }

  /* Create PAX user */
  createUser(paramsToUpdate: any): Observable<any> {
    return this.http.post(this.endpoints.register, paramsToUpdate);
  }

  /* Update user */
  updateUser(user_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put(this.endpoints.crudUser + user_id, paramsToUpdate);
  }

  /* Get user */
  getUser(user_id: number): Observable<any> {
    return this.http.get(this.endpoints.crudUser + user_id);
  }

  /* Update user */
  getUserSettings(user_id: number): Observable<any> {
    return this.http.get(this.endpoints.userSetts + user_id);
  }

  /* Update user */
  updateUserSettings(user_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put(this.endpoints.userSetts + user_id, paramsToUpdate);
  }

  /* Get user async */
  async getUserSettingsAsync(user_id: number) {
    return await this.http.get(this.endpoints.userSetts + user_id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Delete user */
  deleteUser(user_id: number, req_id: number, force?: string) {
    let params = new HttpParams()
      .set('user_id', user_id.toString())
      .set('req_id', req_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudUser, {params: params});
  }

  // REFERRALS

  getAllReferrals(): Observable<any> {
    return this.http.get(this.endpoints.referrals);
  }

  getReferralsUser(user_id: number): Observable<any> {
    return this.http.get(this.endpoints.referralsUser + user_id);
  }

  createReferral(paramsToUpdate: any): Observable<any> {
    return this.http.post(this.endpoints.referralCRUD, paramsToUpdate);
  }

  updateReferral(user_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put(this.endpoints.referralCRUD + user_id, paramsToUpdate);
  }

  deleteReferral(user_id: number, req_id: number, force?: string) {
    let params = new HttpParams()
      .set('referral_id', user_id.toString())
      .set('req_id', req_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.referralCRUD, {params: params});
  }

  async checkAppVersion() {
    return await this.http.get(this.endpoints.checkApp).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async updateAppVersion(paramsToUpdate: any) {
    return await this.http.post(this.endpoints.updateApp, paramsToUpdate).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
}
