import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adm-locator',
  templateUrl: './adm-locator.component.html',
  styleUrls: ['./adm-locator.component.scss']
})
export class AdmLocatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
