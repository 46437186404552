import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogDataMsg {
  confirmTitle: string;
  confirmAction: string;
  confirmMessage: string;
  disabled: boolean;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataMsg) {
    if (!this.data.disabled) {
      this.data.disabled = false;
    }
    if (!this.data.confirmTitle) {
      this.data.confirmTitle = 'Attention';
    }
    if (!this.data.confirmAction) {
      this.data.confirmAction = 'OK';
    }
  }

}
