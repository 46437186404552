import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../models/user.model';
import {AuthenticationService} from '../../services/session/authentication.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  currentUser: User;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private authSvc: AuthenticationService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit() {
  }

  goHome() {
    if (!this.currentUser || this.currentUser.role < 3) {
      this.router.navigate(['home']);
    } else {
      this.router.navigate(['tours']);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
