import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/session/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PaxGuardGuard implements CanActivate {
  constructor(
    private router: Router,
    private authSvc: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authSvc.currentUserValue;
    if (currentUser) {
      // authorised so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['login', 'pax'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
