import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {Title} from '@angular/platform-browser';
import {Components} from '../../../../../shared/models/components.model';
import {HttpRoomService} from '../../../../../shared/services/http/http-room.service';
import {Room} from '../../../../../shared/models/room.model';
import {TourPax} from '../../../../../shared/models/tour-pax.model';
import {Bed} from '../../../../../shared/models/bed.model';
import {environment} from '../../../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {EditRoomsComponent} from '../../../../components/hotel/edit-rooms/edit-rooms.component';
import {AddRoomieComponent} from '../../../../components/hotel/add-roomie/add-roomie.component';
import {HttpUserService} from '../../../../../shared/services/http/http-user.service';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {FlagService} from '../../../../../shared/services/common/flag.service';
import {AssignRoomsComponent} from '../../../../components/hotel/assign-rooms/assign-rooms.component';
import {Rooming} from '../../../../../shared/models/rooming.model';
import {SendRoomingComponent} from '../../../../components/hotel/send-rooming/send-rooming.component';
import {OutlookService} from '../../../../../shared/services/session/outlook.service';
import {MatInput} from '@angular/material/input';
import {ReplicateRoomsComponent} from '../../../../components/hotel/replicate-rooms/replicate-rooms.component';
import {TextTransformService} from '../../../../../shared/helpers/texttransform.service';
import {ExcelService} from '../../../../../shared/services/common/excel.service';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';

@Component({
  selector: 'app-tour-hotels',
  templateUrl: './tour-hotels.component.html',
  styleUrls: ['./tour-hotels.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TourHotelsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  component: Components;
  apiUrl: string;
  selfUrl: string;
  returnUrl: string;
  showGenderInfo: boolean;
  same_gender: boolean;
  first_gender: string;
  dayId: number;
  compId: number;
  itId: number;

  plus = false;

  tourPax: TourPax[] = [];
  room_tourPax: any[] = [];
  left_tourPax: TourPax[] = [];
  roomPlan: Room[][] = [];
  rooms: Room[] = [];
  preferences: Rooming[] = [];
  matches: any[][] = [];
  antimatches: any[][] = [];
  roomTypes: { 'single': 1, 'twin': 2, 'double': 2, 'triple': 3, 'quad': 4, 'five': 5, 'six': 6, 'seven': 7, 'eight': 8, 'nine': 9, 'ten': 10 };
  beds: Bed[] = [];
  empty_locked_beds = 0;
  npax = 0;
  nadmin = 0;
  nights = 0;
  longPressed: boolean;
  roomPrefs: any;
  all_hots: {
    'id': number,
    'supplier': string,
    'city_name': string,
    'itin_id': number,
    'check_in': string,
    'check_out': string
  }[];

  idxEditRoom: number[] = [];
  idxEditBed: number[] = [];
  idxRepeatPax: number[] = [];
  idxAntiPax: number[] = [];
  idxAntiPref: number[] = [];
  idxWrongGender: number[] = [];
  idxWrongNumber: number[] = [];
  idxBadGender: number[] = [];
  idxBadRoomType: number[] = [];
  idxTPs2room: any[] = [];
  idxRooms2Drop: number[] = [];

  showMatches = false;
  showAntiMatches = false;
  showPrefs = false;
  showLeft = false;
  showMoreRooms = false;
  loadingHotels = false;
  roomingSeveral = false;
  errpax = false;
  showIds = false;
  idxUpdOnes: number[] = [];

  searchText: string;
  searching = false;

  private onDestroy$ = new Subject<boolean>();
  @ViewChild('roomNumber') input_nRoom: MatInput;
  @ViewChild('roomNumber') reef_nRoom: ElementRef;

  @HostListener('touchstart', ['$event']) onTouch() {
    this.longPressed = false;
  }

  @HostListener('mousemove', ['$event']) onMove() {
    this.longPressed = false;
  }

  constructor(private authSvc: AuthenticationService,
              private activeTourSvc: ActivetourService,
              private router: Router,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private versioning: VersioningService,
              private httpUser: HttpUserService,
              private httpTour: HttpTourService,
              private httpRoom: HttpRoomService,
              private excelService: ExcelService,
              private outlookSvc: OutlookService,
              private flagService: FlagService,
              private titleSvc: TitleService,
              private textTransform: TextTransformService,
              private webTitleSvc: Title,
              private snackSvc: SnackbarService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.plus = tour?.tour.code.substring(0, 1) === 'X';
        this.activeTour = tour;
      });
    this.roomPrefs = JSON.parse(localStorage.getItem('roomPreferences')) || [];
    const idx = this.roomPrefs.findIndex(i => i.tour_id === this.activeTour.tour.id);
    if (idx >= 0) {
      this.preferences = this.roomPrefs[idx].preferences;
    }
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Escape') {
      this.idxEditBed = [];
      this.idxEditRoom = [];
    }
  }

  ngOnInit(): void {
    const prod_id = this.route.snapshot.params['tour-prodid'];
    if (this.activeTour.tour.prodid + '' !== prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour?');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour?');
      this.router.navigate(['tours']);
      return;
    }
    this.compId = this.route.snapshot.params['th_id'];
    this.itId = this.route.snapshot.queryParams['i'] ? this.route.snapshot.queryParams['i'] : null;
    this.component = this.activeTour.components.find(c => +c.id === +this.compId);
    this.showGenderInfo = !!localStorage.getItem('showGenderInfo');
    this.getHotelInfo();
  }

  // HELPERS

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, []);
  }

  // MAIN ACTIONS

  getHotelInfo(comp_id?) {
    this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    if (comp_id) {
      this.component = this.activeTour.components.find(c => '' + c.id === '' + comp_id);
    }
    this.apiUrl = environment.apiUrl;
    this.selfUrl = environment.selfUrl;
    this.loadingHotels = true;
    this.httpRoom.getRoomItinerary(this.component ? this.component.itinerary_id : this.itId, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.loadingHotels = false;
        console.log(res);
        if (res.status < 400) {
          this.versioning.check(res.results.version);
          this.tourPax = res.results.tourpax;
          this.rooms = res.results.rooms;
          this.processPrefs(res.results.preferences);
          this.matches = res.results.matches;
          this.antimatches = res.results.antimatches;
          this.dayId = res.results.day_id;
          this.component = res.results.component;
          this.errpax = false;
          res.results.tourpax.map(t => {
            if (!t.room) {
              this.errpax = true;
            } else {
              if (t.room !== 'Si' && t.user?.role > 1) {
                this.errpax = true;
              }
            }
            if (t.user && !t.user.gender) {
              this.errpax = true;
            }
          });
          this.processRoomPlan();
          this.idxEditRoom = [];
          this.idxEditBed = [];
          this.idxRooms2Drop = [];
          this.idxTPs2room = [];
          this.showLeft = false;
          this.all_hots = res.results.hotels_tour;
          const hotel = this.all_hots.find(h => +h.id === +this.component.id);
          this.nights = this.textTransform.dateDiffInDays(new Date(hotel.check_in), new Date(hotel.check_out));
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.loadingHotels = false;
        console.log(error);
      });
  }

  processRoomPlan() {
    // beds for passing to check warnings
    this.beds = this.rooms.reduce((accum, el) => accum.concat(el.beds), []);
    this.empty_locked_beds = 0;
    for (const room of this.rooms) {
      this.empty_locked_beds += room.beds.filter(b => b.locked && !b.tour_pax_id).length;
    }
    this.roomPlan = this.groupBy(this.rooms, 'type');
    this.roomPlan = Object.keys(this.roomPlan).map(k => this.roomPlan[k]);
    this.processPax();
    // Check repeated #s
    this.checkRepNumbers();
  }

  processRemainPax() {
    // Get not roomed
    this.left_tourPax = [];
    for (const tp of this.tourPax) {
      if (!this.idxTPs2room.includes(tp.id)) {
        if (this.room_tourPax.findIndex(rtp => '' + rtp.id === '' + tp.id) < 0) {
          if (tp.id && tp.user.name !== 'Bus Driver' && !tp.hidden) {
            this.left_tourPax.push(tp);
          }
        }
      }
    }
    this.left_tourPax.sort(function (a, b) {
      return (b.user.role && b.user.role.toString().localeCompare(a.user.role.toString())) ||
        (a.user.gender && a.user.gender.localeCompare(b.user.gender)) ||
        (a.user.name && a.user.name.localeCompare(b.user.name));
    });
  }

  processPax() {
    const beds = this.rooms.reduce((accum, el) => accum.concat(el.beds), []);
    this.room_tourPax = beds.filter(bed => bed.tour_pax);
    this.room_tourPax = this.room_tourPax.reduce((a, e) => a.concat(e.tour_pax), []);
    // Update pax+ad
    this.npax = this.room_tourPax.filter(tp => tp.user && tp.user.role === 1).length;
    this.nadmin = this.room_tourPax.filter(tp => tp.user && tp.user.role === 2).length;
    // Get not roomed
    this.processRemainPax();
    // WARNINGS
    // Find duplicates
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    this.idxRepeatPax = findDuplicates(this.room_tourPax.reduce((a, e) => a.concat(e.id), []));
    // Find Antipax && bad gender
    this.idxAntiPax = [];
    this.idxAntiPref = [];
    this.idxBadGender = [];
    this.idxBadRoomType = [];
    // Find bad gender & antipax
    for (const r of this.rooms) {
      // Find bad gender
      for (const b of r.beds) {
        if (r.gender && b.tour_pax && b.tour_pax.user.gender !== 'x' && b.tour_pax.user.gender.substring(0, 1).toUpperCase() !== r.gender) {
          this.idxBadGender.push(b.id);
        }
        if (r.type && b.tour_pax && b.tour_pax.room) {
          if (b.tour_pax.room === 'Si' && r.type !== 'single') {
            this.idxBadRoomType.push(b.id);
          } else if (b.tour_pax.room === 'Tw' && r.type !== 'twin') {
            this.idxBadRoomType.push(b.id);
          } else if (b.tour_pax.room === 'Db' && r.type !== 'double') {
            this.idxBadRoomType.push(b.id);
          } else if (b.tour_pax.room === 'Tr' && r.type !== 'triple') {
            this.idxBadRoomType.push(b.id);
          } else if (b.tour_pax.room === 'Qd' && r.type !== 'quad') {
            this.idxBadRoomType.push(b.id);
          }
        }
      }
      // Find antipax
      const roomies = r.beds.filter(b => b.tour_pax).map(b => b.tour_pax.user.name);
      if (roomies.length < 2) {
        continue;
      }
      for (const am of this.antimatches) {
        const tmp = [];
        for (const roomie of roomies) {
          if (am.includes(roomie)) {
            tmp.push(roomie);
          }
        }
        if (tmp.length > 1) {
          this.idxAntiPax = [...this.idxAntiPax, ...this.getAntimatchIds(r, tmp)];
        }
      }
    }
    this.idxAntiPax = this.idxAntiPax.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    // Find antiprf
    for (const r of this.rooms) {
      const ids = r.beds.filter(b => b.tour_pax).map(b => b.tour_pax.id);
      if (ids.length < 2) {
        continue;
      }
      // get id's antiprefs
      // if any in the rest of room is in antprefs, add id (bed)
      for (const id of ids) {
        const id_prefs = [];
        for (const pr of this.preferences) {
          if (!pr['choice']) {
            if (pr['main_id'] === id) {
              if (!id_prefs.includes(pr['other_id'])) {
                id_prefs.push(pr['other_id']);
              }
            }
            if (pr['other_id'] === id) {
              if (!id_prefs.includes(pr['main_id'])) {
                id_prefs.push(pr['main_id']);
              }
            }
          }
        }
        const anti_prefs = ids.filter(val => id_prefs.includes(val));
        if (anti_prefs.length > 0) {
          anti_prefs.push(id);
          this.idxAntiPref = [...this.idxAntiPref, ...this.getBedsId(r.beds, anti_prefs)];
        }
      }
    }
    this.idxAntiPref = this.idxAntiPref.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    // Remove from antiprefs if already in antimatch
    this.idxAntiPref = this.idxAntiPref.filter(item => this.idxAntiPax.indexOf(item) < 0);
    // Update copy names
    if (this.idxTPs2room.length > 0) {
      this.copyNames(this.ids2name(this.idxTPs2room));
    }
  }

  processPrefs(preferences) {
    this.idxUpdOnes = [];
    preferences.map(new_p => {
      if (this.preferences.findIndex(old_p => new_p.main_id === old_p.main_id && new_p.other_id === old_p.other_id) < 0) {
        new_p.warn = true;
        this.idxUpdOnes.push(new_p.id);
      }
      this.preferences.map(old_p => {
        if (new_p.main_id === old_p.main_id && new_p.other_id === old_p.other_id) {
          if (new_p.choice !== old_p.choice) {
            new_p.warn = true;
            this.idxUpdOnes.push(new_p.id);
          }
        }
      });
    });
    this.preferences = preferences;
    // update local storage
    const idx = this.roomPrefs.findIndex(it => it.tour_id === this.activeTour.tour.id);
    if (idx < 0) {
      this.roomPrefs.push({'tour_id': this.activeTour.tour.id, 'preferences': preferences});
    } else {
      this.roomPrefs[idx].tour_id = this.activeTour.tour.id;
      this.roomPrefs[idx].preferences = preferences;
    }
    localStorage.setItem('roomPreferences', JSON.stringify(this.roomPrefs));
  }

  // ROOM ACTIONS

  onCreateRooms() {
    const dialogRef = this.dialog.open(EditRoomsComponent, {
      data: {
        'autoFocus': true,
        'singles': this.rooms.filter(r => r.type === 'single').length || null,
        'doubles': this.rooms.filter(r => r.type === 'double').length || null,
        'twins': this.rooms.filter(r => r.type === 'twin').length || null,
        'triples': this.rooms.filter(r => r.type === 'triple').length || null,
        'quads': this.rooms.filter(r => r.type === 'quad').length || null,
        'fives': this.rooms.filter(r => r.type === 'five').length || null,
        'sixes': this.rooms.filter(r => r.type === 'six').length || null,
        'sevens': this.rooms.filter(r => r.type === 'seven').length || null,
        'eights': this.rooms.filter(r => r.type === 'eight').length || null,
        'nines': this.rooms.filter(r => r.type === 'nine').length || null,
        'tens': this.rooms.filter(r => r.type === 'ten').length || null,
        'nsingles': this.activeTour.tour.rms.sin,
        'ntwins': this.activeTour.tour.rms.twn,
        'ndoubles': this.activeTour.tour.rms.dbl,
        'ntriples': this.activeTour.tour.rms.tri,
        'nquads': this.activeTour.tour.rms.qad,
        'nmales': this.activeTour.tour.rms.mal,
        'nfemales': this.activeTour.tour.rms.fem,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        this.createRooms(result);
      } else {
        console.log('Didnt create rooms');
      }
    });
  }

  createRooms(results) {
    this.loadingHotels = true;
    const data = {
      'itin_id': this.component.itinerary_id,
    };
    if (results['singles']) {
      data['single'] = results['singles'];
    }
    if (results['doubles']) {
      data['double'] = results['doubles'];
    }
    if (results['twins']) {
      data['twin'] = results['twins'];
    }
    if (results['triples']) {
      data['triple'] = results['triples'];
    }
    if (results['quads']) {
      data['quad'] = results['quads'];
    }
    if (results['fives']) {
      data['five'] = results['fives'];
    }
    if (results['sixes']) {
      data['six'] = results['sixes'];
    }
    if (results['sevens']) {
      data['seven'] = results['sevens'];
    }
    if (results['eights']) {
      data['eight'] = results['eights'];
    }
    if (results['nines']) {
      data['nine'] = results['nines'];
    }
    if (results['tens']) {
      data['ten'] = results['tens'];
    }
    this.httpRoom.createRoomsItin(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.rooms = res.results.rooms;
            this.idxEditBed = [];
            this.idxEditRoom = [];
            // Remove selected pax
            this.idxRooms2Drop = [];
            this.idxTPs2room = [];
            this.processRoomPlan();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingHotels = false;
        },
        error => {
          console.log(error);
          this.loadingHotels = false;
          this.snackSvc.openSnackBar('Error creating rooms');
        });
  }

  createRoom(type, beds, tpax?) {
    this.loadingHotels = true;
    const data = {
      'itinerary_id': this.component.itinerary_id,
      'type': type,
      'beds': beds ? beds : this.roomTypes[type],
    };
    // console.log(data);
    this.httpRoom.createRoom(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          // this.rooms.push(res.results.room);
          let idx = this.rooms.findIndex(it => it.beds.length > beds);
          if (idx < 0) {
            idx = this.rooms.length;
          }
          this.rooms.splice(idx, 0, res.results.room);
          this.processRoomPlan();
          if (tpax) {
            const tmp = res.results.room.beds[0];
            tmp.tour_pax_id = tpax;
            tmp.locked = 1;
            this.onChangeBed(tmp);
          } else {
            this.loadingHotels = false;
          }
        } else {
          this.loadingHotels = false;
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.loadingHotels = false;
        console.log(error);
      });
  }

  isRoomGenderOK(room) {
    const upgrade = room.type === 'single' || room.type === 'twin' && !room.gender || room.type === 'double';
    const genders = this.idxTPs2room.map(tp_id => this.tourPax.find(tp => tp.id === tp_id).user.gender);
    this.same_gender = genders.every((val, i, arr) => val === arr[0] || val === 'x' || arr[0] === 'x');
    this.first_gender = genders[0].substring(0, 1).toUpperCase();
    const same_room_gender = this.first_gender === 'X' || this.first_gender === room.gender;
    return upgrade || this.same_gender && same_room_gender;
  }

  selectRoom(room: Room) {
    if (this.idxTPs2room.length > 0) {
      const free_beds = room.beds.filter(b => !b.locked && !b.tour_pax_id);
      if (free_beds.length < this.idxTPs2room.length) {
        console.log('Not enough beds!');
      } else if (!this.isRoomGenderOK(room)) {
        console.log('Not same gender');
      } else {
        // Filter if pax already in room
        const bedRepeat = room.beds.filter(b => this.idxTPs2room.includes(b.tour_pax_id));
        let final_tps = this.idxTPs2room;
        if (bedRepeat.length > 0) {
          const tpids = bedRepeat.map((obj) => obj && obj.tour_pax_id);
          final_tps = this.idxTPs2room.filter(x => !tpids.includes(x));
        }
        if (final_tps.length > 0) {
          this.onRoomSeveralPax(room, final_tps.join(','));
        } else {
          // All PAX already in room
          console.log('All PAX already in room');
          this.idxTPs2room = [];
          this.idxRooms2Drop = [];
        }
      }
    }
  }

  toggleRoomGender(room) {
    if (room.type === 'single' || room.type === 'double') {
      return;
    }
    this.showGenderInfo = true;
    localStorage.setItem('showGenderInfo', 'true');
    if (!room.gender) {
      room.gender = 'F';
    } else if (room.gender === 'F') {
      room.gender = 'M';
    } else if (room.gender === 'M') {
      room.gender = null;
    }
    this.onSaveRoom(room, 'gender');
  }

  onEditNumber(room: Room) {
    if (!room.edit_number) {
      this.idxEditBed = [];
      this.idxEditRoom = [];
      room.edit_number = true;
      room.new_n = room.number;
      setTimeout(() => {
        // this.reef_nRoom.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 10);
      setTimeout(() => {
        this.input_nRoom.focus();
      }, 10);
    } else {
      this.onSaveRoom(room, 'number');
    }
  }

  removeWrongGender(room) {
    if (room.gender) {
      const idxWr = this.idxWrongGender.findIndex(r => r === room.id);
      if (idxWr >= 0) {
        this.idxWrongGender.splice(idxWr, 1);
      }
    }
  }

  checkRepNumbers() {
    const numbers = this.rooms.map(r => r.number).filter(t => t !== null);
    const toFindDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    const dupes = toFindDuplicates(numbers);
    this.idxWrongNumber = this.rooms.filter(r => dupes.includes(r.number)).reduce((accum, el) => accum.concat(el.id), []);
  }

  onCloseRoom(room) {
    const idx = this.idxEditRoom.findIndex(r => r === room.id);
    if (idx >= 0) {
      this.idxEditRoom.splice(idx, 1);
    }
  }

  onSaveRoom(room: Room, type?: string) {
    this.loadingHotels = true;
    const data = {};
    if (type === 'number') {
      data[type] = room.new_n;
    } else if (type === 'gender') {
      data[type] = room.gender;
    } else {
      data['number'] = room.new_n;
      data['gender'] = room.gender;
    }
    // console.log(data);
    this.httpRoom.updateRoom(room.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.loadingHotels = false;
        console.log(res);
        if (res.status < 400) {
          room.new_n = null;
          // Remove from edit mode
          if (type === 'number') {
            this.idxEditBed = [];
            this.onCloseRoom(room);
          }
          // Remove from wrong gender
          this.removeWrongGender(room);
          // Edit the room
          const rIdx = this.rooms.findIndex(r => r.id === room.id);
          this.rooms[rIdx] = res.results.room;
          // Adjust roomPlan
          this.processRoomPlan();
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.loadingHotels = false;
        console.log(error);
      });
  }

  onCleanRoom(room) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Empty room?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.loadingHotels = true;
          const data = {
            'number': room.number,
            'gender': room.gender,
            'clean': true,
          };
          // console.log(data);
          this.httpRoom.updateRoom(room.id, data)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              this.loadingHotels = false;
              console.log(res);
              if (res.status < 400) {
                // Remove from edit mode
                this.idxEditBed = [];
                this.onCloseRoom(room);
                // Remove from edit mode
                this.removeWrongGender(room);
                // Edit the room
                const rIdx = this.rooms.findIndex(r => r.id === room.id);
                this.rooms[rIdx] = res.results.room;
                // Adjust roomPlan
                this.processRoomPlan();
              } else {
                this.snackSvc.resultsElse(res);
              }
            }, error => {
              this.loadingHotels = false;
              console.log(error);
            });
        }
      });
  }

  onDeleteRoom(room) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete room?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.loadingHotels = true;
          this.httpRoom.deleteRoom(room.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              this.loadingHotels = false;
              console.log(res);
              if (res.status < 400) {
                const idx = this.rooms.findIndex(r => r.id === room.id);
                this.rooms.splice(idx, 1);
                this.processRoomPlan();
              } else {
                this.snackSvc.resultsElse(res);
              }
            }, error => {
              this.loadingHotels = false;
              console.log(error);
            });
        }
      });
  }

  // BED ACTIONS

  onClickBed(bed: Bed, room: Room) {
    // Remove edit room numbers
    this.rooms.map(r => r.edit_number = false);
    // If dropping TPs
    if (this.idxTPs2room.length > 0) {
      this.idxEditBed = [];
      this.idxEditRoom = [];
      this.selectRoom(this.rooms.find(r => r.id === bed.room_id));
    } else {
      room.edit_number = false;
      // If clicking another bed
      if (this.idxEditBed.includes(bed.id)) {
        // Remove edit bed and its room
        const idxBed = this.idxEditBed.findIndex(i => '' + i === '' + bed.id);
        this.idxEditBed.splice(idxBed, 1);
        const idxRoom = this.idxEditRoom.findIndex(i => '' + i === '' + bed.room_id);
        this.idxEditRoom.splice(idxRoom, 1);
      } else {
        // Click same bed, unselect
        this.idxEditBed = []; // Only allow edit one bed
        this.idxEditRoom = [];
        this.idxEditBed.push(bed.id);
        this.idxEditRoom.push(bed.room_id);
      }
    }
  }

  onRemovePax(bed: Bed) {
    const idxBed = this.idxEditBed.findIndex(i => '' + i === '' + bed.id);
    this.idxEditBed.splice(idxBed, 1);
    const idxRoom = this.idxEditRoom.findIndex(i => '' + i === '' + bed.room_id);
    this.idxEditRoom.splice(idxRoom, 1);
    if (bed.tour_pax_id) {
      bed.tour_pax_id = null;
      bed.locked = false;
      this.onChangeBed(bed);
    } else {
      setTimeout(() => {
        // Only allow one edit bed at the time
        this.idxEditBed = [];
        this.idxEditRoom = [];
      }, 1);
    }
  }

  toggleBedLock(bed) {
    const idxBed = this.idxEditBed.findIndex(i => '' + i === '' + bed.id);
    this.idxEditBed.splice(idxBed, 1);
    const idxRoom = this.idxEditRoom.findIndex(i => '' + i === '' + bed.room_id);
    this.idxEditRoom.splice(idxRoom, 1);
    bed.locked = !bed.locked;
    this.onChangeBed(bed);
  }

  longPressBed(bed: Bed, room: Room) {
    // If any loading, no effect
    if (this.rooms.filter(r => r.loading).length > 0) {
      return;
    }
    // Avoid multiple long press
    if (this.longPressed) {
      return;
    }
    // If no pax, nothing
    if (!bed.tour_pax_id) {
      return;
    }
    // Flag as long pressed
    this.longPressed = true;
    this.extractBed(bed, room);
  }

  onDBclickBed(bed: Bed, room: Room) {
    // If any loading, no effect
    if (this.rooms.filter(r => r.loading).length > 0) {
      return;
    }
    // If no pax, make it a click
    if (!bed.tour_pax_id) {
      this.onClickBed(bed, room);
      return;
    }
    this.extractBed(bed, room);
  }

  extractBed(bed: Bed, room: Room) {
    // Remove edit room numbers
    room.edit_number = false;
    // Add to index
    if (!this.idxTPs2room.includes(bed.tour_pax_id)) {
      this.idxTPs2room.push(bed.tour_pax_id);
    }
    // Remove from bed
    bed.tour_pax = null;
    bed.tour_pax_id = null;
    bed.locked = false;
    // Silent server call
    this.onChangeBed(bed, true);
    // Update idx of rooms
    this.updateRooms2Drop();
    // Update pax+ad
    this.npax = this.room_tourPax.filter(tp => tp.user && tp.user.role === 1).length;
    this.nadmin = this.room_tourPax.filter(tp => tp.user && tp.user.role === 2).length;
  }

  removeEl(id) {
    const removeIdx = this.idxUpdOnes.findIndex(i => '' + i === '' + id);
    this.idxUpdOnes.splice(removeIdx, 1);
  }

  getAvBeds(room) {
    return room.beds.filter(b => !b.locked && !b.tour_pax_id).length;
  }

  addTP2Room(tp_id) {
    // Remove from remain
    const idxRemoveRemain = this.left_tourPax.findIndex(tp => tp.id === tp_id);
    if (idxRemoveRemain >= 0) {
      this.left_tourPax.splice(idxRemoveRemain, 1);
    }
    if (this.left_tourPax.length < 1) {
      this.showLeft = false;
    }
    // Add tp TPs2room
    if (!this.idxTPs2room.includes(tp_id)) {
      this.idxTPs2room.push(tp_id);
    }
    // UPdate idx of rooms
    this.updateRooms2Drop();
  }

  onCloseTps() {
    this.idxTPs2room = [];
    this.idxRooms2Drop = [];
    this.processRemainPax();
  }

  copyNames(names) {
    this.idxEditBed = [];
    this.idxEditRoom = [];
    this.idxTPs2room = [];
    for (const n of names) {
      const tpName = this.tourPax.filter(tp => tp.user ? tp.user.name === n : false);
      this.idxTPs2room.push(tpName[0].id);
    }
    // Update remain
    this.processRemainPax();
    // UPdate idx of rooms
    this.updateRooms2Drop();
    if (names.length > 1) {
      this.showMatches = false;
    } else {
      this.showLeft = false;
    }
  }

  nameRoomed(name) {
    const tpName = this.room_tourPax.filter(tp => tp.user ? tp.user.name === name : false);
    return tpName.length > 0;
  }

  id2name(id) {
    const tpID = this.tourPax.filter(tp => tp.id ? tp.id === id : false);
    return tpID[0]?.user.name;
  }

  id2nameS(id) {
    const tpID = this.tourPax.filter(tp => tp.id ? tp.id === id : false);
    const tmp = tpID[0].user.name.split(' ');
    let name = tmp[0].substring(0, 7);
    if (tmp[0].length > 7) {
      name += '.';
    }
    let surname = '';
    if (tmp.length > 1) {
      surname = ' ' + tmp[1].substring(0, 2);
      if (tmp[1]) {
        surname += '.';
      }
    }
    return name + surname;
  }

  ids2name(ids) {
    const tmp = [];
    for (const id of ids) {
      const tpID = this.tourPax.filter(tp => tp.id === id);
      tmp.push(tpID[0].user.name);
    }
    return tmp;
  }

  removeTPcopied(tp_id) {
    if (this.loadingHotels) {
      return;
    }
    // Remove from TPs2Room
    const idx = this.idxTPs2room.findIndex(r => r === tp_id);
    if (idx >= 0) {
      this.idxTPs2room.splice(idx, 1);
    }
    // Put back at remain
    const tpIdx = this.tourPax.findIndex(tp => tp.id === tp_id);
    if (tpIdx > 0) {
      const tp = this.tourPax[tpIdx];
      if (!this.left_tourPax.includes(tp)) {
        this.left_tourPax.push(tp);
      }
    }
    // UPdate idx of rooms
    this.updateRooms2Drop();
    // Reorganize pax
    this.processRemainPax();
  }

  updateRooms2Drop() {
    this.idxRooms2Drop = [];
    for (const room of this.rooms) {
      if (this.idxTPs2room.length > 0) {
        // check if gender suitable
        if (this.isRoomGenderOK(room)) {
          // check if they fit
          if ((this.getAvBeds(room) >= this.idxTPs2room.length)) {
            if (!this.idxRooms2Drop.includes(room.id)) {
              this.idxRooms2Drop.push(room.id);
            }
          }
        }
      }
    }
  }

  getAntimatchIds(room, tmp): number [] {
    const res = [];
    for (const b of room.beds.filter(bs => bs.tour_pax)) {
      if (tmp.includes(b.tour_pax.user.name)) {
        res.push(b.id);
      }
    }
    return res;
  }

  getBedsId(room, ids): number [] {
    const res = [];
    for (const b of room.filter(bs => bs.tour_pax)) {
      if (ids.includes(b.tour_pax.id)) {
        res.push(b.id);
      }
    }
    return res;
  }

  onChangeBed(bed: Bed, silent?: boolean) {
    const old_value = bed.tour_pax_id;
    if (!silent) {
      this.loadingHotels = true;
    }
    const data = {
      'tour_pax_id': bed.tour_pax_id ? bed.tour_pax_id : null,
      'locked': bed.locked,
    };
    // console.log(data);
    this.httpRoom.updateBed(bed.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          bed = res.results.bed;
          // Edit the room
          const rIdx = this.rooms.findIndex(r => r.id === bed.room_id);
          const bIdx = this.rooms[rIdx].beds.findIndex(b => b.id === bed.id);
          this.rooms[rIdx].beds[bIdx] = res.results.bed;
          // Remove from edit mode
          this.idxEditBed = [];
          this.onCloseRoom(this.rooms[rIdx]);
          this.processPax();
        } else {
          if (silent) {
            bed.tour_pax_id = old_value;
          }
          this.snackSvc.resultsElse(res);
        }
        this.loadingHotels = false;
      }, error => {
        if (silent) {
          bed.tour_pax_id = old_value;
        }
        this.loadingHotels = false;
        console.log(error);
      });
  }

  onRoomSeveralPax(room: Room, list) {
    this.loadingHotels = true;
    // Prevent multiple roomings
    if (this.roomingSeveral) {
      return;
    }
    this.roomingSeveral = true;
    room.loading = true;
    const data = {
      'room_id': room.id,
      'paxs': list,
    };
    // console.log(data);
    this.httpRoom.updateRoomPAXs(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          const idx = this.rooms.findIndex(r => +r.id === +room.id);
          this.rooms[idx] = res.results.room;
          // Update modified beds
          res.results?.updated_beds?.map(upd_bed => {
            const idx2 = this.rooms.findIndex(r => +r.id === +upd_bed.room_id);
            this.rooms[idx2].beds.map(hot_bed => {
              if (+hot_bed.id === +upd_bed.id) {
                hot_bed.tour_pax = null;
                hot_bed.tour_pax_id = upd_bed.tour_pax_id;
                hot_bed.locked = false;
              }
            });
          });
          this.idxRooms2Drop = [];
          this.idxTPs2room = [];
          this.processRoomPlan();
        } else {
          this.snackSvc.resultsElse(res);
        }
        this.loadingHotels = false;
        this.roomingSeveral = false;
        room.loading = false;
      }, error => {
        this.loadingHotels = false;
        this.roomingSeveral = false;
        room.loading = false;
        console.log(error);
      });
  }

  // ROOMING

  sendRoomingList() {
    // console.log(this.component);
    const prod = this.activeTour.tour.prodid;
    const dayIdx = this.activeTour.days.findIndex(it => it.date === this.component.start_date);
    const itidx = this.activeTour.itinerary.findIndex(it => '' + it.id === '' + this.component.itinerary_id);
    const itin = this.activeTour.itinerary[itidx];
    const dialogRef = this.dialog.open(SendRoomingComponent, {
      panelClass: ['full-width-dialog'],
      maxHeight: '90vh',
      data: {
        'autoFocus': true,
        'user': this.currentUser,
        'comp': this.component,
        'itidx': itidx,
        'itin': itin,
        'all_pax': this.left_tourPax.length < 1,
        'compURL': dayIdx > 0 ? '/tour/' + prod + '/day/' + this.activeTour.days[dayIdx].id + '/component/' + this.component.id : null
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] === 'sent') {
        this.snackSvc.openSnackBar('Email sent');
        this.component.description = 'rooming_sent';
        // Update field in memory
        const comIdx = this.activeTour.components.findIndex(it => '' + it.id === '' + this.component.id);
        this.activeTour.components[comIdx]['description'] = 'rooming_sent';
        this.activeTourSvc.setActiveTour(this.activeTour);
      } else {
        if (!result || result && result['comp']['description'] || result && result['reason'] === 'close') {
          this.deleteRoomingMessage();
        }
      }
    });
  }

  deleteRoomingMessage() {
    const data = {
      'user_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
    };
    // console.log(data);
    this.outlookSvc.deleteRoomingMessage(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          // Even if it fails, remove from component
          this.component.description = null;
          // Update field in memory
          const comIdx = this.activeTour.components.findIndex(it => '' + it.id === '' + this.component.id);
          this.activeTour.components[comIdx]['description'] = null;
          this.activeTourSvc.setActiveTour(this.activeTour);
          // this.snackSvc.openSnackBar('Draft discarded');
          if (res.status >= 400) {
            console.log('Not deleted in outlook');
          }
        },
        error => {
          console.log(error);
        });
  }

  addBusDriver() {
    this.loadingHotels = true;
    const data = {
      'tour_id': this.activeTour.tour.id,
      'email': 'bus_driver@planafy.com',
      'room': 'Si',
    };
    // console.log(data);
    this.httpTour.createTourPax(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // If Driver not in a room
            const idx = this.rooms.findIndex(r => r.type === 'single' && r.beds[0]?.tour_pax?.user.name === 'Bus Driver');
            if (idx < 0) {
              // If empty bed available insert there
              const last = this.rooms.map(r => r.type === 'single' && !r.beds[0]?.tour_pax).lastIndexOf(true);
              if (last >= 0) {
                const tmp = this.rooms[last].beds[0];
                tmp.tour_pax_id = res.results.tour_pax.id;
                tmp.locked = true;
                this.onChangeBed(tmp);
              } else {
                this.createRoom('single', 1, res.results.tour_pax.id);
              }
            } else {
              this.loadingHotels = false;
            }
          } else {
            this.loadingHotels = false;
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.loadingHotels = false;
          this.snackSvc.openSnackBar('Error adding bus driver to tour');
        });
  }

  // Preferences

  launchUpgPrefs() {
    const more_prefs = this.tourPax.filter(tp => {
      if ((tp.room !== 'Si' && tp.room !== 'St') &&
        this.matches.findIndex(m => m.includes(tp.user.name)) < 0) {
        return true;
      }
    });
    if (more_prefs.length > 0) {
      const filt = more_prefs.filter(tp => tp.room === more_prefs[0].room);
      if (filt.length > 1) {
        this.createPref(filt[0]);
      }
    }
  }

  createPref(tpax?) {
    const dialogRef = this.dialog.open(AddRoomieComponent, {
      data: {'autoFocus': true, 'tour_pax': this.tourPax, 'matches': this.matches, 'selected': tpax ? tpax : null}
    });
    dialogRef.componentInstance.updateInfo.subscribe(data => {
      this.matches = data['matches'];
      this.antimatches = data['antimatches'];
      this.preferences = data['preferences'];
      this.preferences.sort(function (a, b) {
        return a.main.localeCompare(b.main) || a.other.localeCompare(b.other);
      });
      this.processPrefs(this.preferences);
      this.processPax();
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result !== 'close') {
        this.launchUpgPrefs();
      }
    });
  }

  onRandR() {
    this.loadingHotels = true;
    this.httpRoom.randomRoomies(this.activeTour.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.getHotelInfo();
          } else {
            let err;
            if (res.results) {
              err = res.results[Object.keys(res.results)[0]].toString();
              this.snackSvc.openSnackBar(err);
            } else {
              err = res.message;
              console.log(err);
            }
            this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error onAssignPax', JSON.stringify(err));
          }
          this.loadingHotels = false;
        },
        error => {
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error generating roomies', JSON.stringify(error));
          this.loadingHotels = false;
          this.snackSvc.openSnackBar('Error generating roomies');
        });
  }

  onAssignRooms() {
    if (this.loadingHotels) {
      return;
    }
    const dialogRef = this.dialog.open(AssignRoomsComponent, {
      minWidth: '50%',
      maxWidth: '100%',
      maxHeight: '90vh',
      data: {
        'autoFocus': true,
        'tour_pax': this.tourPax,
        'rooms': this.rooms,
        'preferences': this.preferences,
        'matches': this.matches,
        'tour': this.activeTour.tour,
        'nbed': this.beds.length - this.empty_locked_beds,
        'npax': this.room_tourPax.length,
        'ndriver': this.rooms.filter(r => r.type === 'single' && r.beds[0]?.tour_pax?.user.name === 'Bus Driver').length,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result !== 'close') {
        if (result === 'onCreateRooms') {
          this.onCreateRooms();
        } else {
          if (result.idxWrongGender && result.idxWrongGender.length > 0) {
            this.idxWrongGender = result.idxWrongGender;
            setTimeout(() => {
              document.querySelector('.scroll2WGender').scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
            }, 1);
          } else if (!this.plus && result.prefs && result.prefs.length > 0) {
            this.createPref(result.prefs[0]);
          } else {
            this.onAssignPax((result.rand ? 'rand' : null) + (result.upgraded ? 'upgraded' : null));
          }
        }
      }
    });
  }

  onAssignPax(type) {
    this.loadingHotels = true;
    const data = {
      'itinerary_id': this.component.itinerary_id
    };
    if (type && type.includes('rand')) {
      data['random'] = true;
    }
    // console.log(data);
    this.httpRoom.assignPAX(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.getHotelInfo();
            if (res.status !== 201) {
              this.snackSvc.openSnackBar(res.message);
            }
          } else {
            if (res.results && res.results.rooms) {
              this.idxWrongGender = res.results.rooms;
            }
            this.snackSvc.openSnackBar(res.message);
            this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error onAssignPax', '' + res.message);
          }
        },
        error => {
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error assigning rooms', JSON.stringify(error));
          this.loadingHotels = false;
          this.snackSvc.openSnackBar('Error assigning rooms');
        });
  }

  onDeletePref(ch) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete preference?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.deletePref(ch);
        }
      });
  }

  deletePref(ch) {
    const data = {
      'tp_ids': [ch.main_id, ch.other_id].join(',')
    };
    // console.log(data);
    this.httpRoom.createRooming(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.matches = res.results.matches;
            this.antimatches = res.results.antimatches;
            const idx = this.preferences.findIndex(p => p.id === ch.id);
            this.preferences.splice(idx, 1);
            this.processPrefs(this.preferences);
            this.processPax();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error deleting preference');
        });
  }

  onOpenReplicateRooms() {
    const dialogRef = this.dialog.open(ReplicateRoomsComponent, {
      data: {
        'autoFocus': true,
        'itin_from': this.component.itinerary_id,
        'comp_hotels': this.all_hots
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        if (result['comp_id']) {
          this.getHotelInfo(result['comp_id']);
        }
      }
    });
  }

  onDeletePrefs() {
    this.loadingHotels = true;
    this.httpRoom.deletePreferences(this.activeTour.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.getHotelInfo();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingHotels = false;
        },
        error => {
          console.log(error);
          this.loadingHotels = false;
          this.snackSvc.openSnackBar('Error generating roomies');
        });
  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  openWeb() {
    window.open(this.apiUrl + 'rooming/html/' + this.component.id, '_blank');
  }

  onDownloadPdf() {
    this.httpRoom.downloadRoomingPdf(this.component.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        x => {
          const newBlob = new Blob([x], {type: 'application/pdf'});
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = data;
          // Add the number of city to name
          const codeprod = this.activeTour.tour.code + '.' + this.activeTour.tour.prodid;
          const itidx = this.activeTour.itinerary.findIndex(it => '' + it.id === '' + this.component.itinerary_id);
          const itin = this.activeTour.itinerary[itidx];
          link.download = codeprod + '-Hotel_' + (itidx + 1) + itin.city.name + '.pdf';
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error downloading rooming pdf');
        });
  }

  onDownloadExcel() {
    this.httpRoom.downloadRoomingExcel(this.component.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.excelService.downloadExcel(res.results.headers, res.results.data, res.results.name);
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error downloading rooming excel');
        });
  }

  goBack() {
    if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  onGoToPax() {
    this.activeTourSvc.setActiveTour(this.activeTour);
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'tourpax'], {queryParams: {returnUrl: window.location.pathname}});
  }

  OnGoToHotels() {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'hotels'], {queryParams: {returnUrl: window.location.pathname}});
  }

  goToHotel(comp) {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'hotels', comp.id]);
    this.getHotelInfo(comp.id);
  }

  onGoHoCo(comp?) {
    if (this.dayId && comp) {
      this.router.navigate(['tour', this.activeTour.tour.prodid, 'day', this.dayId, 'component', comp.id],
        {queryParams: {returnUrl: window.location.pathname}});
    } else {
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
