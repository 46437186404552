import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {Activetour} from '../../../../shared/models/activetour.model';
import {Orders} from '../../../../shared/models/orders.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TitleService} from '../../../../shared/services/common/title.service';
import {takeUntil} from 'rxjs/operators';
import {loadStripe} from '@stripe/stripe-js';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss']
})
export class DonationComponent implements OnInit, OnDestroy {
  currentUser: User;
  activeTour: Activetour;

  order: Orders;
  orders: Orders[] = [];

  baseUrlLogo: string;
  max: number;
  sent_icon: string;
  curr_symbol: string;
  err: string;
  txt: string;
  payment_intent: any;
  stripePromise: any = null;
  stripe: any = null;
  pushed_pay = false;
  understood = false;

  recommended: number;
  amount: number;

  curr_don = 'EUR';
  change_curr = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private flagService: FlagService,
    private snackSvc: SnackbarService,
    private httpTour: HttpTourService,
    private httpOrder: HttpOrdersService,
    private router: Router,
    private route: ActivatedRoute,
    private webTitleSvc: Title,
    private titleSvc: TitleService
  ) {
    this.webTitleSvc.setTitle('Donation | Planafy');
    this.titleSvc.setTitle('Donation');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    // Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(environment.stripePublishKey)
      .then(res => {
        this.stripe = res;
      });
    this.baseUrlLogo = environment.baseUrl;
  }

  ngOnInit(): void {
    if (this.currentUser.role > User.pax_admin) {
      this.snackSvc.openSnackBar('Access here as PAX, not TD');
      this.router.navigate(['tours']);
      return;
    }
    this.err = null;
    this.txt = null;
    const sessionId = this.route.snapshot.queryParams['session_id'] ? this.route.snapshot.queryParams['session_id'] : null;
    if (sessionId && sessionId !== 'error') {
      this.verifySession(sessionId);
    } else if (sessionId && sessionId === 'error') {
      this.err = 'Payment not completed';
    }
    this.recommended = 10;
    if (this.route.snapshot.queryParams['a']) {
      this.recommended = this.route.snapshot.queryParams['a'].replace(',', '.');
      this.understood = true;
    }
    if (this.route.snapshot.queryParams['c']) {
      this.curr_don = this.route.snapshot.queryParams['c'].toUpperCase();
      this.understood = true;
    }
    this.amount = this.recommended;
    this.max = this.recommended * 2;
    this.changeCurr();
  }

  changeCurr() {
    this.curr_don = this.curr_don.toUpperCase();
    this.curr_symbol = (this.curr_don === 'GBP' ? '£' : (this.curr_don === 'USD' ? '$' : (this.curr_don === 'AUD' ? 'AU$' : '€')));
  }

  verifySession(sessionId) {
    const data = {
      'user_id': this.currentUser.id,
      'session_id': sessionId,
    };
    this.httpOrder.verifyStripeSession(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const verified = res.results.verified;
            if (verified) {
              this.txt = 'Thank you for the donation!';
              this.snackSvc.openSnackBar('Payment successful!');
            } else {
              this.err = 'Payment ok but not verified. Contact support';
              this.snackSvc.openSnackBar('ERROR. Verifying payment');
            }
          } else {
            this.err = 'Payment ok but not verified. Contact support';
            this.snackSvc.openSnackBar('ERROR. Verifying payment');
          }
        },
        error => {
          console.log(error);
          this.err = 'Payment ok but not verified. Contact support';
          this.snackSvc.openSnackBar('ERROR. Verifying payment');
        });
  }

  rem() {
    if (this.amount >= 2) {
      this.amount = this.amount - 2;
    } else {
      this.amount = 0;
    }
    this.choose_icon();
  }

  add() {
    this.amount = this.amount + 2;
    this.choose_icon();
  }

  choose_icon() {
    if (this.amount < this.recommended / 2) {
      this.sent_icon = 'sentiment_satisfied';
    } else if (this.amount > this.recommended / 2 && this.amount < this.recommended * 1.5) {
      this.sent_icon = 'sentiment_satisfied_alt';
    } else if (this.amount > this.recommended * 1.5 && this.amount < this.recommended * 2) {
      this.sent_icon = 'sentiment_very_satisfied';
    } else if (this.amount > this.recommended * 2) {
      this.sent_icon = 'celebration';
    } else {
      this.sent_icon = 'sentiment_satisfied_alt';
    }
  }

  doChange(event) {
    this.amount = event.value;
    this.choose_icon();
  }

  onGoHome() {
    this.router.navigate(['home']);
  }

  /*
  /* STRIPE
  *******************/

  createDonationCheckout() {
    this.err = null;
    if (!this.amount) {
      this.snackSvc.openSnackBar('Please insert amount');
      return;
    }
    this.pushed_pay = true;
    const data = {
      'user_id': this.currentUser.id,
      'amount': +this.amount * 100,
      'currency_code': this.curr_don,
      'success_url': window.location.origin + '/donate/',
      'cancel_url': window.location.origin + '/donate/',
      'returnUrl': null,
    };
    // console.log(data);
    this.httpOrder.setStripeDonateExpCheckout(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            window.open(res.results.checkout_session.url, '_self');
          } else {
            this.pushed_pay = false;
            this.snackSvc.openSnackBar('Error(1). Contact your Tour Director');
          }
        },
        error => {
          this.pushed_pay = false;
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, '' + error, '');
          this.snackSvc.openSnackBar('Error(2). Contact your Tour Director');
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
