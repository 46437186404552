import {Event} from './event.model';
import {Comptype} from './comptype.model';
import {City} from './city.model';
import {Itinerary} from './itinerary.model';
import {Form} from './form.model';

export class Components {
  id: number;
  itinerary_id: number;
  comp_type_id: number;
  name: string;
  start_date: string;
  end_date: string;
  city_id: number;
  npax: string;
  from: string;
  to: string;
  supplier: string;
  image: string;
  description: string;
  requests: string;
  hotel_extras: string;
  state: string;
  confirmed_at: string;
  confirm_notes: string;
  mailable: boolean;
  recipients: string;
  recs_cc: string;
  recs_bcc: string;
  subject: string;
  sent_at: string;
  mail_provider: string;
  message_id: string;
  conversation_id: string;
  html_body: string;
  web_link: string;
  scheduled_send: string;
  updated_at: string;
  deleted_at: string;
  city_name: string;
  showenddate?: boolean;
  tour_optional_id?: number;
  city?: City;
  events?: Event[];
  comp_type?: Comptype;
  itinerary?: Itinerary;
  form?: Form;
  checked?: boolean;
  updated?: boolean;
  t_prodid?: string;
  t_code?: string;
  t_name?: string;
  td?: string;
  day_id?: string;
  reply_recs?: string;
  reply_ccs?: string;
  showCities?: boolean;
  start_day?: string;
  end_day?: string;
  selected: string[];

  constructor() {
    this.events = [new Event()];
    this.start_date = '';
    this.name = null;
    this.supplier = null;
    this.city_name = null;
    this.city_id = 0;
    this.sent_at = null;
    this.form = new Form();
    this.selected = [];
    this.mailable = this.comp_type && this.comp_type.mailable ? this.comp_type.mailable : false;
  }
}
