import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInvoiceService {

  private endpoints: {
    billingCrud: string,
    billingInvoice: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      billingCrud: environment.apiUrl + 'billing/',
      billingInvoice: environment.apiUrl + 'billing_invoice/',
    };
  }

  /* Create a billing instance */
  createBilling(data): Observable<any> {
    return this.http.post<any>(this.endpoints.billingCrud, data);
  }

  /* Update Billing */
  updateBilling(bill_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.billingCrud + bill_id, paramsToUpdate);
  }

  /* Create a billing and invoice instance */
  createInvoicewithBilling(data): Observable<any> {
    return this.http.post<any>(this.endpoints.billingInvoice, data);
  }
}
