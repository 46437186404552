import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpUserService} from '../../../shared/services/http/http-user.service';
import {HttpClient} from '@angular/common/http';
import {Clipboard} from '@angular/cdk/clipboard';
import {SnackbarService} from '../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {AppVersion} from '../../../shared/models/app-version.model';

export interface DialogDataUpdateApp {
  os: string;
  version: string;
  reason: string;
}

@Component({
  selector: 'app-update-version',
  templateUrl: './update-version.component.html',
  styleUrls: ['./update-version.component.scss']
})
export class UpdateVersionComponent implements OnDestroy {
  loading = false;
  snackbarRef: any = null;
  selfUrl: string;
  version: AppVersion;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpUser: HttpUserService,
    private http: HttpClient,
    private clipboard: Clipboard,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<UpdateVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataUpdateApp) {
    this.data.os = null;
    this.data.version = null;
    this.data.reason = null;
    this.selfUrl = environment.selfUrl;
    this.checkData();
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Enter') {
      this.submit();
    }
  }

  submit(): void {
    if (!this.data.os) {
      this.snackSvc.openSnackBar('Please insert os');
      return;
    }
    if (!this.data.version) {
      this.snackSvc.openSnackBar('Please insert version');
      return;
    }
    this.updateVersion();
  }

  async checkData() {
    this.loading = true;
    const res = await this.httpUser.checkAppVersion();
    this.loading = false;
    console.log(res);
    if (res.status < 400) {
      this.version = new AppVersion(res.results);
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  async updateVersion() {
    this.loading = true;
    const data = {
      'os': this.data.os,
      'new_version': this.data.version,
    };
    // console.log(data);
    const res = await this.httpUser.updateAppVersion(data);
    this.loading = false;
    console.log(res);
    if (res.status < 400) {
      this.version = res.results;
      this.data.os = null;
      this.data.version = null;
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  close(reason) {
    if (reason === 'close' && this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
