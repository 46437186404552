import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

import {User} from '../../models/user.model';
import {Platform} from '@angular/cdk/platform';
import {Tour} from '../../models/tour.model';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private url: { login: string, logout: string };

  constructor(
    public platform: Platform,
    private http: HttpClient
  ) {
    const parsed = localStorage.getItem('currentUser') !== null ? JSON.parse(decodeURIComponent(atob(localStorage.getItem('currentUser')))) : null;
    this.currentUserSubject = new BehaviorSubject<User>(parsed);
    this.currentUser = this.currentUserSubject.asObservable();
    this.url = {
      login: environment.apiUrl + 'login',
      logout: environment.apiUrl + 'logout',
    };
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(loginData: { email: string, password: string }) {
    return this.http.post<any>(this.url.login, loginData)
      .pipe(map(res => {
        // login successful if there's a jwt token in the response
        if (res.results && res.results.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', btoa(JSON.stringify(res.results)));
          localStorage.setItem('accessToken', JSON.stringify(res.results.access_token));
          this.currentUserSubject.next(res.results);
        }
        return res;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('roomPreferences');
    localStorage.removeItem('hiddenIncidents');
    localStorage.removeItem('showGenderInfo');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('activeTour');
    localStorage.removeItem('toursUser');
    localStorage.removeItem('toursPax');
    localStorage.removeItem('returnUrl');
    localStorage.removeItem('day_view');
    localStorage.removeItem('dayview_role');
    localStorage.removeItem('referrer_id');
    localStorage.removeItem('folder_id');
    localStorage.removeItem('session_id');
    localStorage.removeItem('TDIcomps');
    localStorage.removeItem('sunTimes');
    this.currentUserSubject.next(null);
    return this.http.post<any>(this.url.logout, {});
  }

  updateUser(currentUser) {
    localStorage.setItem('currentUser', btoa(encodeURIComponent(JSON.stringify(currentUser))));
    this.currentUserSubject.next(currentUser);
  }

  viewerAs(viewAs: number) {
    let ret;
    const toursUser: Tour[] = JSON.parse(localStorage.getItem('toursUser'));
    const id = this.currentUserSubject.value.id;
    if (toursUser.length > 0) {
      const tour = toursUser.find(t => +t.user_id === +viewAs);
      ret = (tour.user_id !== id) ?
        (tour.user.given_name ? (toursUser[0].user.given_name + ':' + viewAs) : tour.user.name + ':' + viewAs) : null;
    } else {
      ret = viewAs;
    }
    return ret;
  }

  isMobileDevice() {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substring(0, 4))) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  checkOS(n) {
    let ios = false;
    if (n.userAgentData) {
      const hints = ['architecture', 'model', 'platform', 'platformVersion', 'uaFullVersion'];
      n.userAgentData.getHighEntropyValues(hints)
        .then(ua => {
          // console.log(ua);
          ios = this.platform.IOS || this.platform.SAFARI || ua.platform === 'macOS';
        });
    } else {
      // 'navigator.userAgentData is not supported'
      // console.log(n.userAgent);
      ios = this.platform.IOS || this.platform.SAFARI;
    }
    return ios;
  }
}
