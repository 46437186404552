import {Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';
import {fromEvent, merge, of, Subscription, timer} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective implements OnDestroy {
  eventSubscribe: Subscription;
  threshold = 250;

  @Output() mouseLongPress = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const mousedown = fromEvent<MouseEvent>(elementRef.nativeElement, 'mousedown').pipe(
      filter((event) => event.button === 0), // Only allow left button (Primary button)
      map((event) => true) // turn on threshold counter
    );
    const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter((event) => event.button === 0), // Only allow left button (Primary button)
      map(() => false) // reset threshold counter
    );
    const touchstart = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchstart').pipe(
      map(() => true)
    );
    const touchmove = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchmove').pipe(
      map(() => true)
    );
    const touchend = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchend').pipe(
      map(() => false)
    );
    this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchmove, touchend)
      .pipe(
        switchMap((state) => (state ? timer(this.threshold, 100) : of(null))),
        filter((value) => value)
      )
      .subscribe(() => this.mouseLongPress.emit());
  }

  ngOnDestroy(): void {
    if (this.eventSubscribe) {
      this.eventSubscribe.unsubscribe();
    }
  }
}
