import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpFreetimeService {

  private endpoints: {
    allFreetimes: string,
    crudFreetime: string,
    freetimeCity: string,
    freetimeTypes: string,
    freetimeType: string,
    freetimeTypeCity: string,
    uploadFTTypeImage: string,
    uploadFTImage: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allFreetimes: environment.apiUrl + 'freetimes/',
      crudFreetime: environment.apiUrl + 'freetime/',
      freetimeCity: environment.apiUrl + 'freetime_fttype/',
      freetimeTypes: environment.apiUrl + 'freetime_types/',
      freetimeType: environment.apiUrl + 'freetime_type/',
      freetimeTypeCity: environment.apiUrl + 'freetime_type_city/',
      uploadFTTypeImage: environment.apiUrl + 'upload_fttype_image/',
      uploadFTImage: environment.apiUrl + 'upload_freetime_image/',
    };
  }

  // FREETIME

  /* gets all free time types of a city (either id or name) */
  getAllFreetimeTypesCity(city: number): Observable<any> {
    return this.http.get<any>(this.endpoints.freetimeTypeCity + city);
  }

  /* gets all freetimes */
  getAllFreetimes(): Observable<any> {
    return this.http.get<any>(this.endpoints.allFreetimes);
  }

  /* Uploads image for FT Type */
  uploadFTTypeImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadFTTypeImage, data);
  }

  /* Uploads image for FT*/
  uploadFTImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadFTImage, data);
  }

  /* Creates a freetime */
  createFreetime(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudFreetime, data);
  }

  /* Creates a freetime type */
  createFTType(data): Observable<any> {
    return this.http.post<any>(this.endpoints.freetimeType, data);
  }

  /* Updates a freetime */
  updateFreetime(rest_id: number, data): Observable<any> {
    return this.http.put<any>(this.endpoints.crudFreetime + rest_id, data);
  }

  /* Gets all freetimes from one type */
  getFreetimesType(fttype_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.freetimeCity + fttype_id);
  }

  /* Delete freetime */
  deleteFreetime(data): Observable<any> {
    return this.http.delete<any>(this.endpoints.crudFreetime, {params: data});
  }

  /* Delete freetime type */
  deleteFreetimeType(data): Observable<any> {
    return this.http.delete<any>(this.endpoints.freetimeType, {params: data});
  }

  /* Updates a freetime type */
  updateFTtype(fttype_id: number, data): Observable<any> {
    return this.http.put<any>(this.endpoints.freetimeType + fttype_id, data);
  }

}
